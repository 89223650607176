import { Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { wenderUtahRatingScaleQuestions } from "./questions";
import { WenderUtahRatingScaleForm } from "./types";
import { calculateScore, questionsToTakeIntoConsideration } from "./utils";
import { AnswerLabels, QuestionAndAnswer, genericStyles } from "../PdfBuilder";

export const WursPage: React.FC<{
  data: WenderUtahRatingScaleForm;
  clientName: string;
}> = ({ data, clientName }) => {
  return (
    <Page size="A4" style={genericStyles.page}>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>
          Wender Utah Rating Scale for the Attention Deficit Hyperactivity
          Disorder
        </Text>
      </View>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>{clientName}</Text>
      </View>
      {wenderUtahRatingScaleQuestions.map(questionSet => (
        <View key={questionSet.title}>
          <View style={{ paddingTop: "10pt" }}>
            <Text style={genericStyles.sectionTitle}>{questionSet.title}</Text>
          </View>
          <AnswerLabels
            questions={[
              "Not at all or very slightly",
              "Mildly",
              "Moderately",
              "Quite a bit",
              "Very much",
            ]}
          />
          {questionSet.questions.map(question => (
            <View key={question.field}>
              <QuestionAndAnswer
                key={question.field}
                question={question.question}
                answer={data![question.field]!}
                potentialScores={[0, 1, 2, 3, 4]}
              />
            </View>
          ))}
        </View>
      ))}
      <Text
        style={{ paddingTop: "10pt" }}
      >{`Total score from selected* questions: ${calculateScore(data)} / ${
        questionsToTakeIntoConsideration.length * 4
      }`}</Text>
    </Page>
  );
};
