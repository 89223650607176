import { Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { wfirsQuestions } from "./questions";
import { WfirsForm } from "./types";
import { flagSection } from "../flagWeissSection";
import { AnswerLabels, QuestionAndAnswer, genericStyles } from "../PdfBuilder";

export const WfirsPage: React.FC<{ data: WfirsForm; clientName: string }> = ({
  data,
  clientName,
}) => {
  return (
    <Page size="A4" style={genericStyles.page}>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>
          Weiss Functional Impairment Rating Scale - Self Report (WFIRS-S)
        </Text>
      </View>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>{clientName}</Text>
      </View>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>{`School Status: ${data.schoolStatus}`}</Text>
      </View>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>{`Work Status: ${data.workStatus}`}</Text>
      </View>
      {wfirsQuestions.map(questionSet => (
        <View key={questionSet.title}>
          <View style={{ paddingTop: "10pt" }}>
            <Text style={genericStyles.sectionTitle}>{questionSet.title}</Text>
          </View>
          <AnswerLabels
            questions={[
              "Never or not at all",
              "Sometimes or somewhat",
              "Often or much",
              "Very often or very much",
              "N/A",
            ]}
          />
          {questionSet.questions.map(question => (
            <View key={question.field}>
              <QuestionAndAnswer
                key={question.field}
                question={question.question}
                answer={data[question.field] as number}
                potentialScores={[0, 1, 2, 3, -1]}
                minGreySection={2}
              />
            </View>
          ))}
        </View>
      ))}
      {wfirsQuestions.map(questionSet => {
        const section = flagSection(
          data,
          questionSet.questions,
          questionSet.title,
          true
        );
        if (!section) {
          return null;
        }
        return (
          <View
            style={{ paddingTop: "10pt" }}
            key={`${questionSet.title}-scoring`}
          >
            <Text style={genericStyles.sectionTitle}>{section}</Text>
          </View>
        );
      })}
    </Page>
  );
};
