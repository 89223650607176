import { Typography } from "@material-ui/core";
import { Backdrop, Box, CircularProgress, Divider } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { Button, DateInput, SimpleForm } from "react-admin";
import {
  CustomTooltip,
  InfoDisplay,
  ReportingStatistic,
} from "./ReportingStatistic";
import { REACT_APP_AUTH0_AUDIENCE, useHttpClient } from "../../utils";
import { logger } from "../../utils/logging";

export const Subtitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography style={{ margin: "1rem", padding: "0" }} variant="h6">
      {children}
    </Typography>
  );
};

function getTodayDateString() {
  const now = new Date();
  return moment.utc(now).format("YYYY-MM-DD");
}

function getLastMonthDateString() {
  const now = new Date();
  return moment.utc(now).subtract(1, "month").format("YYYY-MM-DD");
}

function addOneDayToDateString(dateString: string) {
  return moment.utc(dateString).add(1, "day").format("YYYY-MM-DD");
}

export const ReportingDashboard = () => {
  const { httpClient } = useHttpClient();
  const [retrievedData, setRetrievedData] = useState<any>(undefined);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmission = async (values: any) => {
    const { startDate, endDate } = values;
    const today = getTodayDateString();

    if (startDate > today) {
      setError("Start date cannot be in the future");
      return;
    }
    if (startDate > endDate) {
      setError("Start date cannot be later than end date");
      return;
    }

    // Use end date +1 day to make the end date inclusive
    const endDatePlusOne = addOneDayToDateString(endDate);

    setIsLoading(true);
    try {
      const response = await httpClient(
        `${REACT_APP_AUTH0_AUDIENCE}/statistics/reporting_statistics?startDate=${startDate}&endDate=${endDatePlusOne}`
      );
      setRetrievedData(response.json);
      setError("");
    } catch (error) {
      logger.warn("error getting reporting statistics", { error });
      return;
    }
    setIsLoading(false);
  };

  return (
    <Box>
      <Backdrop sx={{ zIndex: 1 }} open={isLoading}>
        <Box display={"flex"} flexDirection="column" alignItems={"center"}>
          <CircularProgress color="inherit" />
          <Box marginTop={"1rem"}>Loading... This may take a minute</Box>
        </Box>
      </Backdrop>
      <SimpleForm toolbar={false} onSubmit={handleSubmission}>
        <Box display={"flex"} flexDirection="row">
          <DateInput
            defaultValue={getLastMonthDateString()}
            required
            source="startDate"
            label="Start Date (inclusive)"
          />
          <DateInput
            defaultValue={getTodayDateString()}
            required
            source="endDate"
            label="End Date (inclusive)"
          />
        </Box>
        {error && <Box sx={{ color: "red" }}>{error}</Box>}
        <Button
          disabled={isLoading}
          sx={{
            outline: "1px solid black",
            zIndex: 0,
            color: "blue",
            background: "white",
            fontSize: "14px",
            fontWeight: "bold",
            "&:hover": {
              color: "white",
              background: "blue",
            },
          }}
          size="large"
          label="Generate Report"
          type="submit"
        />
      </SimpleForm>
      {retrievedData && (
        <Box>
          <Subtitle>Active Clients</Subtitle>
          <ReportingStatistic
            tooltip="The number of clients that have an active subscription that spans the entire time frame and have logged in at least one time prior to the provided end date."
            label={"Number of unique active clients"}
            data={retrievedData.numberOfActiveClients}
          />
          <Divider />

          <Subtitle>Island Health</Subtitle>
          <ReportingStatistic
            tooltip="The number of clients that were created within the time frame. Referred by MHSU at Island Health."
            label={"Number of new referrals"}
            data={retrievedData.numberOfNewReferrals}
          />
          <ReportingStatistic
            tooltip={`A client is included in this statistic if they completed their 2nd thirty minute CBT session in the provided time frame.`}
            label={
              "Number of clients who have completed 2 thirty minute CBT sessions"
            }
            data={retrievedData.completed2ndThirtyMinuteCbtSessionInTimeFrame}
          />
          <ReportingStatistic
            tooltip={`A client is included in this statistic if they completed their 12th thirty minute CBT session in the provided time frame.`}
            label={`Number of clients who have completed all 12 thirty minute CBT sessions`}
            data={retrievedData.completed12thThirtyMinuteCbtSessionInTimeFrame}
          />
          <ReportingStatistic
            tooltip="The number of clients that were marked ineligible and not voided in the selected time frame"
            label={"Number of clients marked ineligible"}
            data={retrievedData.numberOfClientsMarkedIneligible}
          />
          <Box margin={"0 0 1rem 1rem"}>
            <Box display={"flex"}>
              <CustomTooltip
                info={`Response to the question: "Do you feel you have accomplished the initial goals you set for yourself at your first session?" from the final survey`}
              />
              <Box>Initial goals accomplished feedback</Box>
            </Box>
            <Box marginLeft={"2rem"}>
              {retrievedData.islandHealthAccomplishedGoalsFeedback.map(
                feedback => (
                  <InfoDisplay label={feedback.answer} data={feedback.count} />
                )
              )}
            </Box>
          </Box>
          <Divider />

          <Subtitle>Waitlist Data</Subtitle>
          <ReportingStatistic
            tooltip={`Looks at days between client creation and initial assessment. Only clients who completed their initial assessment within the time frame are recorded in this statistic.`}
            label={`Median number of days between receiving client referral and service initiation`}
            data={
              retrievedData.medianDaysBetweenJoiningAndCompleteInitialAssessment
            }
          />
          <Divider />

          <Subtitle>New Admits</Subtitle>
          <ReportingStatistic
            tooltip={`A client is considered a "new admit" once they have completed their initial assessment. Only clients that have completed their initial assessment in the provided time period are considered for this statistic.`}
            label={"Number of unique clients new admits"}
            data={retrievedData.completedInitialAssessments}
          />
          <ReportingStatistic
            tooltip={`A client is part of this statistic if they completed their initial assessment in the provided time period. Note that the client could have discharged within the time frame or later. If there are no clients in the time period N/A is shown.`}
            label={"Self-identified gender of client new admits"}
            data={retrievedData.genderBreakdown}
          />
          <ReportingStatistic
            tooltip={`A client is part of this statistic if they completed their initial assessment in the provided time period. Note that the client could have discharged within the time frame or later.`}
            label={
              "Number of unique client new admits who self identify as Indigenous"
            }
            data={retrievedData.indigenousBreakdown}
          />
          <Divider />

          <Subtitle>Service Data</Subtitle>
          <ReportingStatistic
            tooltip={`The total time of individual sessions (Initial Assessment, CBT, Med Refill, etc) that were not cancelled or no-showed that fall within the time frame.`}
            label={
              "Number of individual clinical hours received by clients in the reporting period"
            }
            data={retrievedData.completedIndividualSessionHours}
          />
          <ReportingStatistic
            tooltip={`The total time of group sessions that were not cancelled or no-showed that fall within the time frame. Note that if 8 clients had a 1 hour group session together, this would show 8 hours not 1 hour as it is the cumulative time of client sessions.`}
            label={
              "Number of group clinical hours received by clients in the reporting period"
            }
            data={retrievedData.completedGroupSessionHours}
          />
          <ReportingStatistic
            tooltip={`Sum of total individual and group clinical hours received by clients in the reporting period.`}
            label={
              "Total number of clinical hours received by clients in the reporting period"
            }
            data={
              retrievedData.completedIndividualSessionHours +
              retrievedData.completedGroupSessionHours
            }
          />
          <ReportingStatistic
            tooltip={`Only includes sessions that no showed or cancelled, and were not rescheduled.`}
            label={`Number of sessions not completed`}
            data={retrievedData.noShowedOrCancelled}
          />
          <ReportingStatistic
            tooltip={`Only includes incomplete sessions that were cancelled/rescheduled by Cognito team members (i.e. not by clients).`}
            label={"Reasons for sessions not being completed"}
            data={retrievedData.cancelReasonBreakdown}
          />
          <Divider />

          <Subtitle>Discharge Data</Subtitle>
          <ReportingStatistic
            tooltip={`Number of clients who have been marked ineligible for the program based on their answers on the intake form. A client is NOT included in this statistic if they have been readmitted after a discussion.`}
            label={`Total number of clients marked as ineligible for the program during the reporting period`}
            data={retrievedData.numberOfIneligibleClients}
          />
          <ReportingStatistic
            tooltip={`Number of clients who have been discharged and did not complete all 13 CBT sessions. A client is included in this statistic if they discharged in the provided time period.`}
            label={`Total number of unique clients closed/discharged during the reporting period`}
            data={retrievedData.dischargedAndHasNotCompletedAllSessions}
          />
          <ReportingStatistic
            tooltip="Uses the cumulative number of sessions completed by clients who are active during the given time frame, this includes intake appointments."
            label={
              "Average number of clinical service events per case, including assessment"
            }
            data={
              retrievedData.averageNumberOfCompletedSessionsForActiveClients
            }
          />
          <ReportingStatistic
            tooltip={`A client is counted in this statistic if they did a CBT session at some point and they discharged within the provided time frame. If there are no clients that meet this standard N/A is shown.`}
            label={`Average number of days from the beginning of treatment to the end of treatment`}
            data={
              retrievedData.averageNumberOfDaysBetweenInitialAssessmentAndLastSession
            }
          />
          <Divider />

          <Subtitle>Other</Subtitle>
          <ReportingStatistic
            tooltip={`A client is only counted in this statistic if they discharged within the provided time frame.`}
            label={`Average number of completed sessions for inactive clients`}
            data={
              retrievedData.averageNumberOfCompletedSessionsForInactiveClients
            }
          />
          <ReportingStatistic
            tooltip={`Only clients who complete their first 30 minute CBT session within the time frame are recorded in this statistic.`}
            label={`Total clients that completed their first 30 minute CBT session`}
            data={retrievedData.numberOfClientsWhoHaveCompletedFirst30MinCbt}
          />
          <ReportingStatistic
            tooltip={`The number of events within the time frame that the client did not show up for the appointment.`}
            label={"Number of no shows"}
            data={retrievedData.numberOfNoShows}
          />
          <ReportingStatistic
            tooltip={`Only clients whose subscription ended within the time frame are recorded in this statistic. E.g Let's say a client's payment is on the 15th of every month, if the client cancelled May 16th and you were looking at May-June the client would not be recorded in that time frame, but would be recorded for June-July.`}
            label={"Number of cancelled subscriptions"}
            data={retrievedData.numberOfCancelledSubscriptions}
          />
          <ReportingStatistic
            tooltip={`Only clients who were referred in the provided time period are recorded in this statistic.`}
            label={"Number of referrals per region"}
            data={retrievedData.referralsPerRegion}
          />
          <ReportingStatistic
            tooltip={`Only clients who were referred in the provided time period are recorded in this statistic. A client is included if they have an upcoming initial assessment that is out of the provided time frame.`}
            label={`Total number of referrals per region that haven't done the initial assessment`}
            data={
              retrievedData.referralsPerRegionThatHaveNotCompletedInitialAssessment
            }
          />
        </Box>
      )}
    </Box>
  );
};
