const decodeBase64 = window.atob;

export const getRoles = function (authTok: string) {
  const tokenPayload = authTok.split(".")[1];
  const jwt = JSON.parse(decodeBase64(tokenPayload));
  return jwt["https://getcognito.ca/roles"];
};

export const checkForRole = function (
  role_required: string,
  roles_present: string[]
) {
  return roles_present.some(role => role === role_required);
};
