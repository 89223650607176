import { Link as MuiLink } from "@mui/material";
import { useState } from "react";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  usePermissions,
  WithRecord,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import { UserAccessDialog, ViewingDetails } from "./UserAccessDialog";
import { UserBulkActions } from "./userBulkActions";
import { userFilters } from "./userFilters";
import { Pagination } from "../../components/Pagination";
import { useHttpClient } from "../../utils";
import { checkForRole } from "../../utils/checkRoles";
import { birthdayDateOptions } from "../../utils/dateTime";

export const UsersList = props => {
  const { permissions } = usePermissions();
  const navigateTo = useNavigate();
  const { httpClient, baseUrl } = useHttpClient();

  const [selectedId, setSelectedId] = useState("");

  if (!permissions) {
    return null;
  }
  const isPharmacist = checkForRole("pharmacist", permissions);
  const isTeamLead = checkForRole("teamLead", permissions);

  const handleCloseDialog = async (args?: ViewingDetails) => {
    setSelectedId("");
    if (args) {
      navigateTo(`/users/${selectedId}/show`, {
        replace: true,
        state: {
          relationship: args.relationship,
          reason: args.reasonDescription ?? args.reason,
        },
      });
    }
  };

  const handleClickOnClient = async (userId: string) => {
    const res = await httpClient(`${baseUrl}/api/admins/prompt/${userId}`, {
      method: "GET",
    });
    if (res.json.shouldPrompt) {
      setSelectedId(userId);
    } else {
      navigateTo(`/users/${userId}/show`);
    }
  };

  return (
    <>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        filters={userFilters}
        perPage={100}
        empty={false}
        pagination={<Pagination />}
      >
        {isPharmacist ? (
          <Datagrid bulkActionButtons={false} sx={{ whiteSpace: "nowrap" }}>
            <WithRecord
              label={"Email"}
              render={record => (
                <MuiLink
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClickOnClient(record.id as string)}
                >
                  {record.email}
                </MuiLink>
              )}
            />
            <DateField source="birthday" options={birthdayDateOptions} />
            <TextField source="email" />
          </Datagrid>
        ) : (
          <Datagrid
            bulkActionButtons={isTeamLead ? <UserBulkActions /> : false}
            sx={{ whiteSpace: "nowrap" }}
          >
            <WithRecord
              label={"Email"}
              render={record => (
                <MuiLink
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClickOnClient(record.id as string)}
                >
                  {record.email}
                </MuiLink>
              )}
            />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="plan" />
            <TextField source="province" />
          </Datagrid>
        )}
      </List>
      <UserAccessDialog
        selectedId={selectedId}
        onCloseDialog={handleCloseDialog}
      />
    </>
  );
};
