import { Box } from "@material-ui/core";
import {
  Document as PDFDocument,
  PDFDownloadLink,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { Button, Confirm } from "react-admin";
import { AnxietyForm as IAnxietyForm } from "./Anxiety/types";
import { DepressionForm as IDepressionForm } from "./Depression/types";
import {
  convertDateToHumanReadable,
  convertSuicidalThoughts,
  gad7Questions,
  phq9Questions,
  translateGAD7Score,
  translatePHQ9Score,
} from "./utils";
import { useHttpClient } from "../../utils/useHttpClient";
import {
  AnswerLabels,
  QuestionAndAnswer,
  genericStyles,
} from "../AdhdInfo/PdfBuilder";

interface DisplayResultsProps {
  id: string;
  name: string;
  birthday: string;
  deleteCallback: () => void;
  hideCallback: () => void;
  type: "anxiety" | "depression";
}

export const DisplayResults: React.FC<DisplayResultsProps> = ({
  id,
  name,
  birthday,
  deleteCallback,
  hideCallback,
  type,
}) => {
  const { httpClient, baseUrl } = useHttpClient();
  const [data, setData] = useState<IAnxietyForm | IDepressionForm | undefined>(
    undefined
  );
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const endpoint = type === "anxiety" ? "anxiety_forms" : "depression_forms";
  const title = type === "anxiety" ? "GAD-7" : "PHQ-9";
  const translateScore =
    type === "anxiety" ? translateGAD7Score : translatePHQ9Score;
  const questions = type === "anxiety" ? gad7Questions : phq9Questions;

  useEffect(() => {
    const fetchData = async () => {
      const res = await httpClient(`${baseUrl}/api/${endpoint}/${id}`);
      setData(JSON.parse(res.body));
    };
    fetchData();
  }, [id]);

  const styles = StyleSheet.create({
    score: { fontSize: "14px", fontStyle: "bold", paddingTop: "10pt" },
  });

  function Document() {
    return (
      <PDFDocument>
        <Page size="A4" style={genericStyles.page}>
          <View style={{ display: "flex", alignItems: "center" }}>
            <Text>{title}</Text>
            <Text>{`${name} (${birthday})`}</Text>
            <Text>{`Completed on ${convertDateToHumanReadable(
              data?.createdDate
            )}`}</Text>
          </View>
          <View key={"Title"}>
            <AnswerLabels
              questions={[
                "Not at all",
                "Several Days",
                "More than half the days",
                "Nearly every day",
              ]}
            />
            {questions.map(questionInfo => (
              <View key={questionInfo.field}>
                <QuestionAndAnswer
                  key={questionInfo.field}
                  question={questionInfo.question}
                  answer={data![questionInfo.field]!}
                  potentialScores={[0, 1, 2, 3]}
                />
              </View>
            ))}
            {type === "depression" &&
            (data as IDepressionForm).recentThoughtsOfHurtingSelf ? (
              <Text style={styles.score}>
                {`Recent thoughts of hurting self: ${convertSuicidalThoughts(
                  (data as IDepressionForm).recentThoughtsOfHurtingSelf!
                )}`}
              </Text>
            ) : null}
            <Text style={styles.score}>
              {`Total Score: ${data?.totalSum} - ${translateScore(
                data!.totalSum!
              )}`}
            </Text>
            {questions.every(q => data![q.field] === 0) &&
            data!.totalSum !== 0 ? (
              <Text style={styles.score}>
                {`*If all answers are "Not at all" but there is still a score, this is because this result was manually entered and only the Total Score was supplied`}
              </Text>
            ) : null}
          </View>
        </Page>
      </PDFDocument>
    );
  }

  const handleDelete = async () => {
    await httpClient(`${baseUrl}/api/${endpoint}/${id}`, {
      method: "DELETE",
    });
    setOpenDeleteDialog(false);
    deleteCallback();
  };

  if (!data) {
    return null;
  }

  return (
    <Box>
      <Box display="flex" flexDirection={"row"} justifyContent="center">
        <Box flex={1}>
          <PDFDownloadLink
            document={<Document />}
            fileName={`${title} - ${name} - ${convertDateToHumanReadable(
              data?.createdDate
            )}.pdf`}
          >
            {({ loading }) => (loading ? "Loading document..." : "Download")}
          </PDFDownloadLink>
          <Button label="Hide Result" onClick={hideCallback} />
        </Box>
        <Box>
          <Button
            style={{ color: "red" }}
            label="Delete"
            onClick={() => setOpenDeleteDialog(true)}
          />
          <Confirm
            title="Delete"
            isOpen={openDeleteDialog}
            content="Are you sure you want to delete this?"
            onConfirm={handleDelete}
            onClose={() => setOpenDeleteDialog(false)}
          />
        </Box>
      </Box>
      <Box sx={{ height: "50vh" }}>
        <PDFViewer showToolbar={false} height="100%" width="50%">
          <Document />
        </PDFViewer>
      </Box>
    </Box>
  );
};
