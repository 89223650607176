import { Box, Typography } from "@material-ui/core";
import {
  Document as PDFDocument,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import React from "react";
import { CognitoAdhdAssessmentFormPage } from "./CognitoAdhdAssessmentFormPage";
import { CognitoAdhdAssessmentForm } from "./types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../Prescreenings/Accordions";

export const CognitoAdhdAssessmentFormDocument: React.FC<{
  data: CognitoAdhdAssessmentForm;
  clientName: string;
  clientCreatedAt: Date;
}> = ({ data, clientName, clientCreatedAt }) => {
  const Document = () => (
    <PDFDocument>
      <CognitoAdhdAssessmentFormPage
        clientCreatedAt={clientCreatedAt}
        clientName={clientName}
        data={data}
      />
    </PDFDocument>
  );

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="adhd-assessment-form-content"
        id="adhd-assessment-form-header"
      >
        <Typography>Cognito ADHD Assessment</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PDFDownloadLink
          document={<Document />}
          fileName={`${clientName} - Cognito ADHD Assessment Form.pdf`}
        >
          {({ loading }) => (loading ? "Loading document..." : "Download now")}
        </PDFDownloadLink>
        <Box sx={{ height: "80vh" }}>
          <PDFViewer showToolbar={false} height="100%" width="100%">
            <Document />
          </PDFViewer>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
