import { parse } from "query-string";
import {
  DateInput,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useLocation } from "react-router";
import { SaveCancelToolBar } from "../../components/SaveCancelToolBar";
import { dateFormatter, dateParser } from "../../utils/dateInputFilters";

export const RefillEdit = props => {
  const pageTitle = "Edit Refill";

  const location = useLocation();
  const { prescription_id: prescription_id_string } = parse(location.search);
  const redirectPath = prescription_id_string
    ? `/prescriptions/${prescription_id_string}/show`
    : undefined;

  return (
    <Edit
      {...props}
      title={pageTitle}
      redirect={redirectPath}
      mutationMode={"optimistic"}
    >
      <SimpleForm toolbar={<SaveCancelToolBar path={`/#${redirectPath}`} />}>
        <NumberInput source="refillsRemaining" disabled />
        <DateInput
          source="dateFilled"
          required
          parse={dateParser}
          format={dateFormatter}
        />
        <NumberInput source="cost" required min={0} />
        <NumberInput source="insuranceDiscount" min={0} />
        <TextInput source="trackingNumber" />
      </SimpleForm>
    </Edit>
  );
};
