import { IPrescreening } from "./Prescreening";
import { SortField } from "./SortFields";

export const removeField = (
  fields: { name: string }[],
  fieldName: keyof IPrescreening
) => {
  fields.splice(
    fields.findIndex(field => field.name === fieldName),
    1
  );
};

export const removeSubsection = (
  fields: SortField[],
  fieldName: keyof IPrescreening
) => {
  fields.splice(
    fields.findIndex(field =>
      field.subsection?.fields.some(field => field.name === fieldName)
    ),
    1
  );
};

/**
 * https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
 */
export const formatPhoneNumber = phoneNumberString => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
};

/** Converts "hi there" -> "Hi There" */
export const convertEveryFirstLetterToCapital = (text: string) => {
  if (!text) {
    return;
  }
  return text
    .split(" ")
    .map(word => `${word[0]?.toLocaleUpperCase() ?? ""}${word.substring(1)}`)
    .join(" ");
};
