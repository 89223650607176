import { CADDRAMedicationFormFields } from "./types";

export const sideEffectsQuestions: {
  question: string;
  field: CADDRAMedicationFormFields;
}[] = [
  {
    question: "Appetite Reduction",
    field: "appetiteReduction",
  },
  {
    question: "Weight Loss",
    field: "weightLoss",
  },
  {
    question: "Weight Gain",
    field: "weightGain",
  },
  {
    question: "Stomachaches",
    field: "stomachaches",
  },
  {
    question: "Nausea",
    field: "nausea",
  },
  {
    question: "Vomiting",
    field: "vomiting",
  },
  {
    question: "Diarrhea",
    field: "diarrhea",
  },
  {
    question: "Dryness",
    field: "dryness",
  },
  {
    question: "Thirst",
    field: "thirst",
  },
  {
    question: "Sore Throat",
    field: "soreThroat",
  },
  {
    question: "Sleep Difficulties",
    field: "sleepDifficulties",
  },
  {
    question: "Tics",
    field: "tics",
  },
  {
    question: "Headaches",
    field: "headache",
  },
  {
    question: "Muscular Tensions",
    field: "muscularTensions",
  },
  {
    question: "Fatigue",
    field: "fatigue",
  },
  {
    question: "Dizziness",
    field: "dizziness",
  },
  {
    question: "Sweating",
    field: "sweating",
  },
  {
    question: "Agitation or Excitability",
    field: "agitationOrExcitability",
  },
  {
    question: "Irritability",
    field: "irritability",
  },
  {
    question: "Mood Instability",
    field: "moodInstability",
  },
  {
    question: "Over Focus",
    field: "overFocus",
  },
  {
    question: "Sadness",
    field: "sadness",
  },
  {
    question: "Heart Palpitations",
    field: "heartPalpitations",
  },
  {
    question: "Blood Pressure Changes",
    field: "bloodPressureChanges",
  },
  {
    question: "Frequent Urination",
    field: "frequentUrination",
  },
  {
    question: "Sexual Dysfunction",
    field: "sexualDysfunction",
  },
  {
    question:
      "Feeling worse or different when the medication wears off (rebound)",
    field: "rebound",
  },
];

export const CADDRAMedicationQuestions = [
  { title: "Side Effects", questions: sideEffectsQuestions },
];
