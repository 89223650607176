/**
 * @param date If no date is passed in the current date is used
 * @param options How the date should be formatted
 * @returns A string date e.g Sept 1, 2022
 */
const getHumanReadableDate = (args?: {
  date?: number | string;
  options?: Intl.DateTimeFormatOptions;
}) => {
  const { date, options } = args ?? {};
  const d = date ? new Date(date) : new Date();
  return d.toLocaleDateString("default", options);
};

export const convertDateToHumanReadable = (
  date?: string,
  options: { dateInUtc?: boolean } = {}
) => {
  const { dateInUtc } = options;

  return getHumanReadableDate({
    date,
    options: {
      month: "short",
      day: "numeric",
      year: "numeric",
      timeZone: dateInUtc ? "UTC" : undefined,
    },
  });
};

export const convertSuicidalThoughts = (score: number) => {
  if (score === 1) {
    return "A vague or general thought or feeling";
  }
  if (score === 2) {
    return "Specific plans have been made";
  }
};

export const convertScoreToAnswer = (score: number) => {
  if (score === 0) {
    return "Not at all";
  }
  if (score === 1) {
    return "Several Days";
  }
  if (score === 2) {
    return "More than half the days";
  }
  return "Nearly every day";
};

export const gad7Questions = [
  {
    question: "Feeling nervous, anxious, or on edge",
    field: "feelingNervousAnxiousOrOnEdge",
  },
  {
    question: "Not being able to stop or control worrying",
    field: "notBeingAbleToStopOrControlWorrying",
  },
  {
    question: "Worrying too much about different things",
    field: "worryingTooMuchAboutDifferentThings",
  },
  {
    question: "Trouble relaxing",
    field: "troubleRelaxing",
  },
  {
    question: "Being so restless that it is hard to sit still",
    field: "beingSoRestlessThatItsHardToSitStill",
  },
  {
    question: "Becoming easily annoyed or irritable",
    field: "becomingEasilyAnnoyedOrIrritable",
  },
  {
    question: "Feeling afraid, as if something awful might happen",
    field: "feelingAfraidAsIfSomethingAwfulMightHappen",
  },
];

export const translateGAD7Score = (score: number) => {
  if (score < 5) {
    return "Minimal Anxiety";
  }
  if (score < 10) {
    return "Mild Anxiety";
  }
  if (score < 15) {
    return "Moderate Anxiety";
  }
  return "Severe Anxiety";
};

export const phq9Questions = [
  {
    question: "Little interest or pleasure in doing things",
    field: "littleInterestOrPleasureInDoingThings",
  },
  {
    question: "Feeling down, depressed or hopeless",
    field: "feelingDownDepressedOrHopeless",
  },
  {
    question: "Trouble falling or staying asleep, or sleeping too much",
    field: "troubleFallingOrStayingAsleep",
  },

  {
    question: "Feeling tired or having little energy",
    field: "feelingTired",
  },
  {
    question: "Poor appetite or overeating",
    field: "poorAppetite",
  },
  {
    question:
      "Feeling bad about yourself - or that you are a failure or have let yourself or your family down",
    field: "feelingBadAboutYourself",
  },

  {
    question:
      "Trouble concentrating on things, such as reading the newspaper or watching television",
    field: "troubleConcentrating",
  },
  {
    question:
      "Moving or speaking so slowly that other people have noticed? Or the opposite - being so fidgety or restless that " +
      "you have been moving around a lot more than usual",
    field: "movingOrSpeakingSlowly",
  },
  {
    question:
      "Thoughts that you would be better off dead or hurting yourself in some way",
    field: "thoughtsBetterOffDeadOrHurtingYourself",
  },
];

export const translatePHQ9Score = (score: number) => {
  if (score < 5) {
    return "None/Minimal Depression";
  }
  if (score < 10) {
    return "Mild Depression";
  }
  if (score < 15) {
    return "Moderate Depression";
  }
  if (score < 21) {
    return "Moderately Severe Depression";
  }
  return "Severe Depression";
};
