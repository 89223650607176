import { SelectInput, TextInput } from "react-admin";

export const referralSelection = [
  { id: "Google", name: "Google" },
  { id: "Facebook / Instagram", name: "Facebook / Instagram" },
  { id: "TikTok", name: "TikTok" },
  { id: "Doctor / Referral", name: "Doctor / Referral" },
  { id: "Friends / Family", name: "Friends / Family" },
  { id: "Newsletter", name: "Newsletter" },
  { id: "Cridge Pharmacy", name: "Cridge Pharmacy" },
  { id: "Bark", name: "Bark" },
];

export const userFilters = [
  <TextInput
    label="Creation Method"
    source="creationMethod"
    name="creationMethod"
    resettable
  />,
  <TextInput
    label="First Name"
    source="firstName"
    alwaysOn
    name="firstName"
    resettable
  />,
  <TextInput
    label="Last Name"
    source="lastName"
    alwaysOn
    name="lastName"
    resettable
  />,
  <TextInput label="Email" source="email" alwaysOn name="email" resettable />,
  <SelectInput
    choices={referralSelection}
    source="referralSource"
    emptyText={"Show All"}
    fullWidth
  />,
];
