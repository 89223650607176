import { parse } from "query-string";
import {
  BooleanInput,
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useLocation } from "react-router";
import { pharmacistNameChoices, prescriberChoices } from "./StaffNames";
import { SaveCancelToolBar } from "../../components/SaveCancelToolBar";

export const PrescriptionEdit = props => {
  const pageTitle = "Edit Prescription";

  const location = useLocation();
  const { user_id: user_id_string } = parse(location.search);

  const redirectCancelPath = user_id_string
    ? `/#/users/${user_id_string}/show`
    : `/#${location.pathname}/show`;

  const redirectPath = `${location.pathname}/show`;

  const transform = data => {
    const { prescriberCalendlyId } = data;
    const updatedPrescription = {
      ...data,
    };
    if (prescriberCalendlyId) {
      const prescriberName = prescriberChoices.find(
        prescriber => prescriber.id === prescriberCalendlyId
      )?.value;
      updatedPrescription.prescriberName = prescriberName;
    }

    return updatedPrescription;
  };

  return (
    <Edit
      {...props}
      title={pageTitle}
      redirect={redirectPath}
      mutationMode={"optimistic"}
      transform={transform}
    >
      <SimpleForm toolbar={<SaveCancelToolBar path={redirectCancelPath} />}>
        <TextInput source="name" required />
        <TextInput source="strength" required />
        <TextInput source="directions" required />
        <NumberInput source="quantityFilled" required step={1} min={0} />
        <NumberInput source="daysSupply" step={1} min={0} required />
        <BooleanInput source="refillRequested" />
        <BooleanInput source="cancelled" />
        <SelectInput
          source="prescriberCalendlyId"
          required
          label="Prescriber"
          choices={prescriberChoices}
        />
        <SelectInput
          source="pharmacistName"
          required
          choices={pharmacistNameChoices}
        />
        <TextInput source="pharmacyName" required />
        <TextInput source="pharmacyPhoneNumber" required />
      </SimpleForm>
    </Edit>
  );
};
