import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import axios, { AxiosRequestHeaders } from "axios";
import { CSSProperties, useEffect, useState } from "react";
import { REACT_APP_AUTH0_AUDIENCE } from "../../utils";
import { millisecondsInADay } from "../../utils/dateTime";

interface SubscriptionStatusProps {
  id: string;
  styling?: CSSProperties;
}

function getDaysOverdue(dueDate: number) {
  const curDate = new Date().getTime();

  return Math.ceil((curDate - dueDate) / millisecondsInADay);
}

const SubscriptionStatus = ({
  id,
  styling: style,
}: SubscriptionStatusProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const [subscriptionInfoData, setSubscriptionInfoData] = useState<
    { status: string; relatedDate: number } | undefined
  >(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      // Add content-type and Authorization
      const headers: AxiosRequestHeaders = {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const url = `${REACT_APP_AUTH0_AUDIENCE}/subscription/status/${id}`;
      const results = await axios.request({
        method: "GET",
        url,
        headers,
      });
      setSubscriptionInfoData(results.data ?? {});
    };
    if (!id || subscriptionInfoData) {
      return;
    }
    fetchData();
  });

  if (!subscriptionInfoData) {
    return null;
  }

  const status = subscriptionInfoData?.status;

  if (status === "active") {
    return <Box sx={{ color: "green", ...style }}>{status}</Box>;
  }
  if (status === "cancel_at_period_end") {
    const periodEnd = new Date(subscriptionInfoData.relatedDate * 1000);
    return (
      <Box
        sx={{ color: "orange", ...style }}
      >{`Cancels on ${periodEnd.toDateString()}`}</Box>
    );
  }
  if (status === "canceled") {
    // convert time from stripe in seconds to milliseconds before making new Date
    const dateCancelled = new Date(subscriptionInfoData.relatedDate * 1000);
    return (
      <Box
        sx={{ color: "red", ...style }}
      >{`Canceled on ${dateCancelled.toDateString()}`}</Box>
    );
  } else if (status === "past_due" || status === "unpaid") {
    // convert time from stripe in seconds to milliseconds
    const daysOverdue = getDaysOverdue(subscriptionInfoData.relatedDate * 1000);

    const daysString =
      daysOverdue === 1
        ? `(${daysOverdue} day overdue)`
        : `(${daysOverdue} days overdue)`;

    return (
      <Box sx={{ color: "red", ...style }}>{`${status.replace(
        "_",
        " "
      )} ${daysString}`}</Box>
    );
  }
  if (status === "trialing" || status === "paused") {
    return <Box sx={{ color: "orange", ...style }}>{status}</Box>;
  }
  return <Box sx={{ color: "red", ...style }}>{status}</Box>;
};

export default SubscriptionStatus;
