import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  useRecordContext,
} from "react-admin";
import { eventDateOptions } from "../../utils/dateTime";

const Title = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  return (
    <span>
      {record.user.firstName} {record.user.lastName}
    </span>
  );
};

export const LoginActivitiesShow = props => {
  return (
    <Show {...props} title={<Title />}>
      <SimpleShowLayout>
        <TextField label="ID" source="id" emptyText="null" />
        <TextField label="IP" source="ip" emptyText="null" />
        <TextField
          label="First Name"
          source="user.firstName"
          emptyText="null"
        />
        <TextField label="Last Name" source="user.lastName" emptyText="null" />
        <TextField label="Email" source="email" />
        <TextField source="portal" />
        <DateField
          source="createdAt"
          emptyText="null"
          options={eventDateOptions}
          showTime
        />
      </SimpleShowLayout>
    </Show>
  );
};
