import moment from "moment";
import { DateInput } from "react-admin";

const parseDate = (dateString: string) => {
  if (dateString === "") {
    return null;
  }
  return moment.utc(dateString).add(1, "day").format("YYYY-MM-DD");
};

const formatDate = (dateString: string | null) => {
  if (dateString == null || dateString === "") {
    return "";
  }
  return moment.utc(dateString).subtract(1, "day").format("YYYY-MM-DD");
};

export const InclusiveEndDateInput = ({ source, alwaysOn }) => (
  <DateInput
    label="End Date (Inclusive)"
    format={formatDate}
    parse={parseDate}
    source={source}
    alwaysOn={alwaysOn}
  />
);
