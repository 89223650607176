import {
  Datagrid,
  DateField,
  List,
  ListProps,
  Loading,
  TextField,
  useListContext,
} from "react-admin";
import { failedReferralReasonsFilters } from "./failedReferralReasonsFilters";
import { Pagination } from "../../components/Pagination";
import { birthdayDateOptions, eventDateOptions } from "../../utils/dateTime";

export const FailedReferralReasonsList = (props: ListProps) => {
  const { isLoading } = useListContext();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        filters={failedReferralReasonsFilters}
        perPage={100}
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="Admin Name" source="adminName" />
          <TextField label="Admin Email" source="adminEmail" />
          <TextField label="Admin Region" source="adminRegion" />
          <TextField label="Client Name" source="clientName" />
          <DateField
            label="Client Birthday"
            source="clientBirthday"
            options={birthdayDateOptions}
          />
          <TextField
            label="Ineligibility Reason"
            source="ineligibilityReason"
          />
          <DateField source="createdAt" options={eventDateOptions} showTime />
        </Datagrid>
      </List>
    </>
  );
};
