declare global {
  interface Window {
    __COGNITO_ENVIRONMENT_VARIABLES__?: { [key: string]: string };
  }
}

export const REACT_APP_BASE_URL =
  window.__COGNITO_ENVIRONMENT_VARIABLES__?.["REACT_APP_BASE_URL"] ||
  process.env.REACT_APP_BASE_URL ||
  "https://api-staging.getcognito.ca";

export const REACT_APP_AUTH0_AUDIENCE =
  window.__COGNITO_ENVIRONMENT_VARIABLES__?.["REACT_APP_AUTH0_AUDIENCE"] ||
  process.env.REACT_APP_AUTH0_AUDIENCE ||
  "https://api-staging.getcognito.ca/api";

export const REACT_APP_AUTH0_DOMAIN =
  window.__COGNITO_ENVIRONMENT_VARIABLES__?.["REACT_APP_AUTH0_DOMAIN"] ||
  process.env.REACT_APP_AUTH0_DOMAIN ||
  "cognito-dev.us.auth0.com";

export const REACT_APP_AUTH0_CLIENT_ID =
  window.__COGNITO_ENVIRONMENT_VARIABLES__?.["REACT_APP_AUTH0_CLIENT_ID"] ||
  process.env.REACT_APP_AUTH0_CLIENT_ID ||
  "0LIhTfr8ABJ8zHbVpUFeNnxZvAR575MD";

export const REACT_APP_THIRD_PARTY_ADMIN =
  window.__COGNITO_ENVIRONMENT_VARIABLES__?.["REACT_APP_THIRD_PARTY_ADMIN"] ??
  process.env.REACT_APP_THIRD_PARTY_ADMIN;

/** The stripe price ID required for creating a subscription */
export const REACT_APP_PRICE_ID =
  window.__COGNITO_ENVIRONMENT_VARIABLES__?.["REACT_APP_PRICE_ID"] ??
  process.env.REACT_APP_PRICE_ID;

export const REACT_APP_THIRD_PARTY_LOGO_FILENAME =
  window.__COGNITO_ENVIRONMENT_VARIABLES__?.[
    "REACT_APP_THIRD_PARTY_LOGO_FILENAME"
  ] ?? process.env.REACT_APP_THIRD_PARTY_LOGO_FILENAME;

export const REACT_APP_INITIAL_CBT_SESSIONS =
  window.__COGNITO_ENVIRONMENT_VARIABLES__?.[
    "REACT_APP_INITIAL_CBT_SESSIONS"
  ] ?? process.env.REACT_APP_INITIAL_CBT_SESSIONS;

export const REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID =
  window.__COGNITO_ENVIRONMENT_VARIABLES__?.[
    "REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID"
  ] ?? process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID;
