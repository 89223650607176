import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "@mui/material";

export const CancelButton = props => {
  return (
    <Button
      variant="contained"
      color="error"
      startIcon={<CancelIcon />}
      href={props.path}
      sx={{ marginLeft: "1rem" }}
    >
      Cancel
    </Button>
  );
};
