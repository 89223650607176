import { WfirsFields } from "./types";

export interface Question {
  question: string;
  field: WfirsFields;
}

export const familyQuestions: Question[] = [
  {
    question: "Having problems with family",
    field: "havingProblemsWithFamily",
  },
  {
    question: "Having problems with spouse/partner",
    field: "havingProblemsWithPartner",
  },
  {
    question: "Relying on others to do things for you",
    field: "relyingOnOthersToDoThingsForYou",
  },
  {
    question: "Causing fighting in the family",
    field: "causingFightingInTheFamily",
  },
  {
    question: "Makes it hard for the family to have fun together",
    field: "makesItTHardForFamilyToHaveFunTogether",
  },
  {
    question: "Problems taking care of your family",
    field: "problemsTakingCareOfYourFamily",
  },
  {
    question: "Problems balancing your needs against those of your family",
    field: "problemsBalancingNeedsWithFamily",
  },
  {
    question: "Problems losing control with family",
    field: "problemsLosingControlWithFamily",
  },
];

export const workQuestions: Question[] = [
  {
    question: "Problems performing required duties",
    field: "problemsPerformingRequiredDuties",
  },
  {
    question: "Problems with getting your work done efficiently",
    field: "problemsWithGettingWorkDoneEfficiently",
  },
  {
    question: "Problems with your supervisor",
    field: "problemsWithSupervisor",
  },
  { question: "Problems keeping a job", field: "problemsKeepingJob" },
  { question: "Getting fired from work", field: "gettingFiredFromWork" },
  { question: "Problems working in a team", field: "problemsWorkingInATeam" },
  {
    question: "Problems with your attendance",
    field: "problemsWithAttendanceAtWork",
  },
  {
    question: "Problems with being late",
    field: "problemsWithBeingLateAtWork",
  },
  {
    question: "Problems taking on new tasks",
    field: "problemsTakingOnNewTasks",
  },
  {
    question: "Problems working to your potential",
    field: "problemsWorkingToPotentialAtWork",
  },
  {
    question: "Poor performance evaluations",
    field: "poorPerformanceEvaluations",
  },
];

export const schoolQuestions: Question[] = [
  { question: "Problems taking notes", field: "problemsTakingNotes" },
  {
    question: "Problems completing assignments",
    field: "problemsCompletingAssignments",
  },
  {
    question: "Problems getting your work done efficiently",
    field: "problemsGettingSchoolWorkDoneEfficiently",
  },
  { question: "Problems with teachers", field: "problemsWithTeachers" },
  {
    question: "Problems with school administrators",
    field: "problemsWithSchoolAdministrators",
  },
  {
    question: "Problems meeting minimum requirements to stay in school",
    field: "problemsMeetingMinRequirementsToStayInSchool",
  },
  {
    question: "Problems with attendance",
    field: "problemsWithAttendanceAtSchool",
  },
  {
    question: "Problems with being late",
    field: "problemsWithBeingLateAtSchool",
  },
  {
    question: "Problems with working to your potential",
    field: "problemsWorkingToPotentialAtSchool",
  },
  {
    question: "Problems with inconsistent grades",
    field: "problemsWithInconsistentGrades",
  },
];

export const lifeSkillsQuestions: Question[] = [
  {
    question: "Excessive or inappropriate use of internet, video games or TV",
    field: "excessiveUseOfDigitalEntertainment",
  },
  {
    question: "Problems keeping an acceptable appearance",
    field: "problemsKeepingAnAcceptableAppearance",
  },
  {
    question: "Problems getting ready to leave the house",
    field: "problemsGettingReadyToLeaveTheHouse",
  },
  { question: "Problems getting to bed", field: "problemsGettingToBed" },
  { question: "Problems with nutrition", field: "problemsWitNutrition" },
  { question: "Problems with sex", field: "problemsWithSex" },
  { question: "Problems with sleeping", field: "problemsWithSleeping" },
  { question: "Getting hurt or injured", field: "gettingHurtOrInjured" },
  { question: "Avoiding exercise", field: "avoidingExercise" },
  {
    question: "Problems keeping regular appointments with doctor/dentist",
    field: "problemsKeepingRegularAppointments",
  },
  {
    question: "Problems keeping up with household chores",
    field: "problemsKeepingUpWithChores",
  },
  { question: "Problems managing money", field: "problemsManagingMoney" },
];

export const selfConceptQuestions: Question[] = [
  { question: "Feeling bad about yourself", field: "feelingBadAboutYourself" },
  {
    question: "Feeling frustrated with yourself",
    field: "feelingFrustratedWithYourself",
  },
  { question: "Feeling discouraged", field: "feelingDiscouraged" },
  {
    question: "Not feeling happy with your life",
    field: "notFeelingHappyWithYourLife",
  },
  { question: "Feeling incompetent", field: "feelingIncompetent" },
];

export const socialQuestions: Question[] = [
  { question: "Getting into arguments", field: "gettingIntoArguments" },
  { question: "Trouble cooperating", field: "troubleCooperating" },
  {
    question: "Trouble getting along with people",
    field: "troubleGettingAlongWithPeople",
  },
  {
    question: "Problems having fun with other people",
    field: "problemsHavingFunWithOtherPeople",
  },
  {
    question: "Problems participating in hobbies",
    field: "problemsParticipatingInHobbies",
  },
  { question: "Problems making friends", field: "problemsMakingFriends" },
  { question: "Problems keeping friends", field: "problemsKeepingFriends" },
  {
    question: "Saying inappropriate things",
    field: "sayingInappropriateThings",
  },
  { question: "Complaints from neighbours", field: "complaintsFromNeighbours" },
];

export const riskQuestions: Question[] = [
  { question: "Aggressive driving", field: "aggressiveDriving" },
  {
    question: "Doing other things while driving",
    field: "doingOtherThingsWhileDriving",
  },
  { question: "Road rage", field: "roadRage" },
  {
    question: "Breaking or damaging things",
    field: "breakingOrDamagingThings",
  },
  {
    question: "Doing things that are illegal",
    field: "doingThingsThatAreIllegal",
  },
  {
    question: "Being involved with the police",
    field: "beingInvolvedWithThePolice",
  },
  { question: "Smoking cigarettes", field: "smokingCigarettes" },
  { question: "Smoking marijuana", field: "smokingMarijuana" },
  { question: "Drinking alcohol", field: "drinkingAlcohol" },
  { question: `Taking "street" drugs`, field: "takingStreetDrugs" },
  {
    question: "Sex without protection (birth control, condom)",
    field: "havingUnprotectedSex",
  },
  {
    question: "Sexually inappropriate behaviour",
    field: "sexuallyInappropriateBehaviour",
  },
  {
    question: "Being physically aggressive",
    field: "beingPhysicallyAggressive",
  },
  { question: "Being verbally aggressive", field: "beingVerballyAggressive" },
];

export const wfirsQuestions = [
  { title: "Family", questions: familyQuestions },
  { title: "Work", questions: workQuestions },
  { title: "School", questions: schoolQuestions },
  { title: "Life Skills", questions: lifeSkillsQuestions },
  { title: "Self-Concept", questions: selfConceptQuestions },
  { title: "Social", questions: socialQuestions },
  { title: "Risk", questions: riskQuestions },
];
