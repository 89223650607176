import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import { pharmacistNameChoices, prescriberChoices } from "./StaffNames";
import { Empty } from "../../components";
import { eventDateOptions } from "../../utils/dateTime";
import { getLatestRefill } from "../../utils/getLatestRefill";

export const PrescriptionShow = props => {
  const Title = () => {
    const record = useRecordContext();

    //the record can be empty while loading
    if (!record) {
      return null;
    }
    return <span>{record.name}</span>;
  };

  const RefillTopToolBar = () => {
    const record = useRecordContext();
    //the record can be empty while loading
    if (!record) {
      return null;
    }

    let latestRefill;
    let userId;

    // Coming from PrescriptionCreate the record stores the prescription and refill separately
    if (record.prescription) {
      latestRefill = record.refill;
      userId = record.prescription.user.id;
    } else {
      latestRefill = getLatestRefill(record);
      userId = record.user.id;
    }

    const refillPath = `/refills/create?prescription_id=${record.id}`;
    const prescriptionPath = `/prescriptions/create?user_id=${userId}`;

    const disableRefill: boolean = latestRefill?.refillsRemaining < 1;

    return (
      <TopToolbar>
        <Button label="Create New" component={Link} to={prescriptionPath} />
        <Tooltip
          title={
            disableRefill ? "No refills remaining for this prescription" : ""
          }
          arrow
          sx={{ fontSize: "30px" }}
        >
          <div>
            <Button
              label="Refill"
              component={Link}
              to={refillPath}
              disabled={disableRefill}
            />
          </div>
        </Tooltip>
      </TopToolbar>
    );
  };

  const PrescriptionActionButtons = () => {
    const record = useRecordContext();
    if (!record) {
      return null;
    }

    let name: string;
    let userId: string;

    // Coming from PrescriptionCreate the record stores the prescription and refill separately
    if (record.prescription) {
      name = record.prescription.name;
      userId = record.prescription.user.id;
    } else {
      name = record.name;
      userId = record.user.id;
    }

    return (
      <div>
        <DeleteWithConfirmButton
          confirmTitle={`Deleting "${name}"`}
          redirect={`/users/${userId}/show`}
          sx={{ float: "right" }}
        />
        <EditButton sx={{ float: "right" }} />
      </div>
    );
  };

  const RefillActionButtons = () => {
    const record = useRecordContext();
    if (!record) {
      return null;
    }
    const editPath = `/refills/${record.id}?prescription_id=${record.prescription.id}`;

    return (
      <div>
        <Button
          startIcon={<EditIcon />}
          label="edit"
          component={Link}
          to={editPath}
        />
        <DeleteWithConfirmButton
          confirmTitle={`Deleting refill from ${record.dateFilled}`}
          redirect={`/prescriptions/${record.prescription.id}/show`}
          sx={{ marginLeft: ".5rem" }}
        />
      </div>
    );
  };

  return (
    <Show {...props} title={<Title />} actions={<RefillTopToolBar />}>
      <SimpleShowLayout sx={{ alignItems: "end" }}>
        <PrescriptionActionButtons />
        <ReferenceField
          link="show"
          label="User"
          source="user.id"
          reference="users"
        >
          <TextField source="firstName" />
        </ReferenceField>
        <TextField source="name" emptyText="null" />
        <TextField source="strength" emptyText="null" />
        <TextField source="directions" emptyText="null" />
        <NumberField source="quantityFilled" emptyText="null" />
        <NumberField source="daysSupply" emptyText="null" />
        <BooleanField source="refillRequested" />
        <BooleanField source="cancelled" />
        <FunctionField
          label="Prescriber"
          render={record => {
            const prescriber = prescriberChoices.find(
              prescriber => prescriber.id === record.prescriberCalendlyId
            );
            return prescriber?.name;
          }}
        />
        <FunctionField
          label="Pharmacist"
          render={record => {
            const pharmacist = pharmacistNameChoices.find(
              pharmacist => pharmacist.id === record.pharmacistName
            );
            return pharmacist?.name;
          }}
        />
        <TextField source="pharmacyName" emptyText="null" />
        <TextField source="pharmacyPhoneNumber" emptyText="null" />
        <ReferenceManyField
          label="Refills"
          reference="refills"
          target="refillPrescription.id"
          sort={{ field: "dateFilled", order: "DESC" }}
        >
          <Datagrid bulkActionButtons={false} empty={<Empty />}>
            <NumberField source="refillsRemaining" />
            <DateField source="dateFilled" options={eventDateOptions} />
            <NumberField
              source="cost"
              sortable={false}
              options={{ style: "currency", currency: "usd" }}
            />
            <NumberField
              source="insuranceDiscount"
              sortable={false}
              emptyText="null"
              options={{ style: "currency", currency: "usd" }}
            />
            <NumberField
              source="finalCost"
              sortable={false}
              options={{ style: "currency", currency: "usd" }}
            />
            <TextField source="trackingNumber" sortable={false} />
            <RefillActionButtons />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
