import { CognitoAdhdAssessmentForm } from "./types";
import { convertBornToHumanReadable } from "./utils";
import { convertBooleanToYesNo, onNewAdhdFlow } from "../utils";
import { ExtendedWeissSymptomRecordIIAnswersText } from "../weissSymptomRecordII/ExtendedWeissSymptomRecordIIAnswersText";
import { WeissSymptomRecordIIForm } from "../weissSymptomRecordII/types";

// Note this is duplicated in PDF form in CognitoAdhdAssessmentFormPDF.tsx
export const getCognitoAdhdAssessmentFormText = (args: {
  data: CognitoAdhdAssessmentForm & WeissSymptomRecordIIForm;
  clientCreatedAt: Date;
}) => {
  const { clientCreatedAt, data } = args;
  if (!onNewAdhdFlow(clientCreatedAt)) {
    return "Client not prompted to fill out the Cognito ADHD assessment form as they were created before the new ADHD flow launch.";
  }

  let text = "";

  const addText = (addedText?: string, newLine?: boolean) => {
    text += `${newLine ? "\n" : ""}${addedText}\n`;
  };

  if (!data.reasonForAdhdAssessment) {
    addText(
      `Client has not begun filling out the Cognito ADHD assessment form`
    );
    addText(ExtendedWeissSymptomRecordIIAnswersText(data));
    return text;
  }

  addText("REASON FOR SEEKING ASSESSMENT");
  addText(data.reasonForAdhdAssessment);
  addText(data.specificAdhdExperiences);

  addText("CHIEF COMPLAINTS", true);
  addText(data.primaryConcerns?.join(", "));

  addText("HISTORY OF PRESENTING ILLNESS", true);
  addText(
    `Previously assessed or diagnosed with ADHD: ${convertBooleanToYesNo(
      data.previouslyAssessedOrDiagnosedWithAdhd
    )}`
  );
  if (data.previouslyAssessedOrDiagnosedWithAdhd) {
    addText(data.previouslyAssessedOrDiagnosedWithAdhdDetails);
  }

  addText("Weiss Symptom Record II", true);
  addText(ExtendedWeissSymptomRecordIIAnswersText(data));

  addText("DEVELOPMENTAL HISTORY", true);
  addText(`Born: ${convertBornToHumanReadable(data.born)}`);
  addText(
    `Mother consumed substances while pregnant: ${convertBooleanToYesNo(
      data.motherConsumedSubstancesWhilePregnant
    )}`
  );
  if (data.motherConsumedSubstancesWhilePregnant) {
    addText(data.motherConsumedSubstancesWhilePregnantDetails);
  }

  addText(
    `Gross motor difficulties: ${convertBooleanToYesNo(
      data.hadGrossMotorDifficulties
    )}`
  );
  if (data.hadGrossMotorDifficulties) {
    addText(data.hadGrossMotorDifficultiesDetails);
  }

  addText(
    `Fine motor difficulties: ${convertBooleanToYesNo(
      data.hadFineMotorDifficulties
    )}`
  );
  if (data.hadFineMotorDifficulties) {
    addText(data.hadFineMotorDifficultiesDetails);
  }

  addText(
    `Language difficulties: ${convertBooleanToYesNo(
      data.hadLanguageDifficulties
    )}`
  );
  if (data.hadLanguageDifficulties) {
    addText(data.hadLanguageDifficultiesDetails);
  }
  addText(`Off behaviours: ${convertBooleanToYesNo(data.hadOffBehaviours)}`);
  if (data.hadOffBehaviours) {
    addText(data.hadOffBehavioursDetails);
  }
  addText(`Childhood temperament: ${data.childhoodTemperament}`);

  addText("FAMILY HISTORY", true);
  addText(`Born and raised: ${data.bornAndRaisedLocation}`);
  addText(`Immediate Family Members: ${data.immediateFamilyMembers}`);
  addText(`Family Atmosphere: ${data.childhoodAndFamilyEnvironment}`);
  addText(`Family Strengths: ${data.positiveAspectsOfFamily}`);
  addText(`Attitude towards Chores and Rules: ${data.choresAndFollowingRules}`);
  addText(`Discipline in Upbringing: ${data.disciplineAndRules}`);
  addText(
    `Family Stressors and Coping: ${data.howDidFamilyHandleDifficulties} ${data.violenceWithinFamilyEnvironment}`
  );
  addText(`Parents' Relationship: ${data.parentRelationshipDescription}`);
  addText(
    `Relationship with Parents/Guardians: ${data.relationshipWithParents}`
  );
  addText(
    `Relationship with Siblings: ${
      data.hasSiblings === false
        ? "Does not have siblings"
        : data.relationshipWithSiblings
    }`
  );
  addText(
    `Current Family Stressors: ${
      data.currentFamilyLifeStressors === true
        ? data.currentFamilyLifeStressorsDetails
        : "No stressors"
    }`
  );
  addText(
    `Relationship with Current Partner: ${
      data.hasAPartner === true
        ? data.relationshipWithCurrentPartner
        : "Does not currently have a partner"
    }`
  );
  addText(
    `Parenting Style Strengths and Challenges: ${
      data.isAParent === false
        ? "Is not a parent"
        : data.strengthsAndWeaknessesOfParentingStyle
    }`
  );

  addText("EDUCATION", true);
  addText("Elementary (Kindergarten to Grade 6)");
  addText(`Academics: ${data.elementaryAcademicStatus}`);
  addText(`Homework experience/attitude: ${data.elementaryHomeworkExperience}`);
  addText(
    `Elementary class difficulties: ${convertBooleanToYesNo(
      data.hadElementaryClassDifficulties
    )}`
  );
  if (data.hadElementaryClassDifficulties) {
    addText(data.hadElementaryClassDifficultiesDetails);
  }
  addText(`Report card comments: ${data.elementaryReportCardComments}`);
  addText(
    `Elementary school additional support: ${convertBooleanToYesNo(
      data.hadElementaryAdditionalSupport
    )}`
  );
  if (data.hadElementaryAdditionalSupport) {
    addText(data.hadElementaryAdditionalSupportDetails);
  }
  addText(`Peer interactions: ${data.elementaryPeerInteraction}`);
  addText("Middle School / High School (Grade 6-12)", true);
  addText(`Academics: ${data.secondarySchoolAcademicStatus}`);
  addText(
    `Homework experience/attitude: ${data.secondarySchoolHomeworkExperience}`
  );
  addText(
    `Secondary school class difficulties: ${convertBooleanToYesNo(
      data.hadSecondarySchoolClassDifficulties
    )}`
  );
  if (data.hadSecondarySchoolClassDifficulties) {
    addText(data.hadSecondarySchoolClassDifficultiesDetails);
  }
  addText(`Grades: ${data.secondarySchoolGrades}`);
  addText(
    `Secondary school additional support: ${convertBooleanToYesNo(
      data.hadSecondarySchoolAdditionalSupport
    )}`
  );
  if (data.hadSecondarySchoolAdditionalSupport) {
    addText(data.hadSecondarySchoolAdditionalSupportDetails);
  }
  addText(
    `Secondary school additional accommodations: ${convertBooleanToYesNo(
      data.requiredSecondarySchoolAccommodations
    )}`
  );
  if (data.requiredSecondarySchoolAccommodations) {
    addText(data.requiredSecondarySchoolAccommodationsDetails);
  }

  addText("Post Secondary", true);
  addText(
    `Attended post secondary: ${convertBooleanToYesNo(
      data.attendedPostSecondary
    )}`
  );
  if (data.attendedPostSecondary) {
    addText(`Field: ${data.postSecondaryField}`);
    addText(`Lecture experience: ${data.postSecondaryLectureExperience}`);
    addText(`Difficulties: ${data.postSecondaryDifficulties}`);
    addText(
      `Reached post secondary potential: ${convertBooleanToYesNo(
        data.reachedPostSecondaryPotential
      )}`
    );
    if (!data.reachedPostSecondaryPotential) {
      addText(data.reachedPostSecondaryPotentialDetails);
    }
  }

  addText("EMPLOYMENT", true);
  addText(data.currentEmploymentStatus);
  addText(`Estimated number of past jobs: ${data.estimatedNumberOfPastJobs}`);
  addText(`Longest employment length: ${data.longestEmploymentLength}`);
  addText(`Current position strengths: ${data.currentPositionStrengths}`);
  addText(`Current position weaknesses: ${data.currentPositionWeaknesses}`);
  addText(
    `Work difficulties: ${convertBooleanToYesNo(data.hasWorkDifficulties)}`
  );
  if (data.hasWorkDifficulties) {
    addText(data.hasWorkDifficultiesDetails);
  }
  addText(
    `Workplace accommodations: ${convertBooleanToYesNo(
      data.requiresWorkplaceAccommodations
    )}`
  );
  if (data.requiresWorkplaceAccommodations) {
    addText(data.requiresWorkplaceAccommodationsDetails);
  }

  addText("CAR SAFETY", true);
  addText(`Drives: ${convertBooleanToYesNo(data.drives)}`);
  if (data.drives === false) {
    addText(`Reason for not driving: ${data.reasonForNotDriving}`);
  }
  if (data.drives === true) {
    addText(
      `Accidents or near misses as primary driver: ${convertBooleanToYesNo(
        data.hadAccidentsWhilePrimaryDriver
      )}`
    );
    if (data.hadAccidentsWhilePrimaryDriver) {
      addText(data.hadAccidentsWhilePrimaryDriverDetails);
    }
    addText(
      `Dangerous driving: ${convertBooleanToYesNo(data.dangerousDriver)}`
    );
    if (data.dangerousDriver) {
      addText(data.dangerousDriverDetails);
    }
  }
  addText(`Accident prone: ${convertBooleanToYesNo(data.accidentProne)}`);
  if (data.accidentProne) {
    addText(data.accidentProneDetails);
  }

  addText("LIFESTYLE", true);
  addText(`Exercise routine: ${data.exerciseRoutine}`);
  addText(
    `Difficulties with exercise routine: ${convertBooleanToYesNo(
      data.hasDifficultiesWithExerciseRoutine
    )}`
  );
  addText(`Favourite types of exercise: ${data.favouriteTypesOfExercise}`);
  addText(
    `Regularly engaging in extreme sports: ${convertBooleanToYesNo(
      data.regularlyEngagingInExtremeSports
    )}`
  );
  addText(`Typical eating pattern: ${data.typicalEatingPattern}`);
  addText(
    `Eating pattern difficulties: ${convertBooleanToYesNo(
      data.hasEatingPatternDifficulties
    )}`
  );
  addText(
    `Self care difficulties: ${convertBooleanToYesNo(
      data.hasSelfCareDifficulties
    )}`
  );
  if (data.hasSelfCareDifficulties) {
    addText(data.hasSelfCareDifficultiesDetails);
  }
  addText(`Hobbies: ${data.hobbies}`);
  addText(
    `Becomes bored with hobbies: ${convertBooleanToYesNo(
      data.becomesBoredWithHobbies
    )}`
  );

  return text;
};
