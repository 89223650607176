import { Box, Typography } from "@material-ui/core";
import {
  Document as PDFDocument,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import React from "react";
import { WeissSymptomRecordIIForm } from "./types";
import { WeissSymptomRecordIIPage } from "./WeissSymptomRecordIIPage";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../Prescreenings/Accordions";

export const WeissSymptomRecordIIDocument: React.FC<{
  data: WeissSymptomRecordIIForm;
  clientName: string;
  showExtendedAnswers: boolean;
}> = ({ data, clientName, showExtendedAnswers }) => {
  const Document = () => (
    <PDFDocument>
      <WeissSymptomRecordIIPage
        data={data}
        clientName={clientName}
        showExtendedAnswers={showExtendedAnswers}
      />
    </PDFDocument>
  );

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="WeissSymptomRecordII-content"
        id="WeissSymptomRecordII-header"
      >
        <Typography>Weiss Symptom Record II</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PDFDownloadLink
          document={<Document />}
          fileName={`${clientName} - Weiss Symptom Record II.pdf`}
        >
          {({ loading }) => (loading ? "Loading document..." : "Download now")}
        </PDFDownloadLink>
        <Box sx={{ height: "80vh" }}>
          <PDFViewer showToolbar={false} height="100%" width="100%">
            <Document />
          </PDFViewer>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
