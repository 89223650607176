function parsePayload(token: string) {
  // JWT structure: header.payload.signature
  return JSON.parse(atob(token.split(".")[1]));
}

export function doesTokenHaveRole(token: string, role: string) {
  const parsedPayload = parsePayload(token);
  const rolesKey = Object.keys(parsedPayload).find(key => {
    return key.includes("roles");
  });
  const parsedRoles = rolesKey ? parsedPayload[rolesKey] : [];
  return parsedRoles.includes(role);
}
