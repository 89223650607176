import {
  CardContent,
  CardHeader,
  Container,
  List,
  ListItem,
} from "@material-ui/core";
import { DashboardComponent } from "ra-core";
import { CardContainer } from "./app.styles";
import logo from "../assets/logo.png";
import { checkForRole } from "../utils/checkRoles";

const Dashboard: DashboardComponent = ({ permissions }) => {
  if (!permissions) {
    return null;
  }
  const isPharmacist = checkForRole("pharmacist", permissions);

  return (
    <Container style={{ marginTop: 16 }}>
      <CardContainer>
        <img alt="Cognito Logo" src={logo} width="100px" />
        <CardHeader
          title={`Welcome to the Dashboard!`}
          style={{
            maxWidth: 760,
            textAlign: "center",
          }}
        />
        <CardContent>
          Things you can do here:
          <List>
            <ListItem>
              See, search, and filter a read-only list of users
            </ListItem>
          </List>
        </CardContent>
      </CardContainer>
    </Container>
  );
};

export default Dashboard;
