import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { CADDRAMedicationQuestions } from "./questions";
import { CADDRAMedicationForm } from "./types";
import { getDescriptor, mapGlobalChangeScore } from "./utils";
import { AnswerLabels, QuestionAndAnswer, genericStyles } from "../PdfBuilder";

export const styles = StyleSheet.create({
  text: { fontSize: "14px", paddingTop: "10pt" },
});

export const MedicationPage: React.FC<{
  data: CADDRAMedicationForm;
  clientName: string;
}> = ({ data, clientName }) => {
  return (
    <Page size="A4" style={genericStyles.page}>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>CADDRA Patient ADHD Medication Form</Text>
      </View>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>{clientName}</Text>
      </View>
      {data.hasNeverTakenADHDMedication && (
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "10pt",
          }}
        >
          <Text>Client has never taken ADHD Medication before</Text>
        </View>
      )}
      {!data.hasNeverTakenADHDMedication && (
        <View>
          {data.medications && (
            <View>
              <Text style={genericStyles.sectionTitle}>ADHD Medications:</Text>
              {data.medications.map(medication => (
                <Text style={styles.text} key={medication}>
                  {medication}
                </Text>
              ))}
            </View>
          )}
          <Text style={styles.text}>
            {`ADHD Symptom Control: ${getDescriptor(data.adhdSymptomControl)}`}
          </Text>
          <Text style={styles.text}>
            {`Tolerability of Medication: ${getDescriptor(
              data.tolerabilityOfMedication
            )}`}
          </Text>
          <Text style={styles.text}>
            {`Quality of Life: ${getDescriptor(data.qualityOfLife)}`}
          </Text>
          <Text style={styles.text}>
            {`How would you rate the global changes that have occurred since medication started?: ${mapGlobalChangeScore(
              data.globalChangeSinceMedicationStarted
            )}`}
          </Text>
          <Text style={styles.text}>
            {`Additional Comments: ${data.comments ?? "None"}`}
          </Text>
          {CADDRAMedicationQuestions.map(questionSet => (
            <View key={questionSet.title}>
              <View style={{ paddingTop: "10pt" }}>
                <Text style={genericStyles.sectionTitle}>
                  {questionSet.title}
                </Text>
              </View>
              <AnswerLabels
                questions={["Not at all", "Sometimes", "Often", "All the time"]}
              />
              {questionSet.questions.map(questionInfo => (
                <View key={questionInfo.field}>
                  <QuestionAndAnswer
                    key={questionInfo.field}
                    question={questionInfo.question}
                    answer={data[questionInfo.field] as number}
                    potentialScores={[0, 1, 2, 3]}
                  />
                </View>
              ))}
            </View>
          ))}
          <Text style={styles.text}>
            {`Other Side Effects: ${data.otherSideEffects ?? "None"}`}
          </Text>
          <Text style={styles.text}>
            {`Items to Discuss: ${data.itemsToDiscuss ?? "None"}`}
          </Text>
        </View>
      )}
    </Page>
  );
};
