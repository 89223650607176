import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@material-ui/core";
import axios, { AxiosRequestHeaders } from "axios";
import React, { useEffect, useState } from "react";
import { useRecordContext } from "react-admin";
import { Allergies } from "./Allergies";
import { FamilyHistory } from "./FamilyHistory";
import { LetterOfAccommodation } from "./LetterOfAccommodation";
import { MedicalHistory } from "./MedicalHistory";
import { PrescriptionHistory } from "./PrescriptionHistory";
import { Reminders } from "./Reminders";
import { RiskFactors } from "./RiskFactors";
import { SocialHistory } from "./SocialHistory";
import { REACT_APP_AUTH0_AUDIENCE } from "../../utils";

export interface PrescreeningProps {
  emergencyContact: string;
  emergencyContactPhone: string;
  emergencyContactRelationship: string;
  supportName: string;
  supportRelationship: string;
  primaryCareProvider?: string;
  primaryCareProviderName?: string;
  primaryCareProviderPhone?: string;
  primaryCareProviderFax?: string;
}

export const Prescreening: React.FC<PrescreeningProps> = props => {
  const record = useRecordContext();
  const { getAccessTokenSilently } = useAuth0();

  const [data, setData] = useState<
    (IPrescreening & PrescreeningProps) | undefined
  >(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      // Add content-type and Authorization
      const headers: AxiosRequestHeaders = {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const url = `${REACT_APP_AUTH0_AUDIENCE}/health_info/${record.id}`;
      const results = await axios.request({
        method: "GET",
        url,
        headers,
      });
      const prescreeningData = results.data?.prescreeningInfo ?? {};
      setData({
        ...prescreeningData,
        ...props,
      });
    };
    if (!record || data) {
      return;
    }
    fetchData();
  });

  if (!data) {
    return null;
  }

  return (
    <>
      <Typography style={{ padding: "0.5rem 1rem" }} variant="h5">
        Prescreening
      </Typography>
      <SocialHistory data={data} />
      <MedicalHistory data={data} />
      <Reminders data={data} />
      <LetterOfAccommodation data={data} />
      <Allergies data={data} />
      <PrescriptionHistory data={data} />
      <RiskFactors data={data} />
      <FamilyHistory data={data} />
    </>
  );
};

export interface IPrescreening {
  initialVisitReasons?: string[];
  // Medical Conditions
  highBloodPressure?: boolean;
  diabetesMellitus?: boolean;
  hypoOrHyperthyroidism?: boolean;
  headInjuryOrConcussion?: boolean;
  stroke?: boolean;
  heartAttack?: boolean;
  arrhythmias?: boolean;
  chronicPain?: boolean;
  epilepsy?: boolean;
  cognitiveImpairment?: boolean;
  otherMedicalConditions?: boolean;
  otherMedicalConditionsDetails?: string;
  noChronicMedicalConditions?: boolean;
  previousSurgicalProcedures?: boolean;
  previousSurgicalProceduresDetails?: SurgicalProcedureInfo[];
  // Mental Health History
  requiresLOAForWork?: boolean;
  companyNameLOA?: string;
  workContactNameLOA?: string;
  workContactEmailLOA?: string;
  requiresLOAForSchool?: boolean;
  schoolNameLOA?: string;
  schoolContactNameLOA?: string;
  schoolContactEmailLOA?: string;
  generalizedAnxietyDisorder?: boolean;
  depressiveDisorder?: boolean;
  borderlinePersonalityDisorder?: boolean;
  bipolarDisorder?: boolean;
  schizophrenia?: boolean;
  adhd?: boolean;
  otherPsychiatricConditions?: boolean;
  otherPsychiatricConditionsDetails?: string;
  noPsychiatricConditions?: boolean;
  goneToEmergencyDueToMentalHealth?: boolean;
  goneToEmergencyDueToMentalHealthDetails?: string;
  familyMemberDiagnosedWithCondition?: boolean;
  familyMemberDiagnosedWithConditionDetails?: FamilyMemberDiagnosis[];
  // Mental Health History Cont.
  experiencedTrauma?: boolean | string;
  ptsdMemories?: boolean;
  ptsdNightmares?: boolean;
  relivingPtsdEvent?: boolean;
  ptsdOnGuard?: boolean;
  ptsdJumpy?: boolean;
  preferNotToAnswerPtsdSymptoms?: boolean;
  noPtsdSymptoms?: boolean;
  previouslySupportedWithTraumaBasedCounseling?: boolean | string;
  seekingTraumaFocusedTherapyFromCognito?: boolean | string;
  seekingTraumaFocusedTherapyFromCognitoDetails?: string;
  seekingOrEngagedInTraumaFocusedTherapyOutsideOfCognito?: boolean | string;
  seekingOrEngagedInTraumaFocusedTherapyOutsideOfCognitoDetails?: string;
  traumaConcerns?: boolean | string;
  traumaConcernsDetails?: string;
  // Social History
  knowSomeoneWhoHasUsedCognito?: boolean;
  knowSomeoneWhoHasUsedCognitoDetails?: KnownCognitoClient[];
  occupation?: string;
  livingSituation?: string;
  highestLevelOfEducation?: string;
  caffeineConsumption?: string;
  smokesOrVapes?: boolean;
  smokesOrVapesDetails?: string;
  usesCannabis?: boolean;
  usesCannabisDetails?: string;
  drinksAlcoholFrequency?: string;
  drinksAlcoholDetails?: string;
  usesRecreationalDrugs?: boolean;
  usesRecreationalDrugsDetails?: string;
  struggledWithAddictionInPast?: boolean;
  struggledWithAddictionInPastDetails?: string;
  // Prescriptions Info
  hasPreviousPrescriptions?: boolean;
  hasPreviousPrescriptionsDetails?: PreviousPrescriptionInfo[];
  hasCurrentPrescriptions?: boolean;
  hasCurrentPrescriptionsDetails?: CurrentPrescriptionInfo[];
  hasAllergies?: boolean;
  hasAllergiesDetails?: string | AllergiesInfo[];

  // Legacy info
  experiencedTraumaOrAbuse?: boolean;
  isTraumaOrAbuseCurrentlyImpacting?: boolean;
  supportedWithTraumaBasedCounseling?: boolean;
  consentForRecordsToBeSentToGP?: boolean;
  primaryPharmacy?: string;

  // etoh is a combination of drinksAlcoholFrequency and drinksAlcoholDetails
  etoh?: string;
}

export interface FamilyMemberDiagnosis {
  familyMember: string;
  diagnosis: string;
}
export interface SurgicalProcedureInfo {
  procedure: string;
  date: string;
}
export interface KnownCognitoClient {
  name: string;
  relationship: string;
}

export interface CurrentPrescriptionInfo {
  medication: string;
  dose: string;
  frequency: string;
}

export interface PreviousPrescriptionInfo {
  medication: string;
  duration: string;
  effectiveness:
    | "effective"
    | "partially effective"
    | "not effective"
    | "stopped due to side effects";
  sideEffects: string;
}

export interface AllergiesInfo {
  allergen: string;
  reaction: string;
  severity: "mild" | "moderate" | "severe";
}
