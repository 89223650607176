import axios, { AxiosRequestHeaders } from "axios";
import { Identifier } from "react-admin";
import { REACT_APP_AUTH0_AUDIENCE } from "./server";

export async function requestHelper<T>(
  url: string,
  recordId: Identifier,
  headers: AxiosRequestHeaders
) {
  const requestUrl = `${REACT_APP_AUTH0_AUDIENCE}/${url}/${recordId}`;
  const result = await axios.request<T>({
    method: "GET",
    url: requestUrl,
    headers,
  });
  return (result.data ?? {}) as T;
}
