import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";
import { fetchUtils } from "react-admin";
import { REACT_APP_BASE_URL } from "./server";

export const useHttpClient = () => {
  const { getAccessTokenSilently } = useAuth0();

  const handleFeedback = async (url, options) => {
    let result;
    try {
      result = await fetchUtils.fetchJson(url, options);
      result.json = { data: result.json.data, total: result.json.total };
    } catch (e) {
      return { json: { data: [], total: 0 } };
    }
    return result;
  };

  const httpClient = useMemo(
    () =>
      async (url, options: any = {}) => {
        if (!options.headers) {
          options.headers = new Headers({ Accept: "application/json" });
        }
        const token = await getAccessTokenSilently();
        options.headers.set("Authorization", `Bearer ${token}`);
        // Feedback currently isn't a crud provider so format its response to
        // be handled correctly by react-admin
        if (url.includes("api/feedback")) {
          return await handleFeedback(url, options);
        }
        return fetchUtils.fetchJson(url, options);
      },
    []
  );

  return { httpClient, baseUrl: REACT_APP_BASE_URL };
};
