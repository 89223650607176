import { Typography } from "@mui/material";
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "./Accordions";
import { IPrescreening } from "./Prescreening";
import { SortField, SortFields } from "./SortFields";
import { removeField } from "./utils";

interface RiskFactorsProps {
  data: IPrescreening;
}

export const RiskFactorsContent: React.FC<RiskFactorsProps> = ({ data }) => {
  const shouldHighlightETOH = () => {
    return (
      data.drinksAlcoholDetails === "5 or 6" ||
      data.drinksAlcoholDetails === "7, 8, or 9" ||
      data.drinksAlcoholDetails === "10 or more" ||
      data.drinksAlcoholFrequency === "4 or more times a week"
    );
  };

  let traumaFields: {
    name: string;
    label?: string;
    highlight?: boolean;
    data?: any;
    hideWhenTrue?: boolean;
  }[] = [
    {
      name: "experiencedTrauma",
      label: "Experienced Trauma",
      highlight: data.experiencedTrauma === true && !data.noPtsdSymptoms,
    },
    {
      name: "noPtsdSymptoms",
      label: "Has PTSD Symptoms",
      data:
        data.preferNotToAnswerPtsdSymptoms === true
          ? "Prefer not to answer"
          : data.noPtsdSymptoms === undefined
          ? undefined
          : !data.noPtsdSymptoms,
      highlight:
        data.noPtsdSymptoms === undefined ? false : !data.noPtsdSymptoms,
    },
  ];

  const ptsdDifficulties = [
    {
      name: "ptsdMemories",
      label: "PTSD Memories",
      highlight: data.ptsdMemories,
    },
    {
      name: "ptsdNightmares",
      label: "PTSD Nightmares",
      highlight: data.ptsdNightmares,
    },
    {
      name: "relivingPtsdEvent",
      label: "Reliving PTSD Event",
      highlight: data.relivingPtsdEvent,
    },
    {
      name: "ptsdOnGuard",
      label: "Being “super-alert”, watchful, or on guard",
      highlight: data.ptsdOnGuard,
    },
    {
      name: "ptsdJumpy",
      label: "Feeling jumpy or easily startled",
      highlight: data.ptsdJumpy,
    },
  ];

  if (
    data.noPtsdSymptoms === false &&
    data.preferNotToAnswerPtsdSymptoms !== true
  ) {
    traumaFields = [...traumaFields, ...ptsdDifficulties];
  }

  const furtherPTSDQuestions = [
    {
      name: "previouslySupportedWithTraumaBasedCounseling",
      highlight: true,
    },
    {
      name: "seekingTraumaFocusedTherapyFromCognito",
      highlight: data.seekingTraumaFocusedTherapyFromCognito === true,
      hideWhenTrue: true,
    },
    {
      name: "seekingTraumaFocusedTherapyFromCognitoDetails",
      highlight: true,
    },
    {
      name: "seekingOrEngagedInTraumaFocusedTherapyOutsideOfCognito",
      highlight:
        data.seekingOrEngagedInTraumaFocusedTherapyOutsideOfCognito === true,
      hideWhenTrue: true,
    },
    {
      name: "seekingOrEngagedInTraumaFocusedTherapyOutsideOfCognitoDetails",
      highlight: true,
    },
    {
      name: "traumaConcerns",
      highlight: data.traumaConcerns === true,
      hideWhenTrue: true,
    },
    {
      name: "traumaConcernsDetails",
      highlight: true,
    },
  ];
  if (
    data.experiencedTrauma === true &&
    data.noPtsdSymptoms === false &&
    data.preferNotToAnswerPtsdSymptoms === false
  ) {
    traumaFields = [...traumaFields, ...furtherPTSDQuestions];
  }

  if (
    data.seekingTraumaFocusedTherapyFromCognito === false ||
    data.seekingTraumaFocusedTherapyFromCognito === "Prefer not to answer"
  ) {
    removeField(traumaFields, "seekingTraumaFocusedTherapyFromCognitoDetails");
  }
  if (
    data.seekingOrEngagedInTraumaFocusedTherapyOutsideOfCognito === false ||
    data.seekingOrEngagedInTraumaFocusedTherapyOutsideOfCognito ===
      "Prefer not to answer"
  ) {
    removeField(
      traumaFields,
      "seekingOrEngagedInTraumaFocusedTherapyOutsideOfCognitoDetails"
    );
  }
  if (
    data.traumaConcerns === false ||
    data.traumaConcerns === "Prefer not to answer"
  ) {
    removeField(traumaFields, "traumaConcernsDetails");
  }

  const fields: SortField[] = [
    {
      name: "goneToEmergencyDueToMentalHealth",
      label: "Has gone to ER due to Mental Health",
      hideWhenTrue: true,
    },
    {
      name: "goneToEmergencyDueToMentalHealthDetails",
      label: "PREVIOUS ER/HOSPITALIZATION R/T MENTAL HEALTH",
    },
    {
      name: "",
      subsection: {
        label: "TRAUMA HX:",
        fields: traumaFields,
      },
    },
    {
      name: "",
      subsection: {
        label: "ANY SIGNIFICANT TRAUMA HX (Legacy Questions):",
        fields: [
          {
            name: "experiencedTraumaOrAbuse",
            label: "History of abuse",
            highlight:
              data.experiencedTraumaOrAbuse &&
              !data.supportedWithTraumaBasedCounseling,
          },
          {
            name: "isTraumaOrAbuseCurrentlyImpacting",
            label: "Trauma or abuse is currently impacting them",
            highlight: data.isTraumaOrAbuseCurrentlyImpacting,
          },
          {
            name: "supportedWithTraumaBasedCounseling",
            label: "Has trauma counselling",
            highlight:
              data.experiencedTraumaOrAbuse &&
              !data.supportedWithTraumaBasedCounseling,
          },
        ],
      },
    },
    {
      name: "",
      subsection: {
        label: "SUBSTANCE USE HX:",
        fields: [
          { name: "caffeineConsumption" },
          {
            name: "etoh",
            label: "ETOH",
            highlight: shouldHighlightETOH(),
          },
          {
            name: "smokesOrVapesDetails",
            label: "Nicotine",
          },
          {
            name: "usesCannabisDetails",
            label: "Cannabis",
          },
          { name: "usesRecreationalDrugsDetails", label: "Other Rec Drugs" },
        ],
      },
    },
    { name: "usesRecreationalDrugs", hideWhenTrue: true },
    { name: "struggledWithAddictionInPast", hideWhenTrue: true },
    { name: "struggledWithAddictionInPastDetails" },
  ];

  // Manipulate some data so that it's easier to present
  Object.keys(data).forEach(key => {
    if (data[key] === "preferNotToAnswer") {
      data[key] = "Prefer not to answer";
    }
  });
  if (data.smokesOrVapes === false) {
    data.smokesOrVapesDetails = "None";
  }
  if (data.usesCannabis === false) {
    data.usesCannabisDetails = "None";
  }
  if (data.usesRecreationalDrugs === false) {
    data.usesRecreationalDrugsDetails = "None";
  }

  if (data.goneToEmergencyDueToMentalHealth === false) {
    removeField(fields, "goneToEmergencyDueToMentalHealthDetails");
  }

  if (data.struggledWithAddictionInPast === false) {
    removeField(fields, "struggledWithAddictionInPastDetails");
  }

  if (data.experiencedTraumaOrAbuse === false) {
    data.isTraumaOrAbuseCurrentlyImpacting = "N/A" as any;
    data.supportedWithTraumaBasedCounseling = "N/A" as any;
  }

  if (data.drinksAlcoholFrequency && data.drinksAlcoholFrequency !== "Never") {
    data.etoh = `${data.drinksAlcoholFrequency}, ${data.drinksAlcoholDetails} units per session`;
  } else {
    data.etoh = `${data.drinksAlcoholFrequency}`;
  }

  return <SortFields data={data} fields={fields} />;
};

export const RiskFactors: React.FC<RiskFactorsProps> = ({ data }) => {
  return (
    <Accordion>
      <AccordionSummary
        aria-controls="RiskFactors-content"
        id="RiskFactors-header"
      >
        <Typography>Risk Factors</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <RiskFactorsContent data={data} />
      </AccordionDetails>
    </Accordion>
  );
};
