import {
  BooleanField,
  BooleanInput,
  Datagrid,
  EditButton,
  List,
  ListProps,
  Loading,
  TextField,
  TextInput,
  useListContext,
} from "react-admin";
import { Pagination } from "../../components/Pagination";

export const IneligibleRecordsList = (props: ListProps) => {
  const { isLoading } = useListContext();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={100}
        empty={false}
        pagination={<Pagination />}
        filters={ineligibleRecordFilters}
      >
        <Datagrid sx={{ whiteSpace: "nowrap" }}>
          <TextField source="user.email" />
          <TextField source="user.firstName" />
          <TextField source="user.lastName" />
          <BooleanField source="void" />
          <EditButton />
        </Datagrid>
      </List>
    </>
  );
};

const ineligibleRecordFilters = [
  <TextInput alwaysOn source="user.email" />,
  <TextInput alwaysOn source="user.firstName" />,
  <TextInput alwaysOn source="user.lastName" />,
  <BooleanInput source="void" />,
];
