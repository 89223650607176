import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import { AxiosRequestHeaders } from "axios";
import { useEffect, useState } from "react";
import { useCreatePath, useGetRecordId } from "react-admin";
import { Link } from "react-router-dom";
import { CognitoAdhdAssessmentFormDocument } from "./cognitoAdhdAssessmentForm/CognitoAdhdAssessmentFormDocument";
import { CognitoAdhdAssessmentForm } from "./cognitoAdhdAssessmentForm/types";
import { MedicationDocument } from "./medicationForm/MedicationDocument";
import { MedicationPage } from "./medicationForm/MedicationPage";
import { CADDRAMedicationForm } from "./medicationForm/types";
import { SelfReportDocument } from "./selfReport/SelfReportDocument";
import { SelfReportPage } from "./selfReport/SelfReportPage";
import { SelfReportForm } from "./selfReport/types";
import { SheehanDocument } from "./sheehan/ADHDSheehanDocument";
import { SheehanPage } from "./sheehan/SheehanPage";
import { SheehanDisabilityScaleForm } from "./sheehan/types";
import { SnapDocument } from "./snap/SnapDocument";
import { SnapPage } from "./snap/SnapPage";
import { SnapForm } from "./snap/types";
import { SummaryPage } from "./Summary";
import { IAdhdInfo, UpdateAdhdStatus } from "./UpdateAdhdStatus";
import { DiagnosedBy } from "./UpdateAdhdStatusStep";
import { createPasteableText, onNewAdhdFlow } from "./utils";
import { WeissSymptomRecordIIForm } from "./weissSymptomRecordII/types";
import { WeissSymptomRecordIIDocument } from "./weissSymptomRecordII/WeissSymptomRecordIIDocument";
import { WeissSymptomRecordIIPage } from "./weissSymptomRecordII/WeissSymptomRecordIIPage";
import { WfirsForm } from "./wfirs/types";
import { WfirsDocument } from "./wfirs/WfirsDocument";
import { WfirsPage } from "./wfirs/WfirsPage";
import { WenderUtahRatingScaleForm } from "./wurs/types";
import { WursDocument } from "./wurs/WursDocument";
import { WursPage } from "./wurs/WursPage";
import { REACT_APP_AUTH0_AUDIENCE, useHttpClient } from "../../utils";
import { requestHelper } from "../../utils/requestHelper";
import { IPrescreening } from "../Prescreenings/Prescreening";
import { formatPhoneNumber } from "../Prescreenings/utils";

export const AdhdInfo = () => {
  const recordId = useGetRecordId();
  const { getAccessTokenSilently } = useAuth0();
  const createPath = useCreatePath();
  const { httpClient } = useHttpClient();

  const [selfReportData, setSelfReportData] = useState<
    SelfReportForm | undefined
  >(undefined);
  const [wursData, setWursData] = useState<
    WenderUtahRatingScaleForm | undefined
  >(undefined);
  const [snapData, setSnapData] = useState<SnapForm | undefined>(undefined);
  const [cognitoAdhdAssessmentFormData, setCognitoAdhdAssessmentFormData] =
    useState<CognitoAdhdAssessmentForm | undefined>(undefined);
  const [sheehanData, setSheehanData] = useState<
    SheehanDisabilityScaleForm | undefined
  >(undefined);
  const [weissFunctionalImpairmentData, setWeissFunctionalImpairmentData] =
    useState<WfirsForm | undefined>(undefined);
  const [medicationData, setMedicationData] = useState<
    CADDRAMedicationForm | undefined
  >(undefined);
  const [weissSymptomRecordIIData, setWeissSymptomRecordIIData] = useState<
    WeissSymptomRecordIIForm | undefined
  >(undefined);
  const [clientName, setClientName] = useState<string | undefined>(undefined);
  const [clientCreatedAt, setClientCreatedAt] = useState<Date | undefined>(
    undefined
  );
  const [adhdInfo, setAdhdInfo] = useState<IAdhdInfo | null>(null);
  const [showScales, setShowScales] = useState(false);
  const [doneRendering, setDoneRendering] = useState(false);
  const [prescreeningData, setPrescreeningData] = useState<
    IPrescreening | undefined
  >(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      // Add content-type and Authorization
      const headers: AxiosRequestHeaders = {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const endpoints = [
        "adhd_self_reports",
        "wender_utah_rating_scales",
        "snap",
        "weiss_functional_impairment_scales",
        "sheehan_disability_scales",
        "caddra_medication_forms",
        "weiss_symptom_record_ii",
        "cognito_adhd_assessment_forms",
        "health_info",
      ];
      const [
        selfReportResponse,
        wursResponse,
        snapResponse,
        wfirsResponse,
        sheehanResponse,
        medicationResponse,
        weissSymptomRecordIIResponse,
        cognitoAdhdAssessmentResponse,
        healthInfoData,
      ] = await Promise.all(
        endpoints.map(endpoint =>
          requestHelper<any>(endpoint, recordId, headers)
        )
      );

      const userData = await requestHelper<{
        firstName: string;
        lastName: string;
        createdAt: string;
      }>("users", recordId, headers);

      const adhdInfo = await requestHelper<any>(
        "admin/adhd_info/user",
        recordId,
        headers
      );

      setAdhdInfo(adhdInfo);
      setSelfReportData(selfReportResponse ?? {});
      setWursData(wursResponse ?? {});
      setSnapData(snapResponse.snapForm ?? {});
      setWeissFunctionalImpairmentData(wfirsResponse ?? {});
      setSheehanData(
        sheehanResponse.results.find(form => form.adhdContext) ?? {}
      );
      setMedicationData(medicationResponse ?? {});
      setWeissSymptomRecordIIData(weissSymptomRecordIIResponse ?? {});
      setCognitoAdhdAssessmentFormData(cognitoAdhdAssessmentResponse ?? {});
      setClientName(`${userData.firstName} ${userData.lastName}`);
      setClientCreatedAt(new Date(userData.createdAt));
      setPrescreeningData(healthInfoData.prescreeningInfo ?? {});
    };

    if (!recordId || clientName) {
      return;
    }
    fetchData();
  }, []);

  const updateAdhdDiagnosis = async (value: IAdhdInfo) => {
    const result = await httpClient(
      `${REACT_APP_AUTH0_AUDIENCE}/admin/adhd_info/user/${recordId}`,
      {
        method: "POST",
        body: JSON.stringify(value),
      }
    );
    setAdhdInfo(result.json);
  };

  const getMedicationAgreementLanguage = () => {
    const { agreesWithStimulantGuidelines } = adhdInfo ?? {};
    if (
      agreesWithStimulantGuidelines === null ||
      agreesWithStimulantGuidelines === undefined
    ) {
      return "Client has not signed medication agreement";
    }
    return agreesWithStimulantGuidelines
      ? "Client agrees with medication agreement"
      : "Client does not agree with medication agreement";
  };

  const getDiagnosedByLanguage = () => {
    const { toBeDiagnosedBy } = adhdInfo ?? {};
    if (!toBeDiagnosedBy) {
      return "Not yet determined who client should be assessed by";
    }
    const toBeDiagnosedByLanguage = {
      [DiagnosedBy.ANYONE]:
        "Client will be assessed by either an NP or a psychiatrist",
      [DiagnosedBy.PSYCHIATRIST]: "Client will be assessed by a psychiatrist",
    };
    return toBeDiagnosedByLanguage[toBeDiagnosedBy];
  };

  if (!clientName) {
    return null;
  }

  const handleCopySummaryClick = () => {
    createPasteableText({
      data: {
        ...selfReportData,
        ...snapData,
        ...sheehanData,
        ...weissSymptomRecordIIData,
        ...medicationData,
        ...weissFunctionalImpairmentData,
        ...cognitoAdhdAssessmentFormData,
      } as any,
      wursData: wursData!,
      clientName: clientName,
      clientCreatedAt: clientCreatedAt!,
      prescreeningData: prescreeningData!,
    });
  };

  return (
    <Box>
      <Stack spacing={1}>
        <Box display={"flex"}>
          <Box display="flex" flexDirection={"column"}>
            <Box>
              <Link
                to={createPath({
                  resource: "users",
                  type: "show",
                  id: recordId,
                })}
              >
                {`Back to ${clientName}`}
              </Link>
            </Box>
            <Typography variant="h5">ADHD Info</Typography>
          </Box>
        </Box>
        <UpdateAdhdStatus
          adhdInfo={adhdInfo!}
          updateStatus={updateAdhdDiagnosis}
        />
        {adhdInfo?.bloodWorkCompletedBy && (
          <Typography variant="h6">{`Blood work completed by: ${
            adhdInfo?.bloodWorkCompletedBy.name
          }, ${adhdInfo?.bloodWorkCompletedBy.address}, ${formatPhoneNumber(
            adhdInfo?.bloodWorkCompletedBy.phoneNumber
          )}`}</Typography>
        )}
        <Typography variant="h6">{getDiagnosedByLanguage()}</Typography>
        <Typography variant="h6">{getMedicationAgreementLanguage()}</Typography>
        <FormControlLabel
          label="Received external diagnosis documentation from 3rd party"
          control={
            <Checkbox
              sx={{ paddingLeft: 0 }}
              checked={!!adhdInfo?.receivedExternalAdhdDocuments}
              onChange={(e, checked) =>
                updateAdhdDiagnosis({
                  receivedExternalAdhdDocuments: checked,
                })
              }
            />
          }
        />
        <Button
          sx={{
            margin: "1rem 0",
            backgroundColor: "lightblue",
            color: "black",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "lightblue",
              color: "black",
              opacity: 0.8,
            },
          }}
          onClick={handleCopySummaryClick}
        >
          Copy ADHD Summary
        </Button>

        <Button
          sx={{
            margin: "1rem 0",
            backgroundColor: "lightblue",
            color: "black",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "lightblue",
              color: "black",
              opacity: 0.8,
            },
          }}
          onClick={() => setShowScales(!showScales)}
        >
          {showScales ? "Hide ADHD scales" : "Show ADHD scales"}
        </Button>
      </Stack>
      {showScales && (
        <Box>
          <SelfReportDocument data={selfReportData!} clientName={clientName} />
          <MedicationDocument data={medicationData!} clientName={clientName} />
          <SheehanDocument data={sheehanData!} clientName={clientName} />
          <WfirsDocument
            data={weissFunctionalImpairmentData!}
            clientName={clientName}
          />
          <WeissSymptomRecordIIDocument
            data={weissSymptomRecordIIData!}
            clientName={clientName}
            showExtendedAnswers={onNewAdhdFlow(clientCreatedAt)}
          />
          <WursDocument data={wursData!} clientName={clientName} />
          <SnapDocument data={snapData!} clientName={clientName} />
          {onNewAdhdFlow(clientCreatedAt) && (
            <CognitoAdhdAssessmentFormDocument
              clientCreatedAt={clientCreatedAt!}
              clientName={clientName}
              data={cognitoAdhdAssessmentFormData!}
            />
          )}

          <Box sx={{ display: doneRendering ? "inherit" : "none" }}>
            <PDFDownloadLink
              document={
                <Document onRender={() => setDoneRendering(true)}>
                  <SummaryPage
                    data={
                      {
                        ...selfReportData,
                        ...snapData,
                        ...sheehanData,
                        ...weissSymptomRecordIIData,
                        ...medicationData,
                        ...weissFunctionalImpairmentData,
                        ...cognitoAdhdAssessmentFormData,
                      } as any
                    }
                    wursData={wursData!}
                    clientName={clientName}
                    clientCreatedAt={clientCreatedAt!}
                    prescreeningData={prescreeningData!}
                  />
                  <SelfReportPage
                    data={selfReportData!}
                    clientName={clientName}
                  />
                  <MedicationPage
                    data={medicationData!}
                    clientName={clientName}
                  />
                  <SheehanPage data={sheehanData!} clientName={clientName} />
                  <WfirsPage
                    data={weissFunctionalImpairmentData!}
                    clientName={clientName}
                  />
                  <WeissSymptomRecordIIPage
                    data={weissSymptomRecordIIData!}
                    clientName={clientName}
                    showExtendedAnswers={onNewAdhdFlow(clientCreatedAt)}
                  />
                  <WursPage data={wursData!} clientName={clientName} />
                  <SnapPage data={snapData!} clientName={clientName} />
                </Document>
              }
              fileName={`${clientName} - ADHD Forms.pdf`}
            >
              {({ loading }) =>
                loading ? "Loading document..." : "Download ADHD summary"
              }
            </PDFDownloadLink>
          </Box>
        </Box>
      )}
    </Box>
  );
};
