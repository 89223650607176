import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Button, Snackbar } from "@mui/material";
import axios, { AxiosRequestHeaders } from "axios";
import { useEffect, useState } from "react";
import {
  Confirm,
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  WithRecord,
  useRefresh,
} from "react-admin";
import { StatusField } from "./CalendlyList";
import { REACT_APP_AUTH0_AUDIENCE } from "../../utils";
import { eventDateOptions } from "../../utils/dateTime";
import { logger } from "../../utils/logging";

export const CalendlyShow = props => {
  const { getAccessTokenSilently } = useAuth0();
  const refresh = useRefresh();
  const [displayNoShowConfirmation, setDisplayNoShowConfirmation] =
    useState(false);
  const [recordToUpdate, setRecordToUpdate] = useState<any>(null);

  const [updateFailed, setUpdateFailed] = useState(false);
  const [showSentEmailDescription, setShowSentEmailDescription] =
    useState(false);

  const handleButtonClick = record => {
    setRecordToUpdate(record);
    setDisplayNoShowConfirmation(true);
  };

  const handleConfirm = () => {
    if (recordToUpdate) {
      updateNoShowStatus(
        recordToUpdate.id as string,
        recordToUpdate.uri,
        recordToUpdate.no_show
      );
    }
    setDisplayNoShowConfirmation(false);
  };

  const handleCancel = () => {
    setDisplayNoShowConfirmation(false);
    setRecordToUpdate(null);
  };

  const updateNoShowStatus = async (
    id: string,
    calendlyUri: string,
    currentValue: string
  ) => {
    const accessToken = await getAccessTokenSilently();
    const headers: AxiosRequestHeaders = {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const url = `${REACT_APP_AUTH0_AUDIENCE}/calendly/no_show/${id}`;
    try {
      const response = await axios.request({
        method: "PATCH",
        url,
        headers,
        data: { currentValue, calendlyUri },
      });
      if (response.data.sentNoShowEmail) {
        setShowSentEmailDescription(true);
      }
    } catch (error) {
      logger.info("error updating no show status", { error });
      setUpdateFailed(true);
    }
    refresh();
  };

  const isNoShow = (noShow: string) => {
    return noShow !== "null";
  };

  const PlanField = ({ record }) => {
    const { getAccessTokenSilently } = useAuth0();
    const userId = record.user.id;
    const [plan, setPlan] = useState(null);

    useEffect(() => {
      const fetchPlan = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          const response = await axios.get(
            `${REACT_APP_AUTH0_AUDIENCE}/users/${userId}`,
            {
              headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          setPlan(response.data.metrics.plan);
        } catch (error) {
          logger.info("Error getting plan", { error });
          setPlan(null);
        }
      };

      fetchPlan();
    });

    return <TextField source="plan" record={{ plan }} />;
  };

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <WithRecord
          render={record => {
            const startTime = new Date(record.eventStartTime).getTime();
            if (startTime > Date.now()) {
              return <div></div>;
            }
            return (
              <>
                <Button
                  onClick={() => handleButtonClick(record)}
                  variant="outlined"
                >
                  {isNoShow(record.no_show)
                    ? "Mark as Attended"
                    : "Mark as No Show"}
                </Button>
                <Confirm
                  isOpen={displayNoShowConfirmation}
                  title={
                    isNoShow(record.no_show)
                      ? "Mark as Attended"
                      : "Mark as No Show"
                  }
                  content={
                    isNoShow(record.no_show)
                      ? "Are you sure you want to mark this appointment as attended?"
                      : "Are you sure you want to mark this appointment as a no show? This will send an email to the client prompting them to reschedule."
                  }
                  onConfirm={handleConfirm}
                  onClose={handleCancel}
                />
              </>
            );
          }}
        />
        <TextField source="email" />
        <TextField source="name" />
        <WithRecord
          label="Plan"
          render={record => <PlanField record={record} />}
        />
        <TextField label="Event" source="eventName" />
        <WithRecord
          label="Status"
          render={record => <StatusField record={record} />}
        />
        <WithRecord
          label="Host"
          render={record => {
            const host = record.host_metadata?.user
              ? record.host_metadata.user.name
              : record.hostName;
            return host;
          }}
        />
        <DateField
          label="Start time"
          source="eventStartTime"
          options={eventDateOptions}
          showTime
        />
        <WithRecord
          label="Duration (mins)"
          render={record => {
            const start = new Date(record.eventStartTime);
            const end = new Date(record.eventEndTime);
            const duration = (end.getTime() - start.getTime()) / 60000;
            return duration;
          }}
        />
        <TextField source="canceledBy" emptyText="N/A" />
        <TextField source="reason" label="Cancel reason" emptyText="N/A" />
      </SimpleShowLayout>
      <Snackbar
        open={updateFailed || showSentEmailDescription}
        autoHideDuration={30000}
        onClose={() => {
          setUpdateFailed(false);
          setShowSentEmailDescription(false);
        }}
      >
        <Alert
          onClose={() => {
            setUpdateFailed(false);
            setShowSentEmailDescription(false);
          }}
          severity={updateFailed ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {updateFailed
            ? "Updating the `No Show` state failed. Please contact your administrator for details and next steps."
            : "An automated email has been sent to the client notifying them that they missed their session and how to book a new session."}
        </Alert>
      </Snackbar>
    </Show>
  );
};
