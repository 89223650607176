import { useAuth0 } from "@auth0/auth0-react";
import UploadIcon from "@mui/icons-material/Upload";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import axios, { AxiosRequestHeaders } from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button } from "react-admin";
import { UserDocumentDeleteDialogue } from "./UserDocumentDeleteDialogue";
import { UserDocumentUpload } from "./UserDocumentUpload";
import { REACT_APP_AUTH0_AUDIENCE, REACT_APP_BASE_URL } from "../../utils";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../Prescreenings/Accordions";

function getFileUrl(blobNameInAzure) {
  return `${REACT_APP_BASE_URL}/api/admin/storage/${blobNameInAzure}`;
}

interface DocumentViewerProps {
  blobNameInAzure?: string;
  fileLabel: string;
  clientName: string;
  documentType: string;
  visibleToClient: boolean;
  userId: string;
  refetchData: () => Promise<void>;
  handleError: (message: string) => void;
  handleSuccess: (message: string) => void;
}

const DocumentViewer = (props: DocumentViewerProps) => {
  const {
    blobNameInAzure,
    fileLabel,
    clientName,
    documentType,
    visibleToClient,
  } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [deleteDialogueOpen, setDeleteDialogueOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [canSeeDocument, setCanSeeDocument] =
    useState<boolean>(visibleToClient);

  const url = getFileUrl(blobNameInAzure);

  // Fetches the document from Azure and downloads it
  async function fetchAndDownload() {
    setIsDownloading(true);
    const accessToken = await getAccessTokenSilently();
    const headers = {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const results = await fetch(url, { headers });
    const blob = await results.blob();
    const src = URL.createObjectURL(blob);

    // Creates a link element and clicks it to download the file
    const link = document.createElement("a");
    link.href = src;
    link.download = `${clientName}-${documentType}-${fileLabel}`;
    link.click();

    // Frees up memory by revoking the object URL
    URL.revokeObjectURL(src);
    setIsDownloading(false);
  }

  const handleDelete = async () => {
    const accessToken = await getAccessTokenSilently();
    const headers: AxiosRequestHeaders = {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const url = `${REACT_APP_AUTH0_AUDIENCE}/admin/storage/${blobNameInAzure}`;
    try {
      await axios.request({
        method: "DELETE",
        url,
        headers,
        data: {
          userId: props.userId,
        },
      });
      props.handleSuccess(`Document deleted successfully: ${fileLabel}`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        props.handleError(`Failed to delete document: ${fileLabel}`);
      }
    }
    await props.refetchData();
    setDeleteDialogueOpen(false);
  };

  const updateClientCanSeeDocument = async (e, checked: boolean) => {
    const accessToken = await getAccessTokenSilently();
    const headers: AxiosRequestHeaders = {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const url = `${REACT_APP_AUTH0_AUDIENCE}/admin/storage/visible-to-client/${blobNameInAzure}`;
    try {
      await axios.request({
        method: "POST",
        url,
        headers,
        data: {
          visibleToClient: checked,
        },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        props.handleError(`Document visibility change failed`);
      }
    }
    setCanSeeDocument(checked);
  };

  return (
    <Stack
      display="flex"
      alignItems={"center"}
      direction={"row"}
      spacing={2}
      useFlexGap
    >
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <button
          disabled={isDownloading}
          style={isDownloading ? {} : { cursor: "pointer", color: "blue" }}
          onClick={fetchAndDownload}
        >
          {isDownloading ? "Downloading..." : "Download"}
        </button>
        <button
          onClick={() => {
            setDeleteDialogueOpen(true);
          }}
          style={{ color: "red", cursor: "pointer" }}
        >
          Delete
        </button>
        <FormControlLabel
          sx={{ marginLeft: "auto" }}
          label="Visible to Client"
          control={
            <Checkbox
              checked={canSeeDocument}
              onChange={updateClientCanSeeDocument}
            />
          }
        />
      </Stack>
      <Divider orientation="vertical" flexItem />
      <Box>
        <span>{fileLabel}</span>
      </Box>
      <UserDocumentDeleteDialogue
        handleDelete={handleDelete}
        handleClose={() => {
          setDeleteDialogueOpen(false);
        }}
        fileName={fileLabel}
        open={deleteDialogueOpen}
      />
    </Stack>
  );
};

interface UserDocumentsProps {
  userId: string;
  name: string;
  handleError: (message: string) => void;
  handleSuccess: (message: string) => void;
}

const UserDocuments = ({
  userId,
  name,
  handleError,
  handleSuccess,
}: UserDocumentsProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [documents, setDocuments] = useState<
    | Record<
        string,
        Record<
          string,
          {
            originalFileName: string;
            visibleToClient: boolean;
            createdAt: Date;
          }
        >
      >
    | undefined
  >(undefined);

  useEffect(() => {
    if (!userId || documents) {
      return;
    }
    fetchData();
  });

  const fetchData = async () => {
    const accessToken = await getAccessTokenSilently();
    const headers: AxiosRequestHeaders = {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const url = `${REACT_APP_AUTH0_AUDIENCE}/admin/storage/blob-info/${userId}`;
    const results = await axios.request({
      method: "GET",
      url,
      headers,
    });
    setDocuments(results.data ?? {});
  };

  if (!documents) {
    return null;
  }

  return (
    <>
      <Accordion>
        <AccordionSummary
          aria-controls="UserDocuments-content"
          id="UserDocuments-header"
        >
          <Typography>User Documents</Typography>
          <Button
            label="Upload"
            variant="outlined"
            startIcon={<UploadIcon />}
            style={{ marginLeft: "auto" }}
            onClick={event => {
              setUploadModalOpen(true);
              event.stopPropagation();
            }}
            aria-label="upload document on behalf of user"
          ></Button>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              {Object.keys(documents).map(grouping => (
                <Box key={grouping}>
                  <h4
                    style={{
                      marginBlockStart: "1em",
                      marginBlockEnd: "0.25em",
                      textTransform: "capitalize",
                    }}
                  >
                    {grouping}
                  </h4>
                  {Object.keys(documents[grouping]).map(key => {
                    const document = documents[grouping][key];
                    const formattedDate = moment(document.createdAt).format(
                      "YYYY-MM-DD"
                    );
                    return (
                      <DocumentViewer
                        userId={userId}
                        clientName={name}
                        documentType={grouping}
                        key={`${grouping}-${key}`}
                        fileLabel={`(${formattedDate}) ${document.originalFileName}`}
                        blobNameInAzure={key}
                        visibleToClient={document.visibleToClient}
                        refetchData={fetchData}
                        handleError={handleError}
                        handleSuccess={handleSuccess}
                      />
                    );
                  })}
                </Box>
              ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <UserDocumentUpload
        open={uploadModalOpen}
        handleClose={() => {
          setUploadModalOpen(false);
        }}
        refetchData={fetchData}
        userId={userId}
        handleError={handleError}
        handleSuccess={handleSuccess}
      />
    </>
  );
};

export default UserDocuments;
