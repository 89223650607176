import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Action,
  DiagnosedBy,
  IActionItem,
  steps,
} from "./UpdateAdhdStatusStep";
import { YesNoButton } from "./YesNoButton";
import { ConfirmationDialog } from "../../app/ConfirmationDialog";

interface UpdateAdhdStatusProps {
  adhdInfo: IAdhdInfo;
  updateStatus: (value: IAdhdInfo) => Promise<void>;
}

/** Values can be undefined or null because no row may exist in the DB. */
export interface IAdhdInfo {
  internallyDiagnosedWithAdhd?: boolean | null;
  agreesWithStimulantGuidelines?: boolean | null;
  externallyDiagnosedWithAdhd?: boolean | null;
  externalAdhdDiagnosisValid?: boolean | null;
  receivedExternalAdhdDocuments?: boolean | null;
  eligibleForAdhdProgram?: boolean | null;
  toBeDiagnosedBy?: DiagnosedBy | null;
  bloodWorkCompletedBy?: {
    name: string;
    address: string;
    phoneNumber: string;
  } | null;
}

export const UpdateAdhdStatus: React.FC<UpdateAdhdStatusProps> = ({
  adhdInfo,
  updateStatus,
}) => {
  const [actionItem, setActionItem] = useState<IActionItem | undefined>(
    undefined
  );
  const [toastMessage, setToastMessage] = useState("");
  const [dialogInfo, setDialogInfo] = useState<
    { dialogMessage: string; actionInfo: Action } | undefined
  >(undefined);
  const {
    eligibleForAdhdProgram,
    externallyDiagnosedWithAdhd,
    internallyDiagnosedWithAdhd,
    externalAdhdDiagnosisValid,
    toBeDiagnosedBy,
  } = adhdInfo || {};

  const handleActionClick = async (actionInfo: Action) => {
    // We'll pass off the responsibility of handling the action to the dialog if
    // it needs to be prefaced with a warning message.
    if (actionInfo.warningMessage) {
      setDialogInfo({
        dialogMessage: actionInfo.warningMessage,
        actionInfo,
      });
      return;
    }
    updateStatusAndSetNextAction(actionInfo);
  };

  const updateStatusAndSetNextAction = (actionInfo: Action) => {
    const { data, toastMessage, nextStep } = actionInfo;
    const actionData = typeof data === "function" ? data(adhdInfo) : data;
    updateStatus(actionData);
    if (toastMessage) {
      setToastMessage(toastMessage);
    }
    setActionItem(steps[nextStep(adhdInfo)]);
  };

  const handleCloseDialog = confirmed => {
    if (confirmed) {
      updateStatusAndSetNextAction(dialogInfo!.actionInfo);
    }
    setDialogInfo(undefined);
  };

  useEffect(() => {
    if (
      eligibleForAdhdProgram === null ||
      eligibleForAdhdProgram === undefined
    ) {
      setActionItem(steps.eligibleForProgramCheck);
      return;
    }
    if (eligibleForAdhdProgram === false) {
      setActionItem(steps.ineligibleForProgram);
      return;
    }
    if (externalAdhdDiagnosisValid) {
      setActionItem(steps.externalDiagnosisValid);
      return;
    }
    if (
      externallyDiagnosedWithAdhd &&
      externalAdhdDiagnosisValid !== false &&
      (internallyDiagnosedWithAdhd === null ||
        internallyDiagnosedWithAdhd === undefined)
    ) {
      setActionItem(steps.externalDiagnosisValidityCheck);
      return;
    }
    if (
      (!toBeDiagnosedBy && !externallyDiagnosedWithAdhd) ||
      (!toBeDiagnosedBy &&
        externallyDiagnosedWithAdhd &&
        externalAdhdDiagnosisValid === false)
    ) {
      setActionItem(steps.whoShouldDiagnoseClient);
      return;
    }
    if (
      internallyDiagnosedWithAdhd === null ||
      internallyDiagnosedWithAdhd === undefined
    ) {
      setActionItem(steps.internalDiagnosisStep);
      return;
    }
    if (internallyDiagnosedWithAdhd === true) {
      setActionItem(steps.internalDiagnosisValid);
      return;
    }
    if (internallyDiagnosedWithAdhd === false) {
      setActionItem(steps.internalDiagnosisInvalid);
      return;
    }
  }, []);

  if (!actionItem) {
    return null;
  }

  return (
    <Stack alignItems={"center"} direction={"row"} spacing={1} margin="1rem 0">
      <Typography fontWeight={"bold"} variant="h6">
        <Box
          sx={{
            color:
              actionItem.type === "discharge"
                ? "red"
                : actionItem.type === "confirmed"
                ? "green"
                : "black",
          }}
        >
          {actionItem.title}
        </Box>
      </Typography>
      <Box>
        {actionItem.otherButtons && (
          <Stack direction="row" spacing={1}>
            {actionItem.otherButtons.map(button => (
              <Button
                sx={{
                  marginRight: "1rem",
                  backgroundColor: button.disabled
                    ? "lightgray"
                    : "mediumseagreen",
                  color: "white",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "mediumseagreen",
                    color: "white",
                    opacity: 0.8,
                  },
                }}
                disabled={button.disabled}
                key={button.label}
                onClick={() => handleActionClick(button)}
              >
                {button.label}
              </Button>
            ))}
          </Stack>
        )}
        <Stack direction="row">
          {actionItem.yesAction && (
            <YesNoButton
              buttonType="yes"
              onClick={() => handleActionClick(actionItem.yesAction!)}
            />
          )}
          {actionItem.noAction && (
            <YesNoButton
              buttonType="no"
              onClick={() => handleActionClick(actionItem.noAction!)}
            />
          )}
        </Stack>
      </Box>
      {actionItem.editAction && (
        <Button onClick={() => handleActionClick(actionItem.editAction!)}>
          {typeof actionItem.editAction.label === "function"
            ? actionItem.editAction.label(adhdInfo)
            : actionItem.editAction.label}
        </Button>
      )}
      {dialogInfo && (
        <ConfirmationDialog
          content={dialogInfo.dialogMessage}
          open
          onCloseDialog={handleCloseDialog}
        />
      )}
      {toastMessage && (
        <Snackbar
          autoHideDuration={30000}
          open
          onClose={(event, reason) => {
            if (reason !== "clickaway") {
              setToastMessage("");
            }
          }}
        >
          <Alert severity={"success"} sx={{ width: "100%" }}>
            <>
              {toastMessage}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setToastMessage("")}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          </Alert>
        </Snackbar>
      )}
    </Stack>
  );
};
