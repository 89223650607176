/** Takes a date string with format "YYYY-MM-DD" and returns a string
 * representing the day after with format "YYYY-MM-DDT00:00:00.000Z"
 */
export const dateParser = value => {
  if (value === null) {
    return;
  }
  const date = new Date(value);
  // sending the day after to db as when fetched will return day before due to time zone
  const nextDay = new Date(date);
  nextDay.setDate(date.getDate() + 1);
  return JSON.parse(JSON.stringify(nextDay));
};

/** Takes a date string with format "YYYY-MM-DDT00:00:00.000Z" and returns
 * the a string representing the day before with format "YYYY-MM-DD"
 */
export const dateFormatter = value => {
  // null, undefined and empty string values should not go through dateFormatter
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value == null || value === "") {
    return "";
  }

  // don't want to format an incoming date that is already formatted
  const dateInputRegex = new RegExp(/^\d{4}-\d{2}-\d{2}$/);
  if (dateInputRegex.test(value)) {
    return value;
  }

  const date = new Date(value);
  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}/;
  // getting day before as temporary record holds parsed value representing the day ahead
  const dayBefore = new Date(date);
  dayBefore.setDate(date.getDate() - 1);
  const dateString = JSON.parse(JSON.stringify(dayBefore));
  const inputDate = dateString.match(dateFormatRegex)[0];

  return inputDate;
};

export const getDefaultDate = () => {
  const today = new Date();
  // default value does not go through parser so need to set to tomorrow
  const tomorrow = new Date(today);
  tomorrow.setHours(0, 0, 0, 0);
  tomorrow.setDate(today.getDate() + 1);
  return JSON.parse(JSON.stringify(tomorrow));
};
