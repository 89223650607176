import { Typography } from "@mui/material";
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "./Accordions";
import { IPrescreening } from "./Prescreening";
import { SortFields } from "./SortFields";

interface LetterOfAccommodationProps {
  data: IPrescreening;
}

export const LetterOfAccommodation: React.FC<LetterOfAccommodationProps> = ({
  data,
}) => {
  const fields = [
    {
      name: "requiresLOAForSchool",
      label: "Requires Letter of Accommodation for School",
    },
    { name: "schoolNameLOA", label: "School Name" },
    {
      name: "schoolContactNameLOA",
      label: "School Contact",
    },
    {
      name: "schoolContactEmailLOA",
      label: "School Contact Email",
    },
    {
      name: "requiresLOAForWork",
      label: "Requires Letter of Accommodation for Work",
    },
    { name: "companyNameLOA", label: "Company Name" },
    { name: "workContactNameLOA", label: "Work Contact" },
    {
      name: "workContactEmailLOA",
      label: "Work Contact Email",
    },
  ];

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="LetterOfAccommodation-content"
        id="LetterOfAccommodation-header"
      >
        <Typography>Letter Of Accommodation</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SortFields data={data} fields={fields} />
      </AccordionDetails>
    </Accordion>
  );
};
