export const conditions = [
  { id: "schizophrenia", name: "Schizophrenia" },
  { id: "bipolarDisorder", name: "Bipolar Disorder" },
  { id: "severeAnxiety", name: "Severe Anxiety" },
  { id: "severeDepression", name: "Severe Depression" },
  { id: "severeInsomnia", name: "Severe Insomnia" },
  { id: "personalityDisorder", name: "Personality Disorder" },
  { id: "complexTrauma", name: "Complex Trauma" },
  {
    id: "suicidalIdeation",
    name: "Severe and/or active suicidal thoughts and/or behaviours",
  },
  {
    id: "substanceUse",
    name: "Active Substance use disorder, which is the primary issue requiring intervention",
  },
  { id: "eatingDisorder", name: "Active Eating Disorder" },
  { id: "none", name: "None of the above" },
];

export const noAngleBrackets = (value: string | undefined) => {
  if (value?.includes("<") || value?.includes(">")) {
    return "Cannot contain < or >";
  }
  return undefined;
};

export const translateErrorMessage = (
  formState: Record<string, string | string[]>
) => {
  const messages: string[] = [];
  let includeTraumaExplanation = false;
  const values = formState;
  if (
    (values.otherConditions as string[]).some(condition => condition !== "none")
  ) {
    messages.push(
      `The client is diagnosed with or is highly suspected to have ${(
        values.otherConditions as string[]
      )
        .filter(condition => condition !== "none")
        .map(
          condition =>
            conditions.find(conditions => conditions.id === condition)?.name
        )
        .join(", ")}`
    );
  }
  if (values.experiencingAppropriateIntensity === "no") {
    messages.push(
      "The client is currently experiencing anxiety, depression and/or insomnia not of mild-moderate intensity"
    );
  }
  if (values.willingToEngageInPsychotherapy === "no") {
    messages.push("The client is not willing to engage in psychotherapy");
  }

  if (values.seekingTraumaCounseling === "yes") {
    messages.push(
      "The client is seeking Trauma Counseling or a Clinical Counselor"
    );
  }
  if (values.suicidalIdeation === "yes") {
    messages.push(
      "The client is expressing active thoughts of self harm or suicide"
    );
  }
  if (values.limitedSupervision === "no") {
    messages.push(
      "The client cannot be supported with limited medical supervision and care coordination"
    );
  }
  if (values.understandsWillNotSeePsychiatrist === "no") {
    messages.push(
      "The client does not understand they will not be seen or referred to a psychiatrist within Cognito Health"
    );
  }
  if (values.doesNotReplacePrimaryCare === "no") {
    messages.push(
      "The client does not understand Cognito Health does not replace primary care"
    );
  }
  if (values.clientOwnership === "no") {
    messages.push(
      "You do not agree that you and MHSU are responsible for ongoing care for the client"
    );
  }
  if (values.trauma === "yes") {
    includeTraumaExplanation = true;
  }
  return { messages, includeTraumaExplanation };
};
