import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface YesNoButtonProps extends ButtonProps {
  buttonType: "yes" | "no";
}

export const YesNoButton: React.FC<YesNoButtonProps> = ({
  buttonType,
  ...other
}) => {
  const buttonLabel = buttonType === "yes" ? "Yes" : "No";
  const backgroundColor = buttonType === "yes" ? "lightgreen" : "lightcoral";
  return (
    <Button
      sx={{
        marginRight: "1rem",
        backgroundColor,
        color: "black",
        fontWeight: "bold",
        "&:hover": {
          backgroundColor,
          color: "black",
          opacity: 0.8,
        },
      }}
      {...other}
    >
      {buttonLabel}
    </Button>
  );
};
