import { useAuth0 } from "@auth0/auth0-react";
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { AxiosRequestHeaders } from "axios";
import { useEffect, useState } from "react";
import {
  Button,
  FunctionField,
  Labeled,
  NumberInput,
  RecordContextProvider,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  WithRecord,
  useCreatePath,
  useGetRecordId,
  usePermissions,
} from "react-admin";
import { Link, useLocation } from "react-router-dom";
import SubscriptionStatus from "./SubscriptionStatus";
import { CreatedByDetails } from "./types";
import UserDocuments from "./UserDocuments";
import { UserIneligibleRecords } from "./UserIneligibleRecords";
import { UserMessages } from "./UserMessages";
import { UserPrescriptions } from "./UserPrescriptions";
import { nonPharmacistFields, pharmacistFields } from "./UserShowFields";
import { Title } from "./UserShowHelperComponents";
import {
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_PRICE_ID,
  useHttpClient,
} from "../../utils";
import { checkForRole } from "../../utils/checkRoles";
import { requestHelper } from "../../utils/requestHelper";
import { IAdhdInfo } from "../AdhdInfo/UpdateAdhdStatus";
import { Prescreening } from "../Prescreenings/Prescreening";
import { formatPhoneNumber } from "../Prescreenings/utils";

export const UserShow = props => {
  const { permissions } = usePermissions();
  const recordId = useGetRecordId();
  const { httpClient } = useHttpClient();
  const createPath = useCreatePath();
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();

  const [adhdInfo, setAdhdInfo] = useState<IAdhdInfo | null>(null);
  const [remaining30MinCbtSessions, setRemaining30MinCbtSessions] = useState<
    number | undefined
  >(undefined);
  const [canSeeAdhdForms, setCanSeeAdhdForms] = useState<boolean>(false);
  const [createdByDetails, setCreatedByDetails] = useState<
    CreatedByDetails | undefined
  >(undefined);
  const [toast, setToast] = useState<{
    severity?: "error" | "success";
    message?: string;
  }>({});
  const [isSaving, setIsSaving] = useState(false);
  const [userData, setUserData] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      // Add content-type and Authorization
      const headers: AxiosRequestHeaders = {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      let searchParams = "";
      if (location.state?.relationship && location.state?.reason) {
        searchParams = `?relationship=${location.state.relationship}&reason=${location.state.reason}`;
      }
      const [
        subscriptionDetails,
        usersData,
        canSeeAdhdForms,
        createdByDetails,
        adhdInfo,
      ] = await Promise.all([
        requestHelper<{
          remainingThirtyMinuteCbtSessions?: number;
        }>("subscription_details/remaining_cbt_sessions", recordId, headers),
        httpClient(
          `${REACT_APP_AUTH0_AUDIENCE}/users/${recordId}${searchParams}`,
          { method: "GET" }
        ),
        requestHelper<boolean>(
          "subscription_details/can_see_adhd_forms",
          recordId,
          headers
        ),
        requestHelper<CreatedByDetails>(
          "created_by_details",
          recordId,
          headers
        ),
        requestHelper<any>("admin/adhd_info/user", recordId, headers),
      ]);
      if (subscriptionDetails.remainingThirtyMinuteCbtSessions !== undefined) {
        setRemaining30MinCbtSessions(
          subscriptionDetails.remainingThirtyMinuteCbtSessions
        );
      }
      setUserData(usersData.json);
      setCanSeeAdhdForms(canSeeAdhdForms);
      setCreatedByDetails(createdByDetails);
      setAdhdInfo(adhdInfo);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!permissions) {
    return null;
  }
  const isPharmacist = checkForRole("pharmacist", permissions);
  const isTeamLead = checkForRole("teamLead", permissions);

  const updateAbilityToSeeAdhdForms = async (e, checked: boolean) => {
    await httpClient(
      `${REACT_APP_AUTH0_AUDIENCE}/subscription_details/${recordId}`,
      {
        method: "POST",
        body: JSON.stringify({
          allowNonAdhdUserToSeeAdhdForms: checked,
        }),
      }
    );
    setCanSeeAdhdForms(checked);
  };

  const updateRemainingCbtSessions = async (values: any) => {
    setIsSaving(true);
    try {
      await httpClient(
        `${REACT_APP_AUTH0_AUDIENCE}/subscription_details/remaining_cbt_sessions`,
        {
          method: "PATCH",
          body: JSON.stringify({
            userId: recordId,
            stripeCustomerId: values.stripeCustomerId,
            priceId: REACT_APP_PRICE_ID,
            remainingThirtyMinuteCbtSessions:
              values.remainingThirtyMinuteCbtSessions,
          }),
        }
      );
    } catch (e: any) {
      handleError(e.message);
      setIsSaving(false);
      return;
    }
    handleSuccess(
      "Successfully updated the remaining CBT Sessions. If the subscription was going to cancel it has been reactivated. If there was no prior subscription a new subscription has been created."
    );
    setIsSaving(false);
  };

  const handleError = (message: string) => {
    setToast({ severity: "error", message });
  };

  const handleSuccess = (message: string) => {
    setToast({ severity: "success", message });
  };

  return (
    <>
      <RecordContextProvider value={userData}>
        <Title />
        <Stack direction={"row"} spacing={1}>
          <Box sx={{ fontSize: "1.5rem" }}>{"Subscription Status: "}</Box>
          <SubscriptionStatus
            styling={{ fontSize: "1.5rem", textTransform: "capitalize" }}
            id={recordId.toString()}
          />
        </Stack>
        <div
          style={{
            border: "1px solid black",
            borderBottom: "unset",
          }}
        >
          <WithRecord
            render={record => (
              <UserDocuments
                name={`${userData.firstName} ${userData.lastName}`}
                userId={record.id as string}
                handleError={handleError}
                handleSuccess={handleSuccess}
              />
            )}
          />
          <UserPrescriptions />
          <UserIneligibleRecords />
          <UserMessages userId={recordId} />
        </div>
        {!isPharmacist && (
          <>
            <div style={{ border: "1px solid" }}>
              <WithRecord
                render={() => (
                  <Prescreening
                    emergencyContact={userData!.emergencyContactName}
                    emergencyContactPhone={userData!.emergencyContactPhone}
                    emergencyContactRelationship={
                      userData!.emergencyContactRelationship
                    }
                    supportName={userData!.supportContactName}
                    supportRelationship={userData!.supportContactRelationship}
                    primaryCareProvider={userData!.primaryCareProvider}
                    primaryCareProviderName={userData!.primaryCareProviderName}
                    primaryCareProviderPhone={formatPhoneNumber(
                      userData!.primaryCareProviderPhone
                    )}
                    primaryCareProviderFax={formatPhoneNumber(
                      userData!.primaryCareProviderFax
                    )}
                  />
                )}
              />
            </div>
            <div
              style={{
                borderLeft: "1px solid",
                borderRight: "1px solid",
                display: "flex",
              }}
            >
              <Box sx={{ padding: "0.5rem 1rem" }}>
                <Typography variant="h5">Misc.</Typography>
              </Box>
            </div>
            <div
              style={{
                borderLeft: "1px solid",
                borderRight: "1px solid",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormControlLabel
                sx={{ marginLeft: "0.5rem" }}
                label="Can see ADHD Forms"
                control={
                  <Checkbox
                    disabled={
                      (userData?.metrics?.plan === "Cognito ADHD" ||
                        userData?.metrics?.plan === "Test ADHD Program") &&
                      new Date(userData?.createdAt) > new Date("2022-12-07")
                    }
                    checked={canSeeAdhdForms}
                    onChange={updateAbilityToSeeAdhdForms}
                  />
                }
              />
              <Box margin={"1rem"}>
                <Labeled label="Previously Diagnosed with ADHD">
                  <TextField
                    record={{
                      previouslyDiagnosed:
                        adhdInfo?.externallyDiagnosedWithAdhd,
                    }}
                    source="previouslyDiagnosed"
                    emptyText="Not answered"
                  />
                </Labeled>
              </Box>
              {canSeeAdhdForms && (
                <WithRecord
                  render={record => (
                    <Box margin={"1rem"}>
                      <Link
                        to={createPath({
                          resource: "adhdInfo",
                          type: "show",
                          id: record.id,
                        })}
                      >
                        ADHD Info
                      </Link>
                    </Box>
                  )}
                />
              )}
            </div>
            <div
              style={{
                borderLeft: "1px solid",
                borderRight: "1px solid",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <WithRecord
                render={record => (
                  <Box margin={"1rem"}>
                    <Link
                      to={createPath({
                        resource: "progress",
                        type: "show",
                        id: record.id,
                      })}
                    >
                      Progress
                    </Link>
                  </Box>
                )}
              />
              <WithRecord
                render={record => (
                  <Box margin={"1rem"}>
                    <Link
                      to={createPath({
                        resource: "survey_response",
                        type: "show",
                        id: record.id,
                      })}
                    >
                      Surveys
                    </Link>
                  </Box>
                )}
              />
            </div>
            <div
              style={{
                borderLeft: "1px solid",
                borderRight: "1px solid",
                display: "flex",
              }}
            >
              <SimpleForm
                toolbar={false}
                defaultValues={{
                  remainingThirtyMinuteCbtSessions: remaining30MinCbtSessions,
                }}
                onSubmit={updateRemainingCbtSessions}
              >
                <Box display="flex" alignItems={"center"}>
                  <Box>
                    <FormLabel component="legend">
                      Remaining Thirty Minute CBT Sessions
                    </FormLabel>
                    <NumberInput
                      disabled={remaining30MinCbtSessions === undefined}
                      min={1}
                      source="remainingThirtyMinuteCbtSessions"
                      label=""
                      helperText={
                        "For every hour long session please add two. For example one 1 hour session and three 30 minute sessions would be a total of 5."
                      }
                    />
                  </Box>
                  <Button
                    disabled={
                      remaining30MinCbtSessions === undefined || isSaving
                    }
                    label={isSaving ? "...Saving" : "Save"}
                    variant="contained"
                    type="submit"
                    sx={{ width: "6rem", height: "3rem", marginLeft: "0.5rem" }}
                  />
                </Box>
              </SimpleForm>
            </div>
          </>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: isPharmacist ? "flex-start" : "space-around",
            padding: "2rem 0 0 0",
            border: "1px solid black",
          }}
        >
          <div>
            <SimpleShowLayout>{pharmacistFields(isTeamLead)}</SimpleShowLayout>
          </div>
          {!isPharmacist && (
            <>
              {createdByDetails && (
                <Box maxWidth={"37rem"}>
                  <SimpleShowLayout>
                    <TextField
                      record={{ createdBy: createdByDetails.info.name }}
                      label="Created by"
                      source="createdBy"
                      emptyText="null"
                    />
                    <TextField
                      record={{ creatorEmail: createdByDetails.info.email }}
                      label="Creator Email"
                      source="creatorEmail"
                      emptyText="null"
                    />
                    <TextField
                      record={{ creatorRegion: createdByDetails.info.region }}
                      label="Creator Region"
                      source="creatorRegion"
                      emptyText="null"
                    />
                    <TextField
                      record={{
                        communicationDetails:
                          createdByDetails.communicationAboutProgram,
                      }}
                      label="Communication Details"
                      source="communicationDetails"
                      emptyText="No details provided from referrer"
                    />
                    <TextField
                      record={{
                        clientNotes: createdByDetails.clientNotes,
                      }}
                      label="Client Notes"
                      source="clientNotes"
                      emptyText="No notes provided from referrer"
                    />
                    <FunctionField
                      record={{
                        referralReasons: createdByDetails.referralReasons,
                      }}
                      label="Referral Reasons"
                      source="referralReasons"
                      render={record =>
                        record.referralReasons
                          ? record.referralReasons.join(", ")
                          : "Unknown"
                      }
                    />
                  </SimpleShowLayout>
                </Box>
              )}
              <div>
                <SimpleShowLayout>{nonPharmacistFields}</SimpleShowLayout>
              </div>
            </>
          )}
        </div>
      </RecordContextProvider>
      {!!(toast.message && toast.severity) && (
        <Snackbar
          open
          autoHideDuration={5000}
          onClose={() => {
            setToast({});
          }}
        >
          <Alert
            onClose={() => {
              setToast({});
            }}
            severity={toast.severity}
            sx={{ width: "100%" }}
          >
            {toast.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
