import { Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { ExtendedWeissSymptomRecordIIAnswers } from "./ExtendedWeissSymptomRecordIIAnswers";
import { weissSymptomRecordIIQuestions } from "./questions";
import { WeissSymptomRecordIIForm } from "./types";
import { flagSection } from "../flagWeissSection";
import { AnswerLabels, QuestionAndAnswer, genericStyles } from "../PdfBuilder";

export const WeissSymptomRecordIIPage: React.FC<{
  data: WeissSymptomRecordIIForm;
  clientName: string;
  showExtendedAnswers: boolean;
}> = ({ data, clientName, showExtendedAnswers }) => {
  return (
    <Page size="A4" style={genericStyles.page}>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>Weiss Symptom Record II</Text>
      </View>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>{clientName}</Text>
      </View>
      {weissSymptomRecordIIQuestions.map(questionSet => (
        <View key={questionSet.title}>
          <View style={{ paddingTop: "10pt" }}>
            <Text style={genericStyles.sectionTitle}>{questionSet.title}</Text>
          </View>
          <AnswerLabels
            questions={["None", "Mild", "Moderate", "Severe", "N/A"]}
          />
          {questionSet.questions.map(questionInfo => (
            <View key={questionInfo.field}>
              <QuestionAndAnswer
                key={questionInfo.field}
                question={questionInfo.question}
                answer={data[questionInfo.field] as number}
                potentialScores={[0, 1, 2, 3, -1]}
              />
            </View>
          ))}
        </View>
      ))}
      {data.otherDifficulties && (
        <View>
          <View style={{ paddingTop: "10pt" }}>
            <Text style={genericStyles.sectionTitle}>
              {"Other Difficulties"}
            </Text>
          </View>
          <AnswerLabels
            questions={["None", "Mild", "Moderate", "Severe", "N/A"]}
          />
          {data.otherDifficulties.map(difficulty => (
            <View key={difficulty.difficulty}>
              <QuestionAndAnswer
                key={difficulty.difficulty}
                question={difficulty.difficulty}
                answer={difficulty.score as number}
                potentialScores={[0, 1, 2, 3, -1]}
              />
            </View>
          ))}
        </View>
      )}
      {weissSymptomRecordIIQuestions.map(questionSet => {
        const section = flagSection(
          data,
          questionSet.questions,
          questionSet.title,
          false
        );
        if (!section) {
          return null;
        }
        return (
          <View
            style={{ paddingTop: "10pt" }}
            key={`${questionSet.title}-scoring`}
          >
            <Text style={genericStyles.sectionTitle}>{section}</Text>
          </View>
        );
      })}
      {showExtendedAnswers && (
        <ExtendedWeissSymptomRecordIIAnswers data={data} />
      )}
    </Page>
  );
};
