import { DateInput, TextInput } from "react-admin";
import { InclusiveEndDateInput } from "../../components/InclusiveEndDateInput";

export const failedReferralReasonsFilters = [
  <TextInput
    label="Admin Name"
    source="adminName"
    alwaysOn
    name="adminName"
    resettable
  />,
  <TextInput
    label="Client Name"
    source="clientName"
    alwaysOn
    name="clientName"
    resettable
  />,
  <TextInput
    label="Ineligibility Reason"
    source="ineligibilityReason"
    alwaysOn
    name="ineligibilityReason"
    resettable
  />,
  <DateInput label="Start Date (Inclusive)" source="createdAt_gte" alwaysOn />,
  <InclusiveEndDateInput source="createdAt_lte" alwaysOn />,
];
