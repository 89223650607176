import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { REACT_APP_AUTH0_AUDIENCE, useHttpClient } from "../../utils";
import { millisecondsInADay } from "../../utils/dateTime";

export const SatisfactionSurveyResults = () => {
  const { httpClient } = useHttpClient();
  const [activeUsers, setActiveUsers] = useState<
    { email: string; daysSinceLastCompleted: number }[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setInitiated(true);

      const result = await httpClient(
        `${REACT_APP_AUTH0_AUDIENCE}/satisfaction_surveys/all`,
        { method: "GET" }
      );

      const users = result.json
        .map(user => ({
          email: user.user_email,
          daysSinceLastCompleted:
            Math.round(
              (new Date().getTime() -
                new Date(user.info.mostRecentCompletedDate).getTime()) /
                millisecondsInADay
            ) - 80,
        }))
        .filter(user => user.daysSinceLastCompleted > 0);
      setActiveUsers(users);

      setIsLoading(false);
    };
    if (!initiated) {
      fetchData();
    }
  }, []);

  return (
    <Box>
      {isLoading ? (
        <Backdrop open>
          <Box display={"flex"} flexDirection="column" alignItems={"center"}>
            <CircularProgress color="inherit" />
            <Box marginTop={"1rem"}>Loading... This may take a minute.</Box>
          </Box>
        </Backdrop>
      ) : (
        <>
          <Box margin={"1rem 0 0 1rem"}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell align="left">
                      Days since prompt for satisfaction survey
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeUsers.map(user => (
                    <TableRow key={user.email}>
                      <TableCell align="left">{user.email}</TableCell>
                      <TableCell align="left">
                        {user.daysSinceLastCompleted}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </Box>
  );
};
