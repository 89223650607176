import { Typography } from "@mui/material";
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "./Accordions";
import { IPrescreening } from "./Prescreening";
import { SortField, SortFields } from "./SortFields";
import { removeField } from "./utils";

interface MedicalHistoryProps {
  data: IPrescreening;
}

export const MedicalHistory: React.FC<MedicalHistoryProps> = ({ data }) => {
  const fields: SortField[] = [
    { name: "highBloodPressure" },
    { name: "diabetesMellitus" },
    { name: "hypoOrHyperthyroidism" },
    { name: "headInjuryOrConcussion" },
    { name: "stroke" },
    { name: "heartAttack" },
    { name: "arrhythmias" },
    { name: "chronicPain" },
    { name: "epilepsy" },
    { name: "cognitiveImpairment" },
    { name: "generalizedAnxietyDisorder" },
    { name: "depressiveDisorder" },
    { name: "borderlinePersonalityDisorder" },
    { name: "bipolarDisorder" },
    { name: "schizophrenia" },
    { name: "adhd", label: "ADHD" },
    {
      name: "previousSurgicalProcedures",
      hideWhenTrue: true,
    },
  ];

  const details = [
    { name: "previousSurgicalProceduresDetails" },
    { name: "otherMedicalConditionsDetails" },
    { name: "otherPsychiatricConditionsDetails" },
  ];

  if (
    fields.every(field => data[field.name] === false) &&
    details.every(details => !data[details.name])
  ) {
    data["noKnownConditions"] = true;
    fields.push({ name: "noKnownConditions" });
  }

  if (data.previousSurgicalProcedures === false) {
    removeField(details, "previousSurgicalProceduresDetails");
  }
  if (data.otherMedicalConditions === false) {
    removeField(details, "otherMedicalConditionsDetails");
  }
  if (data.otherPsychiatricConditions === false) {
    removeField(details, "otherPsychiatricConditionsDetails");
  }

  // Mutate the data so that it displays as procedure, date
  if (Array.isArray(data.previousSurgicalProceduresDetails)) {
    data.previousSurgicalProceduresDetails =
      data.previousSurgicalProceduresDetails
        ?.filter(details => !!details)
        .map(details => ({
          procedure: details.procedure,
          date: details.date,
        }));
  }

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="MedicalHistory-content"
        id="MedicalHistory-header"
      >
        <Typography>Medical History</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SortFields data={data} fields={[...fields, ...details]} />
      </AccordionDetails>
    </Accordion>
  );
};
