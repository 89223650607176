import { defaultTheme } from "react-admin";

const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    RaListToolbar: {
      styleOverrides: {
        root: {
          justifyContent: "flex-start",
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          "& a": { whiteSpace: "break-spaces" },
        },
      },
    },
  },
};

export default theme;
