import { SelfReportFormFields } from "./types";

export interface SelfReportQuestion {
  question: string;
  field: SelfReportFormFields;
  minHighlight: number;
}

export const questionsPartA: SelfReportQuestion[] = [
  {
    question:
      "How often do you have trouble wrapping up the final details of a project, once the challenging parts have been done?",
    field: "troubleWrappingUpFinalDetails",
    minHighlight: 2,
  },
  {
    question:
      "How often do you have difficulty getting things in order when you have to do a task that requires organization?",
    field: "difficultyGettingThingsInOrder",
    minHighlight: 2,
  },
  {
    question:
      "How often do you have problems remembering appointments or obligations?",
    field: "rememberingAppointments",
    minHighlight: 2,
  },
  {
    question:
      "When you have a task that requires a lot of thought, how often do you avoid or delay getting started?",
    field: "avoidStartingTasks",
    minHighlight: 3,
  },
  {
    question:
      "How often do you fidget or squirm with your hands or feet when you have to sit down for a long time?",
    field: "fidgetOrSquirmWhenHaveToSit",
    minHighlight: 3,
  },
  {
    question:
      "How often do you feel overly active and compelled to do things, like you were driven by a motor?",
    field: "overlyActive",
    minHighlight: 3,
  },
];

export const questionsPartB: SelfReportQuestion[] = [
  {
    question:
      "How often do you make careless mistakes when you have to work on a boring or difficult project?",
    field: "carelessMistakes",
    minHighlight: 3,
  },
  {
    question:
      "How often do you have difficulty keeping your attention when you are doing boring or repetitive work?",
    field: "keepingAttentionForBoringWork",
    minHighlight: 2,
  },
  {
    question:
      "How often do you have difficulty concentrating on what people say to you, even when they are speaking to you directly?",
    field: "difficultyConcentratingOnWhatPeopleSay",
    minHighlight: 3,
  },
  {
    question:
      "How often do you misplace or have difficulty finding things at home or at work?",
    field: "misplaceHaveDifficultyFindingThings",
    minHighlight: 3,
  },
  {
    question: "How often are you distracted by activity or noise around you?",
    field: "distractedByActivityOrNoise",
    minHighlight: 2,
  },
  {
    question:
      "How often do you leave your seat in meetings or in other situations in which you are expected to stay seated?",
    field: "leaveSeatInMeetings",
    minHighlight: 3,
  },
  {
    question: "How often do you feel restless or fidgety?",
    field: "feelRestlessOrFidgety",
    minHighlight: 3,
  },
  {
    question:
      "How often do you have difficulty unwinding and relaxing when you have time to yourself?",
    field: "difficultyUnwindingAndRelaxing",
    minHighlight: 3,
  },
  {
    question:
      "How often do you find yourself talking too much when you are in social situations?",
    field: "talkingTooMuchInSocialSituations",
    minHighlight: 3,
  },
  {
    question:
      "When you are in a conversation, how often do you find yourself finishing the sentences of the people you are talking to, before they can finish it themselves?",
    field: "finishOthersSentences",
    minHighlight: 2,
  },
  {
    question:
      "How often do you have difficulty waiting your turn in situations when turn taking is required?",
    field: "difficultyWaitingYourTurn",
    minHighlight: 3,
  },
  {
    question: "How often do you interrupt others when they are busy?",
    field: "interruptsOthers",
    minHighlight: 2,
  },
];

export const selfReportQuestions = [
  { title: "Part A", questions: questionsPartA },
  { title: "Part B", questions: questionsPartB },
];
