import { Typography } from "@mui/material";
import {
  BooleanField,
  Datagrid,
  DateField,
  ReferenceManyField,
  SimpleShowLayout,
  TextField,
  WithRecord,
  useCreatePath,
} from "react-admin";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./../Prescreenings/Accordions";
import { Empty } from "../../components";

export const UserIneligibleRecords = () => {
  const createPath = useCreatePath();

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="IneligibleRecords-content"
        id="IneligibleRecords-header"
      >
        <Typography>Ineligible Records</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <SimpleShowLayout>
          <ReferenceManyField
            reference="admin/ineligible-records"
            target="user.id"
            sort={{ field: "createdAt", order: "DESC" }}
          >
            <Datagrid bulkActionButtons={false} empty={<Empty />}>
              <WithRecord
                render={record => (
                  <Link
                    to={createPath({
                      resource: "admin/ineligible-records",
                      type: "show",
                      id: record.id,
                    })}
                  >
                    Open
                  </Link>
                )}
              />
              <TextField source="reasons" />
              <BooleanField source="void" />
              <TextField source="voidedBy" />
              <DateField source="voidedAt" showTime />
            </Datagrid>
          </ReferenceManyField>
        </SimpleShowLayout>
      </AccordionDetails>
    </Accordion>
  );
};
