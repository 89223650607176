import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import axios, { AxiosRequestHeaders } from "axios";
import React, { useState } from "react";
import { useNotify } from "react-admin";
import { REACT_APP_AUTH0_AUDIENCE } from "../../utils";
import { MAX_FILE_SIZE } from "../../utils/fileHandler";

const documentTypes = [
  "id",
  "insurance",
  "psychiatrist",
  "psychologist",
  "cardiologist",
  "specialist",
  "labs",
  "ecgs",
  "adhd",
  "other",
];
type DocumentType = (typeof documentTypes)[number];

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
  },
}));

type UserDocumentUploadProps = {
  handleClose: () => void;
  refetchData: () => Promise<void>;
  open: boolean;
  userId: string;
  handleError: (message: string) => void;
  handleSuccess: (message: string) => void;
};

export const UserDocumentUpload = ({
  handleClose: handleCloseProp,
  refetchData,
  open,
  userId,
  handleError,
  handleSuccess,
}: UserDocumentUploadProps) => {
  const classes = useStyles();
  const notify = useNotify();
  const { getAccessTokenSilently } = useAuth0();
  const [file, setFile] = useState<File | undefined>(undefined);
  const [documentType, setDocumentType] = useState<DocumentType | null>("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (loading) {
      return;
    }

    setFile(undefined);
    setDocumentType("");
    handleCloseProp();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return;
    }
    const file = event.target.files[0];
    const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);

    if (file.size > MAX_FILE_SIZE) {
      notify(
        `File size (${fileSizeMB} MB) exceeds the maximum allowed size of 10 MB.`,
        { type: "error" }
      );
      return;
    }

    setFile(file);
  };

  const handleUpload = async () => {
    if (!file || !documentType) {
      return;
    }

    setLoading(true);

    const accessToken = await getAccessTokenSilently();
    const headers: AxiosRequestHeaders = {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const formData = new FormData();
    formData.append("files", file);
    formData.append("userId", userId);

    try {
      await axios.request({
        method: "POST",
        url: `${REACT_APP_AUTH0_AUDIENCE}/admin/storage/${documentType}`,
        headers,
        data: formData,
      });
      handleSuccess(`Document uploaded successfully: ${file.name}`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(`Failed to upload document: ${file.name}`);
      }
    }
    await refetchData();
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Upload Document</DialogTitle>
      <DialogContent style={{ paddingBottom: 0 }}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel>Document Type</InputLabel>
          <Select
            fullWidth
            value={documentType}
            onChange={event =>
              setDocumentType(event.target.value as DocumentType)
            }
            style={{ textTransform: "capitalize" }}
          >
            {documentTypes.map(documentType => (
              <MenuItem
                key={documentType}
                value={documentType}
                style={{ textTransform: "capitalize" }}
              >
                {documentType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} fullWidth>
          <DialogContentText>
            {/* If empty, populate with span and non-breaking space to preserve height */}
            {file?.name || <span>&nbsp;</span>}
          </DialogContentText>
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUploadIcon />}
            disabled={loading}
          >
            Select File
            <input
              style={{ display: "none" }}
              type="file"
              onChange={handleFileChange}
              accept=".jpg,.jpeg,.png,.pdf,.heic,.heif"
            />
          </Button>
        </FormControl>
      </DialogContent>
      <DialogActions>
        {loading && (
          <CircularProgress style={{ width: "1rem", height: "1rem" }} />
        )}
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleUpload}
          disabled={!file || !documentType || loading}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};
