import { useAuth0 } from "@auth0/auth0-react";
import SendIcon from "@mui/icons-material/Send";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import {
  Confirm,
  Create,
  FileField,
  FileInput,
  RecordContextProvider,
  SaveButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  maxLength,
  useGetOne,
  useNotify,
  useRedirect,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { MessageTemplate } from "./messages.types";
import {
  doReplacements,
  getDistinctMustacheVariables,
  getMissingVariables,
} from "./messages.utils";
import { User } from "../../types/User.type";
import { REACT_APP_AUTH0_AUDIENCE, useHttpClient } from "../../utils";
import {
  handleFileRejections,
  MAX_FILE_SIZE,
  MAX_FILES,
  SUPPORTED_FILE_TYPES,
} from "../../utils/fileHandler";
import { logger } from "../../utils/logging";

function definedValuesOf(data: object) {
  const definedData = {};
  for (const key in data) {
    if (data[key] !== undefined) {
      definedData[key] = data[key];
    }
  }
  return definedData;
}

export function MessageCreate() {
  const redirect = useRedirect();
  const notify = useNotify();
  const [searchParams] = useSearchParams();
  const { getAccessTokenSilently } = useAuth0();
  const userId = searchParams.get("to_id");
  const { data: user, isLoading: isUserLoading } = useGetOne("users", {
    id: userId,
  });
  const { httpClient, baseUrl } = useHttpClient();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [handleSubmitData, setHandleSubmitData] = useState<object>({});

  const [templates, setTemplates] = useState<MessageTemplate[]>([]);

  useEffect(() => {
    const fetchTemplates = async () => {
      const res = await httpClient(`${baseUrl}/api/admin/message-templates`, {
        method: "GET",
      });

      setTemplates(res.json);
    };
    fetchTemplates();
  }, []);

  async function handleConfirm(data) {
    setConfirmOpen(true);
    // If the user has tried submitting and received an error, the form data
    // will get reset and the values will be undefined unless the user edits
    // the form. This is a workaround to keep the data.
    const mergedData = { ...handleSubmitData, ...definedValuesOf(data) };
    setHandleSubmitData(mergedData);
  }

  async function handleSubmit() {
    setIsSubmitting(true);
    const accessToken = await getAccessTokenSilently();
    const data = handleSubmitData;
    await createMessage(data, { userId, notify, redirect, accessToken });
    setConfirmOpen(false);
    setIsSubmitting(false);
  }

  if (isUserLoading || !templates) {
    return null;
  }

  return (
    // Override React-Admin default `onSubmit` handler (in the Create component) with our handleSubmit prop on SimpleForm
    <Create aside={<UserInfoAside user={user} />}>
      <SimpleForm
        warnWhenUnsavedChanges={true}
        onSubmit={handleConfirm}
        toolbar={<MessageCreateToolbar />}
      >
        <MessageCreateForm user={user} templates={templates} notify={notify} />
      </SimpleForm>
      <Confirm
        title="Confirmation"
        content={
          <span>
            Are you sure you want to send this message to{" "}
            <strong>{`${user.firstName} ${user.lastName}`}</strong>?
          </span>
        }
        isOpen={confirmOpen}
        onConfirm={handleSubmit}
        onClose={() => setConfirmOpen(false)}
        loading={isSubmitting}
        confirm={isSubmitting ? "Sending..." : "Yes"}
      />
    </Create>
  );
}

function TemplateVariableHelper(missingVariables: string[]) {
  return (
    <Box paddingTop={"1em"}>
      {Object.keys(missingVariables).length === 0 ? (
        <span>
          <b>✅ No missing variables.</b>
        </span>
      ) : (
        <>
          <span>
            <b>❌ Variables are missing.</b>
          </span>
          <ul>
            {missingVariables.map((replacement, index) => (
              <li key={index}>{replacement}</li>
            ))}
          </ul>
        </>
      )}
    </Box>
  );
}

function MessageCreateForm({
  user,
  templates,
  notify,
}: {
  user: User;
  templates: MessageTemplate[];
  notify;
}) {
  const toUserValue = `${user.email} <${user.firstName} ${user.lastName}>`;
  const [missingVariables, setMissingVariables] = useState<string[] | null>(
    null
  );

  const formContext = useFormContext();

  const handleTemplateChange = event => {
    const selectedTemplate = templates.find(
      template => template.id === event.target.value
    );
    if (selectedTemplate) {
      const variableDictionary = {
        "{{PATIENT_PREFERRED_NAME}}": user.preferredName || user.firstName,
      };
      const _missingVariables = getMissingVariables(
        variableDictionary,
        selectedTemplate.body
      );

      const updatedBody = doReplacements(
        selectedTemplate.body,
        variableDictionary
      );
      formContext.setValue("body", updatedBody);
      formContext.setValue("subject", selectedTemplate.subject);
      setMissingVariables(_missingVariables);
    }
  };

  function isMissingVariablesSet() {
    // If it's null, it's not set.
    return missingVariables != null;
  }

  function doesBodyContainTemplateVariables(event) {
    const body = event.target.value;
    const variables = getDistinctMustacheVariables(body);
    setMissingVariables(variables);
  }

  return (
    <>
      <TextInput
        required
        fullWidth
        disabled
        source="to"
        defaultValue={toUserValue}
      />
      <Box>
        <FormControl fullWidth>
          <InputLabel>Select Template</InputLabel>
          <Select
            style={{ minWidth: 300 }}
            defaultValue={""}
            onChange={handleTemplateChange}
          >
            {templates.length === 0 ? (
              <MenuItem disabled>Loading...</MenuItem>
            ) : (
              templates.map(template => (
                <MenuItem key={template.id} value={template.id}>
                  {template.name}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Box>
      {isMissingVariablesSet() &&
        TemplateVariableHelper(missingVariables as string[])}
      <hr style={{ width: "100%" }} />
      <TextInput
        required
        fullWidth
        source="subject"
        validate={maxLength(1000, "content too long, max 1000 characters")}
      />
      <TextInput
        onChange={doesBodyContainTemplateVariables}
        required
        fullWidth
        multiline
        minRows={10}
        source="body"
        validate={maxLength(60000, "content too long, max 60000 characters")}
      />
      <FileInput
        source="attachments"
        accept={SUPPORTED_FILE_TYPES}
        multiple
        maxSize={MAX_FILE_SIZE}
        options={{
          maxFiles: MAX_FILES,
          onDropRejected: fileRejections =>
            handleFileRejections(fileRejections, notify),
        }}
      >
        <FileField source="src" title="title" />
      </FileInput>
    </>
  );
}

function MessageCreateToolbar() {
  return (
    <Toolbar>
      <SaveButton
        alwaysEnable={true} // needed so that a template with all its variables can be sent without being edited
        sx={{ marginLeft: "auto" }}
        label={"Send"}
        icon={<SendIcon />}
      />
    </Toolbar>
  );
}

function UserInfoAside({ user }) {
  return (
    <RecordContextProvider value={user}>
      <SimpleShowLayout>
        <b>Patient Info</b>
        <TextField source="preferredName" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
      </SimpleShowLayout>
    </RecordContextProvider>
  );
}

async function createMessage(data, { userId, notify, redirect, accessToken }) {
  const body = data.body;
  if (getDistinctMustacheVariables(body).length > 0) {
    notify("Please fill in all template variables", {
      type: "warning",
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
    return;
  }
  const { attachments, toUser, ...rest } = data;

  const formData = new FormData();
  formData.append("toUser", userId || ""); // userId must be present for the page to render due to other dependencies
  for (const key in rest) {
    formData.append(key, rest[key]);
  }
  for (const attachment of attachments || []) {
    formData.append("attachments", attachment.rawFile);
  }

  try {
    const url = `${REACT_APP_AUTH0_AUDIENCE}/admin/messages`;
    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    };

    await axios.post(url, formData, { headers });

    notify("Message created & sent to user", {
      type: "success",
    });
    redirect("show", "users", userId!);
  } catch (error) {
    logger.error("Failed to send secure message", { error });
    notify(`${((error as AxiosError)?.response?.data as any)?.message}.`, {
      type: "error",
    });
  }
}
