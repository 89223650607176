import { Typography } from "@material-ui/core";
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "./Accordions";
import { AllergiesInfo, IPrescreening } from "./Prescreening";
import { SortField, SortFields } from "./SortFields";
import { removeSubsection } from "./utils";

interface AllergiesProps {
  data: IPrescreening;
}

export const Allergies: React.FC<AllergiesProps> = ({ data }) => {
  const fields: SortField[] = [
    { name: "hasAllergies", hideWhenTrue: true },
    {
      name: "",
      subsection: {
        label: "",
        fields: [
          {
            name: "hasAllergiesDetails",
            label: "",
          },
        ],
      },
    },
  ];

  if (data.hasAllergies === false) {
    data.hasAllergiesDetails = "None";
  }
  if (data.hasAllergies === undefined) {
    removeSubsection(fields, "hasAllergiesDetails");
  }

  if (data.hasAllergiesDetails) {
    try {
      data.hasAllergiesDetails = JSON.parse(
        data.hasAllergiesDetails as string
      ) as AllergiesInfo[];
    } catch (e) {
      // do nothing
    }
  }

  // Mutate the data so it is easy to read
  if (Array.isArray(data.hasAllergiesDetails)) {
    data.hasAllergiesDetails = data.hasAllergiesDetails.map(details => ({
      allergen: details.allergen,
      reaction: details.reaction,
      severity: details.severity,
    }));
  }

  return (
    <Accordion id="AllergiesContainer">
      <AccordionSummary
        aria-controls="Allergies-content"
        id="Allergies-content-header"
      >
        <Typography>Allergies</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SortFields data={data} fields={fields} />
      </AccordionDetails>
    </Accordion>
  );
};
