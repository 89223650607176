import React from "react";
import { AppBar, Layout, Logout, UserMenu } from "react-admin";

const MyUserMenu = props => {
  return (
    <>
      <UserMenu {...props}>
        <Logout />
      </UserMenu>
    </>
  );
};

const CustomAppBar = props => <AppBar {...props} userMenu={<MyUserMenu />} />;

const CustomLayout = props => <Layout {...props} appBar={CustomAppBar} />;

export default React.memo(CustomLayout);
