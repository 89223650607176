import {
  SimpleForm,
  TextInput,
  Create,
  required,
  BooleanInput,
  DateInput,
} from "react-admin";

const validateRequired = required();

export const CalendlyCreate = props => {
  const pageTitle = "Create User";

  return (
    <Create {...props} title={pageTitle} redirect="show">
      <SimpleForm>
        <BooleanInput source="canceled" fullWidth />
        <TextInput source="email" validate={validateRequired} fullWidth />
        <TextInput source="name" validate={validateRequired} fullWidth />
        <TextInput source="status" validate={validateRequired} fullWidth />
        <TextInput source="host_name" validate={validateRequired} fullWidth />
        <BooleanInput source="canceled" fullWidth />
        <DateInput source="eventStartTime" fullWidth />
        <DateInput source="eventEndTime" fullWidth />
      </SimpleForm>
    </Create>
  );
};
