import { CondOperator, RequestQueryBuilder } from "@nestjsx/crud-request";
import { REACT_APP_BASE_URL } from ".";

const buildUserQuery = params => {
  const queryBuilder = RequestQueryBuilder.create();
  const filters = params.filter;

  for (const filter in filters) {
    queryBuilder.setFilter({
      field: filter,
      operator: CondOperator.CONTAINS,
      value: filters[filter],
    });
  }
  queryBuilder
    .setLimit(params.pagination.perPage)
    .setPage(params.pagination.page)
    .sortBy(params.sort);

  return queryBuilder.query();
};

export const getUserListUrl = params => {
  const query = buildUserQuery(params);
  return `${REACT_APP_BASE_URL}/api/users?${query}`;
};
