import { Typography } from "@material-ui/core";
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "./Accordions";
import { IPrescreening } from "./Prescreening";
import { SortField, SortFields } from "./SortFields";
import { removeSubsection } from "./utils";

interface PrescriptionHistoryProps {
  data: IPrescreening;
}

export const PrescriptionHistory: React.FC<PrescriptionHistoryProps> = ({
  data,
}) => {
  const fields: SortField[] = [
    {
      name: "",
      subsection: {
        label: "",
        fields: [
          { name: "primaryCareProvider" },
          { name: "primaryCareProviderName" },
          { name: "primaryCareProviderPhone" },
          { name: "primaryCareProviderFax" },
        ],
      },
    },
    {
      name: "consentForRecordsToBeSentToGP",
      label: "Consents for records to be sent to GP",
    },
    { name: "primaryPharmacy" },
    {
      name: "hasPreviousPrescriptions",
      label:
        "Has Previous Psychiatric Medications (Anxiety, Depression, Insomnia)",
      hideWhenTrue: true,
    },
    {
      name: "",
      subsection: {
        label: "",
        fields: [
          {
            name: "hasPreviousPrescriptionsDetails",
            label:
              "Previous Psychiatric Medications (Anxiety, Depression, Insomnia)",
          },
        ],
      },
    },
    {
      name: "hasCurrentPrescriptions",
      label: "Has Current Meds",
      hideWhenTrue: true,
    },
    {
      name: "",
      subsection: {
        includeSpacing: !data.hasPreviousPrescriptionsDetails,
        label: "",
        fields: [
          {
            name: "hasCurrentPrescriptionsDetails",
            label: "Current Meds",
          },
        ],
      },
    },
  ];

  if (data.hasPreviousPrescriptions === false) {
    data.hasPreviousPrescriptionsDetails = "None" as any;
  }
  if (data.hasPreviousPrescriptions === undefined) {
    removeSubsection(fields, "hasPreviousPrescriptionsDetails");
  }

  if (data.hasCurrentPrescriptions === false) {
    data.hasCurrentPrescriptionsDetails = "None" as any;
  }
  if (data.hasCurrentPrescriptions === undefined) {
    removeSubsection(fields, "hasCurrentPrescriptionsDetails");
  }

  // Mutate the data so it is easy to read
  if (Array.isArray(data.hasCurrentPrescriptionsDetails)) {
    data.hasCurrentPrescriptionsDetails = data.hasCurrentPrescriptionsDetails
      ?.filter(details => !!details)
      .map(details => ({
        medication: details.medication,
        dose: details.dose,
        frequency: details.frequency,
      }));
  }
  if (Array.isArray(data.hasPreviousPrescriptionsDetails)) {
    data.hasPreviousPrescriptionsDetails =
      data.hasPreviousPrescriptionsDetails?.map(details => ({
        medication: details.medication,
        duration: details.duration,
        effectiveness: details.effectiveness,
        sideEffects: details.sideEffects,
      }));
  }

  return (
    <Accordion id="PrescriptionHistoryContainer">
      <AccordionSummary
        aria-controls="PrescriptionHistory-content"
        id="PrescriptionHistory-content-header"
      >
        <Typography>Medications</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SortFields data={data} fields={fields} />
      </AccordionDetails>
    </Accordion>
  );
};
