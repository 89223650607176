import { SelfReportQuestion } from "./questions";
import { SelfReportForm, SelfReportFormFields } from "./types";

const sometimesOrHigher: SelfReportFormFields[] = [
  "troubleWrappingUpFinalDetails",
  "difficultyGettingThingsInOrder",
  "rememberingAppointments",
  "keepingAttentionForBoringWork",
  "distractedByActivityOrNoise",
  "finishOthersSentences",
  "interruptsOthers",
];

export const calculateScore = (
  form: SelfReportForm,
  questions: SelfReportQuestion[]
) => {
  let totalScore = 0;
  questions.forEach(question => {
    if (sometimesOrHigher.some(field => field === question.field)) {
      if (form[question.field]! > 1) {
        totalScore++;
      }
    } else {
      if (form[question.field]! > 2) {
        totalScore++;
      }
    }
  });
  return totalScore;
};
