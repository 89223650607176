export const relationshipOptions = [
  { label: "Product Development", value: "Product Development" },
  { label: "NP/GP", value: "NP/GP" },
  { label: "CBT Provider", value: "CBT Provider" },
  { label: "Quality Assurance", value: "Quality Assurance" },
  { label: "Psychiatrist", value: "Psychiatrist" },
  { label: "Operations Lead", value: "Operations Lead" },
  { label: "Pharmacist", value: "Pharmacist" },
  { label: "MOA", value: "MOA" },
  { label: "Business Analytics", value: "Business Analytics" },
];

export const reasonForViewing = [
  { label: "Quality Assurance Review", value: "Quality Assurance Review" },
  {
    label: "Review for Clinical Care/Planning",
    value: "Review for Clinical Care/Planning",
  },
  { label: "Admin", value: "Admin" },
  {
    label: "Filling/Updating Prescription",
    value: "Filling/Updating Prescription",
  },
  {
    label: "Statistics",
    value: "Statistics",
  },
  { label: "Other", value: "Other" },
];
