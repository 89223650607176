import { Box, Divider } from "@material-ui/core";
import React from "react";
import { DataContainer, convertNameToTitle } from "./DataContainer";
import { IPrescreening } from "./Prescreening";

export interface SortField {
  label?: string;
  name: string;
  hideWhenTrue?: boolean;
  /** Override the field with specific data */
  data?: any;
  highlight?: boolean;
  subsection?: {
    label: string;
    fields: Omit<SortField, "subsection">[];
    includeSpacing?: boolean;
  };
}

export interface SortFieldsProps {
  fields: SortField[];
  data: IPrescreening;
}

export const SortFields: React.FC<SortFieldsProps> = ({ fields, data }) => {
  const trueFields = fields.filter(
    field => (!!data[field.name] && !field.hideWhenTrue) || field.subsection
  );
  const falseFields = fields.filter(
    field => !data[field.name] && !field.subsection
  );
  return (
    <>
      {trueFields.length > 0 && (
        <Box
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "0.25rem",
            color: "green",
          }}
        >
          Confirmed
        </Box>
      )}
      {trueFields.map((field, index) => {
        if (field.subsection) {
          return (
            <Box key={index}>
              {field.subsection.includeSpacing !== false && <br />}
              <span style={{ fontWeight: "bold" }}>
                {field.subsection.label}
              </span>
              {field.subsection.fields
                .filter(
                  field => !(field.hideWhenTrue && data[field.name] === true)
                )
                .map(field => (
                  <DataContainer
                    key={`${field.label}-${field.name}`}
                    title={field.label ?? convertNameToTitle(field.name)}
                    data={field.data ?? data[field.name]}
                    alwaysShowValue
                    highlight={field.highlight}
                  />
                ))}
              <br />
            </Box>
          );
        }
        return (
          <DataContainer
            key={`${field.label}-${field.name}`}
            title={field.label ?? convertNameToTitle(field.name)}
            data={data[field.name]}
            highlight={field.highlight}
          />
        );
      })}
      {falseFields.length > 0 && trueFields.length > 0 && (
        <Divider style={{ margin: "1rem 0" }} />
      )}
      {falseFields.length > 0 && (
        <Box
          style={{
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "0.25rem",
            color: "red",
          }}
        >
          Does not affect client (No) or not filled in (null)
        </Box>
      )}
      {falseFields.map(field => (
        <DataContainer
          key={`${field.label}-${field.name}`}
          title={field.label ?? convertNameToTitle(field.name)}
          data={data[field.name]}
        />
      ))}
    </>
  );
};
