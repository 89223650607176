type Refill = {
  id: string;
  dateFilled: Date;
  refillsRemaining: number;
  trackingNumber?: string;
  cost: number;
  insuranceDiscount?: number;
  finalCost: number;
};

export const getLatestRefill = prescription => {
  const allRefills = prescription.refill;
  if (!allRefills || allRefills.length === 0) {
    return null;
  }
  const sortedRefills = allRefills.sort(sortByDateFilled);
  const refill = sortedRefills[0];
  return refill;
};

const sortByDateFilled = (a: Refill, b: Refill) => {
  if (a.dateFilled < b.dateFilled) {
    return 1;
  } else {
    return -1;
  }
};
