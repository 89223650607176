import { Typography } from "@mui/material";
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "./Accordions";
import { IPrescreening } from "./Prescreening";
import { SortField, SortFields } from "./SortFields";

interface FamilyHistoryProps {
  data: IPrescreening;
}

export const FamilyHistory: React.FC<FamilyHistoryProps> = ({ data }) => {
  const fields: SortField[] = [
    {
      name: "familyMemberDiagnosedWithCondition",
      label: "Has a family member with diagnosis",
      hideWhenTrue: true,
    },
  ];

  if (data.familyMemberDiagnosedWithCondition) {
    fields.push({
      name: "familyMemberDiagnosedWithConditionDetails",
      label: "Family Member Diagnoses Details",
    });
  }

  // Mutate the data so that it displays as name, diagnosis
  if (Array.isArray(data.familyMemberDiagnosedWithConditionDetails)) {
    data.familyMemberDiagnosedWithConditionDetails =
      data.familyMemberDiagnosedWithConditionDetails
        ?.filter(details => !!details)
        .map(details => ({
          familyMember: details.familyMember,
          diagnosis: details.diagnosis,
        }));
  }

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="FamilyHistory-content"
        id="FamilyHistory-header"
      >
        <Typography>Family History</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SortFields data={data} fields={fields} />
      </AccordionDetails>
    </Accordion>
  );
};
