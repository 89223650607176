import moment from "moment";
import { FunctionComponent } from "react";
import { useCreatePath } from "react-admin";
import { Link } from "react-router-dom";

export interface ICalendlyFieldProps {
  appointment?: {
    id: string;
    host_name?: string;
    eventStartTime: string;
  };
}

export const CalendlyField: FunctionComponent<ICalendlyFieldProps> = ({
  appointment,
}) => {
  const createPath = useCreatePath();

  if (!appointment) {
    return null;
  }

  return (
    <Link
      to={createPath({
        resource: "calendly",
        type: "show",
        id: appointment.id,
      })}
    >
      {moment(appointment.eventStartTime).fromNow()} with{" "}
      {appointment.host_name || "Unknown"}
    </Link>
  );
};
