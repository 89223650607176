import {
  WenderUtahRatingScaleForm,
  WenderUtahRatingScaleFormFields,
} from "./types";

export const questionsToTakeIntoConsideration: WenderUtahRatingScaleFormFields[] =
  [
    "easilyDistracted",
    "anxious",
    "nervous",
    "inattentive",
    "hotOrShortTempered",
    "temperOutbursts",
    "failToFinishThings",
    "stubborn",
    "unhappy",
    "rebellious",
    "lowOpinionOfMyself",
    "irritable",
    "moodyUpsAndDowns",
    "angry",
    "impulsive",
    "tendencyToBeImmature",
    "guiltyFeelings",
    "losingControlOfMyself",
    "irrational",
    "unpopular",
    "troubleSeeingThingsFromAnotherPointOfView",
    "troublesWithAuthorities",
    "overallAPoorStudent",
    "troubleWithMathOrNumbers",
    "notAchievingUpToPotential",
  ];

export const calculateScore = (form: WenderUtahRatingScaleForm) => {
  let totalScore = 0;
  questionsToTakeIntoConsideration.forEach(key => {
    totalScore = totalScore + form[key]!;
  });

  return totalScore;
};
