import { Box, Typography } from "@material-ui/core";
import {
  Document as PDFDocument,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import React from "react";
import { WenderUtahRatingScaleForm } from "./types";
import { WursPage } from "./WursPage";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../Prescreenings/Accordions";

export const WursDocument: React.FC<{
  data: WenderUtahRatingScaleForm;
  clientName: string;
}> = ({ data, clientName }) => {
  const Document = () => (
    <PDFDocument>
      <WursPage data={data} clientName={clientName} />
    </PDFDocument>
  );

  return (
    <Accordion>
      <AccordionSummary aria-controls="WURS-content" id="WURS-header">
        <Typography>
          Wender Utah Rating Scale for the Attention Deficit Hyperactivity
          Disorder
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PDFDownloadLink
          document={<Document />}
          fileName={`${clientName} - Wender Utah Rating Scale for the Attention Deficit Hyperactivity
          Disorder.pdf`}
        >
          {({ loading }) => (loading ? "Loading document..." : "Download now")}
        </PDFDownloadLink>
        <Box sx={{ height: "80vh" }}>
          <PDFViewer showToolbar={false} height="100%" width="100%">
            <Document />
          </PDFViewer>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
