import { Box, Typography } from "@material-ui/core";
import {
  Document as PDFDocument,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import React from "react";
import { SnapPage } from "./SnapPage";
import { SnapForm } from "./types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../Prescreenings/Accordions";

export const SnapDocument: React.FC<{
  data: SnapForm;
  clientName: string;
}> = ({ data, clientName }) => {
  const Document = () => (
    <PDFDocument>
      <SnapPage data={data} clientName={clientName} />
    </PDFDocument>
  );

  return (
    <Accordion>
      <AccordionSummary aria-controls="Snap-content" id="Snap-header">
        <Typography>SNAP-IV-26</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PDFDownloadLink
          document={<Document />}
          fileName={`${clientName} - Snap-IV-26.pdf`}
        >
          {({ loading }) => (loading ? "Loading document..." : "Download now")}
        </PDFDownloadLink>
        <Box sx={{ height: "80vh" }}>
          <PDFViewer showToolbar={false} height="100%" width="100%">
            <Document />
          </PDFViewer>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
