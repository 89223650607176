import { useAuth0 } from "@auth0/auth0-react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AxiosRequestHeaders } from "axios";
import { useEffect, useState } from "react";
import { useGetRecordId } from "react-admin";
import { requestHelper } from "../../utils/requestHelper";
import { ISurveyResponse } from "../SurveyResponse/types";

export const SurveyResponse = () => {
  const recordId = useGetRecordId();
  const { getAccessTokenSilently } = useAuth0();
  const [surveyData, setSurveyData] = useState<ISurveyResponse[]>([]);
  const [currentSurveyResponse, setCurrentSurveyResponse] =
    useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      // Add content-type and Authorization
      const headers: AxiosRequestHeaders = {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      const results = await requestHelper<ISurveyResponse[]>(
        "admin/survey_response/user",
        recordId,
        headers
      );
      setSurveyData(results);
    };

    if (!recordId) {
      return;
    }
    fetchData();
  }, []);

  if (!recordId || surveyData.length === 0) {
    return null;
  }

  const getSelectedSurveyResponse = () => {
    return surveyData.find(
      survey => `${survey.programName}-${survey.name}` === currentSurveyResponse
    );
  };

  return (
    <Box style={{ padding: "1rem 0" }}>
      <FormControl fullWidth>
        <InputLabel id="select-label">Survey</InputLabel>
        <Select
          labelId="select-label"
          onChange={e => setCurrentSurveyResponse(e.target.value as string)}
          value={currentSurveyResponse}
        >
          {surveyData.map(survey => (
            <MenuItem
              key={`${survey.programName}-${survey.name}`}
              value={`${survey.programName}-${survey.name}`}
            >
              {`${survey.programName}-${survey.name}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {currentSurveyResponse && (
        <Box>
          <Box
            sx={{
              fontWeight: "bold",
              marginTop: "0.5rem",
              color: getSelectedSurveyResponse()?.completed ? "green" : "red",
            }}
          >{`Completed: ${getSelectedSurveyResponse()?.completed}`}</Box>
          {getSelectedSurveyResponse()?.questionsAndAnswers?.map(data => (
            <Box key={data.question} sx={{ marginTop: "0.5rem" }}>
              <Box sx={{ fontWeight: "bold" }}>{data.question}</Box>
              <Box>{data.answer}</Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
