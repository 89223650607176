import { Stack } from "@mui/material";
import {
  BooleanInput,
  DateInput,
  Edit,
  SaveButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  maxLength,
} from "react-admin";

export const IneligibleRecord = () => {
  return (
    <Edit
      aside={<UserInfo />}
      mutationMode="pessimistic"
      transform={transformIneligibleRecord}
    >
      <SimpleForm toolbar={<IneligibleRecordToolbar />}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
          spacing={2}
        >
          <DateInput disabled source="createdAt" />
        </Stack>
        <TextInput disabled fullWidth source="reasons" />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
          spacing={2}
        >
          <BooleanInput source="void" />
          <TextInput source="voidedBy" />
          <DateInput disabled source="voidedAt" />
        </Stack>
        <TextInput
          fullWidth
          source="voidReason"
          validate={maxLength(1023, "content too long, max 1023 characters")}
        />
      </SimpleForm>
    </Edit>
  );
};

function UserInfo() {
  return (
    <SimpleShowLayout sx={{ "RaSimpleShowLayout-stack": { color: "red" } }}>
      <TextField source="user.firstName"></TextField>
      <TextField source="user.lastName"></TextField>
      <TextField source="user.mobile"></TextField>
      <TextField source="user.consentToCommunication"></TextField>
    </SimpleShowLayout>
  );
}

function IneligibleRecordToolbar() {
  return (
    <Toolbar>
      <SaveButton label="Save" />
    </Toolbar>
  );
}

function transformIneligibleRecord(data: any) {
  const excludedKeys = ["user", "createdAt", "updatedAt", "voidedAt"];

  const result = Object.keys(data).reduce(
    (output, key) =>
      excludedKeys.includes(key) ? output : { ...output, [key]: data[key] },
    {}
  );

  return result;
}
