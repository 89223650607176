import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Typography } from "@mui/material";
import {
  BooleanField,
  Button,
  Datagrid,
  DeleteWithConfirmButton,
  FunctionField,
  ReferenceManyField,
  SimpleShowLayout,
  TextField,
  WithRecord,
  useRecordContext,
} from "react-admin";
import { Link } from "react-router-dom";
import { PrescriptionNameField } from "./UserShowFields";
import { Empty } from "../../components";
import { getLatestRefill } from "../../utils/getLatestRefill";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../Prescreenings/Accordions";

export const UserPrescriptions = () => {
  return (
    <Accordion>
      <AccordionSummary
        aria-controls="Prescriptions-content"
        id="Prescriptions-header"
      >
        <Typography>Prescriptions</Typography>
        <CreatePrescriptionButton />
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <SimpleShowLayout>
          <ReferenceManyField
            reference="prescriptions"
            target="prescriptionUser.id"
            sort={{ field: "createdAt", order: "DESC" }}
          >
            <Datagrid bulkActionButtons={false} empty={<Empty />}>
              <WithRecord
                label="Name"
                render={record => (
                  <PrescriptionNameField name={record.name} id={record.id} />
                )}
              />
              <TextField source="strength" emptyText="null" />
              <TextField source="directions" emptyText="null" />
              <TextField source="daysSupply" emptyText="null" />
              <FunctionField
                label="Refills Remaining"
                render={record => {
                  const refill = getLatestRefill(record);
                  return refill?.refillsRemaining ?? "No Refills";
                }}
              />
              <FunctionField
                label="Date Filled"
                render={record => {
                  const refill = getLatestRefill(record);
                  return refill?.dateFilled ?? "N/A";
                }}
              />
              <BooleanField source="refillRequested" />
              <BooleanField source="cancelled" />
              <PrescriptionActionButtons />
            </Datagrid>
          </ReferenceManyField>
        </SimpleShowLayout>
      </AccordionDetails>
    </Accordion>
  );
};

const PrescriptionActionButtons = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  const editPath = `/prescriptions/${record.id}?user_id=${record.user.id}`;

  return (
    <div>
      <Button
        startIcon={<EditIcon />}
        label="edit"
        component={Link}
        to={editPath}
      />
      <DeleteWithConfirmButton
        confirmTitle={`Deleting "${record.name}"`}
        redirect={`/users/${record.user.id}/show`}
      />
    </div>
  );
};

const CreatePrescriptionButton = () => {
  const record = useRecordContext();
  //the record can be empty while loading
  if (!record) {
    return null;
  }
  const path = `/prescriptions/create?user_id=${record.id}`;

  return (
    <Button
      label="Create"
      startIcon={<AddIcon />}
      component={Link}
      variant="outlined"
      aria-label="add prescription to user"
      to={path}
      style={{ marginLeft: "auto" }}
    />
  );
};
