import { parse } from "query-string";
import { useEffect, useState } from "react";
import {
  Create,
  DateInput,
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import { useLocation } from "react-router";
import { CancelButton } from "../../components/CancelButton";
import { useHttpClient } from "../../utils";
import {
  dateFormatter,
  dateParser,
  getDefaultDate,
} from "../../utils/dateInputFilters";
import { getLatestRefill } from "../../utils/getLatestRefill";

const RefillCreateToolbar = props => {
  const redirect = useRedirect();
  const notify = useNotify();
  const { httpClient, baseUrl } = useHttpClient();

  const updateRefillRequested = async data => {
    const prescriptionId = data.prescription.id;

    try {
      await httpClient(`${baseUrl}/api/prescriptions/${prescriptionId}`, {
        method: "PATCH",
        body: JSON.stringify({ refillRequested: false }),
      });
      notify("Refill created", {
        type: "info",
      });
    } catch (e: any) {
      notify("Refill requested value failed to update", {
        type: "error",
      });
    }
  };

  return (
    <Toolbar {...props}>
      <SaveButton
        alwaysEnable
        mutationOptions={{
          onSuccess: data => {
            updateRefillRequested(data);
            redirect(props.path);
          },
          onError: e =>
            notify("Something went wrong. Refill not created", {
              type: "error",
            }),
        }}
        type="button"
      />
      <CancelButton path={`/#${props.path}`} />
    </Toolbar>
  );
};

export const RefillCreate = props => {
  const pageTitle = "Refill Prescription";

  const location = useLocation();
  const dataProvider = useDataProvider();

  const { prescription_id: prescription_id_string } = parse(location.search);

  const redirectPath = prescription_id_string
    ? `/prescriptions/${prescription_id_string}/show`
    : false;

  const [defaultValues, setDefaultValues] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dataProvider
      .getOne("prescriptions", { id: prescription_id_string })
      .then(({ data }) => {
        const refill = getLatestRefill(data);
        if (!refill) {
          setLoading(false);
          return;
        }
        setDefaultValues({
          cost: refill.cost,
          insuranceDiscount: refill.insuranceDiscount,
          refillsRemaining: refill.refillsRemaining - 1,
        });
        setLoading(false);
      });
  }, []);

  if (loading) {
    return null;
  }

  const isRequired = [required()];
  const minValue = min => value =>
    value && value < min ? "Value must be 0 or greater" : undefined;
  const validateMinValue = [required(), minValue(0)];

  return (
    <Create {...props} title={pageTitle} redirect={redirectPath}>
      <SimpleForm
        defaultValues={defaultValues}
        toolbar={<RefillCreateToolbar path={redirectPath} />}
      >
        <TextInput
          source="prescription.id"
          defaultValue={prescription_id_string}
          label="prescription"
          disabled
          fullWidth
        />
        <NumberInput
          source="refillsRemaining"
          validate={validateMinValue}
          step={1}
        />
        <DateInput
          source="dateFilled"
          format={dateFormatter}
          parse={dateParser}
          defaultValue={getDefaultDate()}
          validate={isRequired}
        />
        <NumberInput
          validate={validateMinValue}
          source="cost"
          label="Cost ($)"
        />
        <NumberInput
          validate={minValue(-1)}
          source="insuranceDiscount"
          label="Insurance Discount ($)"
        />
        <TextInput source="trackingNumber" />
      </SimpleForm>
    </Create>
  );
};
