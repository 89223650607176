import { Box } from "@material-ui/core";
import { Chart, registerables } from "chart.js";
import React, { useRef } from "react";
import { Line, getElementAtEvent } from "react-chartjs-2";
Chart?.register(...(registerables ?? []));

export interface GraphPoint {
  id: string;
  /** The date */
  x: string;
  /** The score */
  y: number;
  /** The comment on the mood */
  label?: string;
}

export interface GraphProps {
  data: GraphPoint[];
  /**
   * The percentage of the chart that should be the target zone. For example if
   * you would like the target zone to be half of the chart, pass in 50 (for
   * 50%).
   */
  targetZonePercentage: number;
  targetZoneStartingPosition: "top" | "bottom";
  maxYValue: number;
  mobileView?: boolean;
  /** How often there will be a horizontal line in the graph */
  horizontalLineStep: number;
  /** If defined will return the index of the element that was clicked */
  onClick?: (index: number) => void;
}

const Graph: React.FC<GraphProps> = ({
  data,
  maxYValue,
  mobileView,
  targetZonePercentage,
  targetZoneStartingPosition,
  horizontalLineStep,
  onClick,
}) => {
  const plugins = [
    {
      id: "target_zone_background_color",
      beforeDraw: (chart: {
        tooltip?: any;
        scales?: any;
        ctx?: any;
        chartArea: {
          left: number;
          top: number;
          width: number;
          bottom: number;
          height: number;
        };
      }) => {
        const { ctx, chartArea } = chart;
        const targetZoneHeight =
          chartArea.height * (targetZonePercentage / 100);
        const startFromTop = targetZoneStartingPosition === "top";
        const yCoordinateOfText = startFromTop
          ? chartArea.top + targetZoneHeight / 2
          : chartArea.bottom - targetZoneHeight / 2;
        ctx.fillStyle = "#F1F8FD";
        ctx.fillRect(
          chartArea.left,
          startFromTop ? chartArea.top : chartArea.bottom,
          chartArea.width,
          startFromTop ? targetZoneHeight : targetZoneHeight * -1
        );

        ctx.font = "600 14px Silka";
        ctx.fillStyle = "rgba(4,27,72,0.5)";
        ctx.opacity = "0.3";
        ctx.fillText("Target", 50, yCoordinateOfText);
      },
    },
  ];

  const chartRef = useRef<any>();
  const handleOnClick = event => {
    const element = getElementAtEvent(chartRef.current, event);
    if (!element.length) {
      return;
    }
    onClick?.(element[0].index);
  };

  return (
    <Box maxHeight={"50vh"}>
      <Line
        ref={chartRef}
        onClick={handleOnClick}
        id="graph"
        data={{
          datasets: [
            {
              label: "",
              data,
              borderColor: ["#FF867E"],
              borderWidth: 2,
            },
          ],
        }}
        plugins={plugins}
        options={{
          normalized: true,
          plugins: {
            legend: { display: false },
            tooltip: {
              backgroundColor: "rgb(4, 27, 72)",
              yAlign: "bottom",
              callbacks: {
                beforeBody: tooltip => {
                  return `${(tooltip[0].raw as GraphPoint).y}`;
                },
                afterBody: tooltip => {
                  return `${(tooltip[0].raw as GraphPoint).label ?? ""}`;
                },
                // Label returns an image which is distracting so set it to the
                // empty string so nothing is displayed.
                label: ctx => {
                  return "";
                },
                // Set as the empty string otherwise it will display the date.
                title: ctx => {
                  return "";
                },
              },
            },
          },
          aspectRatio: mobileView ? 1 : 2,
          elements: {
            line: { tension: 0.4 },
            point: {
              radius: 8,
              hoverRadius: 10,
              backgroundColor: "white",
              hoverBackgroundColor: "white",
            },
          },
          clip: false,
          scales: {
            y: {
              grid: { tickLength: 0 },
              min: 0,
              border: { display: false },
              max: maxYValue,
              ticks: {
                stepSize: horizontalLineStep,
                padding: 10,
              },
            },
            x: {
              offset: true,
              grid: { display: false },
              ticks: { padding: 15 },
            },
          },
        }}
      />
    </Box>
  );
};

export default Graph;
