import { Typography } from "@mui/material";
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "./Accordions";
import { IPrescreening, PrescreeningProps } from "./Prescreening";
import { SortFields } from "./SortFields";
import { formatPhoneNumber } from "./utils";

interface RemindersProps {
  data: IPrescreening & PrescreeningProps;
}

export const Reminders: React.FC<RemindersProps> = ({ data }) => {
  const fields: { name: string; label?: string; hideWhenTrue?: boolean }[] = [
    { name: "emergencyContact" },
    { name: "emergencyContactPhone" },
    { name: "emergencyContactRelationship" },
  ];

  const dataToUse = {
    ...data,
    emergencyContactPhone: formatPhoneNumber(data.emergencyContactPhone),
  };

  return (
    <Accordion>
      <AccordionSummary aria-controls="Reminders-content" id="Reminders-header">
        <Typography>Reminders</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SortFields data={dataToUse} fields={fields} />
      </AccordionDetails>
    </Accordion>
  );
};
