import InfoIcon from "@mui/icons-material/Info";
import { Box, Tooltip } from "@mui/material";
import React from "react";

interface ReportingStatisticProps {
  tooltip: string;
  label: string;
  data: any;
}

export const CustomTooltip: React.FC<{ info: string }> = ({ info }) => {
  return (
    <Tooltip
      placement="right"
      sx={{ marginRight: "0.5rem", fontSize: "28px" }}
      title={<h3>{info}</h3>}
      arrow
    >
      <InfoIcon />
    </Tooltip>
  );
};

export const InfoDisplay: React.FC<{ label: string; data: any }> = ({
  data,
  label,
}) => {
  return (
    <Box display={"flex"}>
      <Box>{`${label}: `}</Box>
      <Box marginLeft={"0.25rem"} fontWeight={"bold"}>
        {data}
      </Box>
    </Box>
  );
};

export const ReportingStatistic: React.FC<ReportingStatisticProps> = ({
  data,
  label,
  tooltip,
}) => {
  return (
    <Box margin="1rem" display={"flex"}>
      <CustomTooltip info={tooltip} />
      <InfoDisplay label={label} data={data} />
    </Box>
  );
};
