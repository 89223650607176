import { Box } from "@mui/material";
import { useRecordContext } from "react-admin";

export const Title = () => {
  const record = useRecordContext();
  // the record can be empty while loading
  if (!record) {
    return null;
  }

  return (
    <Box style={{ fontSize: "2rem" }}>
      {record.firstName} {record.lastName}
    </Box>
  );
};
