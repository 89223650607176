import { Auth0Provider } from "@auth0/auth0-react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
} from "./utils";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID as string,
  });
  root.render(
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN as string}
      clientId={REACT_APP_AUTH0_CLIENT_ID as string}
      audience={REACT_APP_AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
    >
      <React.StrictMode>
        <LDProvider>
          <App />
        </LDProvider>
      </React.StrictMode>
    </Auth0Provider>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
