import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";

interface UserDocumentDeleteDialogueProps {
  handleDelete: () => Promise<void>;
  handleClose: () => void;
  fileName: string;
  open: boolean;
}

export const UserDocumentDeleteDialogue = ({
  handleDelete: handleDeleteProp,
  handleClose: handleCloseProp,
  fileName,
  open,
}: UserDocumentDeleteDialogueProps) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (!loading) {
      handleCloseProp();
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    await handleDeleteProp();
    setLoading(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Delete Document</DialogTitle>
      <DialogContent style={{ paddingBottom: 0 }}>
        <DialogContentText>
          Are you sure you would like to delete "{fileName}"?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {loading && (
          <CircularProgress style={{ width: "1rem", height: "1rem" }} />
        )}
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleDelete} disabled={loading} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
