import { convertNameToTitle } from "./DataContainer";
import { AllergiesInfo, IPrescreening } from "./Prescreening";
import {
  convertToHumanReadableAnswer,
  medicalHistoryFields,
  ptsdDifficulties,
  socialHistoryFields,
  substanceUseFields,
} from "./PrescreeningPdfSummaryFields";
import { convertBooleanToYesNo } from "../AdhdInfo/utils";
import { convertDateToHumanReadable } from "../progress/utils";

// Note this is duplicated in PDF form in PrescreeningPDFSummary.tsx
export const getPrescreeningTextSummary = (data: IPrescreening): string => {
  const showPtsdDifficulties =
    data.noPtsdSymptoms === false &&
    data.preferNotToAnswerPtsdSymptoms !== true;

  const showFurtherPtsdQuestions =
    data.experiencedTrauma === true &&
    data.noPtsdSymptoms === false &&
    data.preferNotToAnswerPtsdSymptoms === false;

  // Format ETOH info to be easier to display
  if (!data.drinksAlcoholFrequency) {
    data.etoh = "Not answered";
  } else {
    data.etoh =
      data.drinksAlcoholFrequency !== "Never"
        ? `${data.drinksAlcoholFrequency}, ${data.drinksAlcoholDetails} units per session`
        : `${data.drinksAlcoholFrequency}`;
  }

  if (data.hasAllergiesDetails) {
    try {
      data.hasAllergiesDetails = JSON.parse(
        data.hasAllergiesDetails as string
      ) as AllergiesInfo[];
    } catch (e) {
      // do nothing
    }
  }

  // Group medical history into confirmed and denied
  const confirmedMedicalHistoryFields: string[] = [];
  const deniedMedicalHistoryFields: string[] = [];
  medicalHistoryFields.forEach(field => {
    const label = field.label ?? convertNameToTitle(field.name);
    if (data[field.name] === true) {
      confirmedMedicalHistoryFields.push(label);
    } else {
      deniedMedicalHistoryFields.push(label);
    }
  });

  let text = "";

  const addText = (addedText?: string, newLine?: boolean) => {
    text += `${newLine ? "\n" : ""}${addedText}\n`;
  };

  addText("SOCIAL HX", true);
  socialHistoryFields.forEach(field => {
    addText(
      `${field.label ?? convertNameToTitle(field.name)}: ${
        data[field.name] ?? "None"
      }`
    );
  });

  addText("MEDICAL HX", true);
  addText(`Confirmed history of: ${confirmedMedicalHistoryFields.join(", ")}`);
  addText(`No known history of: ${deniedMedicalHistoryFields.join(", ")}`);
  if (!data.previousSurgicalProcedures) {
    addText(`Previous surgical procedures: None`);
  }
  if (data.previousSurgicalProcedures) {
    if (
      data.previousSurgicalProceduresDetails &&
      data.previousSurgicalProceduresDetails.length > 0
    ) {
      addText(`Previous surgical procedures details:`);
      data.previousSurgicalProceduresDetails.forEach((field, index) => {
        addText(
          `${index + 1}. ${field.procedure}, ${convertDateToHumanReadable(
            field.date
          )}`
        );
      });
    } else {
      addText(`Previous surgical procedures: Details Unknown`);
    }
  }
  addText("MEDICATIONS", true);
  if (!data.hasPreviousPrescriptions) {
    addText(`Previous medications: None`);
  }
  if (data.hasPreviousPrescriptions) {
    if (
      data.hasPreviousPrescriptionsDetails &&
      data.hasPreviousPrescriptionsDetails.length > 0
    ) {
      addText(`Previous medications:`);
      data.hasPreviousPrescriptionsDetails.forEach((field, index) => {
        addText(
          `${index + 1}. ${field.medication}, ${
            field.duration
          }, effectiveness: ${field.effectiveness}, side effects: ${
            field.sideEffects
          }`
        );
      });
    } else {
      addText(`Previous medications: Details Unknown`);
    }
  }
  if (!data.hasCurrentPrescriptions) {
    addText(`Current medications: None`);
  }
  if (data.hasCurrentPrescriptions) {
    if (
      data.hasCurrentPrescriptionsDetails &&
      data.hasCurrentPrescriptionsDetails.length > 0
    ) {
      addText(`Current medications:`);
      data.hasCurrentPrescriptionsDetails.forEach((field, index) => {
        addText(
          `${index + 1}. ${field.medication}, ${field.dose}, ${field.frequency}`
        );
      });
    } else {
      addText(`Current medications: Details Unknown`);
    }
  }

  addText("SUBSTANCE HX", true);
  substanceUseFields.forEach(field => {
    addText(
      `${field.label ?? convertNameToTitle(field.name)}: ${
        data[field.name] ?? "None"
      }`
    );
  });

  addText("TRAUMA HX", true);
  addText(
    `Experienced Trauma: ${convertToHumanReadableAnswer(
      data.experiencedTrauma
    )}`
  );
  addText(
    `Has PTSD Symptoms: ${
      data.preferNotToAnswerPtsdSymptoms === true
        ? "Prefer not to answer"
        : data.noPtsdSymptoms === undefined
        ? "Not Answered"
        : convertBooleanToYesNo(!data.noPtsdSymptoms)
    }`
  );
  if (showPtsdDifficulties) {
    ptsdDifficulties.forEach(field => {
      addText(
        `${
          field.label ?? convertNameToTitle(field.name)
        }: ${convertBooleanToYesNo(data[field.name])}`
      );
    });
  }
  if (showFurtherPtsdQuestions) {
    addText(
      `Previously supported with trauma based counselling: ${convertToHumanReadableAnswer(
        data.previouslySupportedWithTraumaBasedCounseling
      )}`
    );
    addText(
      `Seeking trauma focused therapy from Cognito: ${convertToHumanReadableAnswer(
        data.seekingTraumaFocusedTherapyFromCognito
      )}`
    );
    if (data.seekingTraumaFocusedTherapyFromCognito === true) {
      addText(
        `Seeking trauma focused therapy from Cognito details: ${convertToHumanReadableAnswer(
          data.seekingTraumaFocusedTherapyFromCognitoDetails
        )}`
      );
    }
    addText(
      `Seeking trauma focused therapy outside of Cognito: ${convertToHumanReadableAnswer(
        data.seekingOrEngagedInTraumaFocusedTherapyOutsideOfCognito
      )}`
    );
    if (data.seekingOrEngagedInTraumaFocusedTherapyOutsideOfCognito === true) {
      addText(
        `Seeking trauma focused therapy outside of Cognito details: ${convertToHumanReadableAnswer(
          data.seekingOrEngagedInTraumaFocusedTherapyOutsideOfCognitoDetails
        )}`
      );
    }
    addText(
      `Concerns trauma may impact ability to work with CBT provider: ${convertToHumanReadableAnswer(
        data.traumaConcerns
      )}`
    );
    if (data.traumaConcerns === true) {
      addText(
        `Concerns trauma may impact ability to work with CBT provider details: ${convertToHumanReadableAnswer(
          data.traumaConcernsDetails
        )}`
      );
    }
  }

  addText("HOSPITALIZATION / ER", true);
  addText(
    `Has gone to ER due to Mental Health: ${convertBooleanToYesNo(
      data.goneToEmergencyDueToMentalHealth
    )}`
  );
  if (data.goneToEmergencyDueToMentalHealthDetails) {
    addText(
      `Has gone to ER due to mental health details: ${data.goneToEmergencyDueToMentalHealthDetails}`
    );
  }

  addText("ALLERGIES", true);
  if (!data.hasAllergies) {
    addText(`None`);
  }
  if (data.hasAllergies) {
    if (
      data.hasAllergiesDetails &&
      typeof data.hasAllergiesDetails === "string"
    ) {
      addText(
        `${
          data.hasAllergies
            ? convertToHumanReadableAnswer(data.hasAllergiesDetails)
            : "None"
        }`
      );
    }
    if (
      data.hasAllergiesDetails &&
      data.hasAllergiesDetails.length > 0 &&
      typeof data.hasAllergiesDetails !== "string"
    ) {
      addText(`Allergies:`);
      data.hasAllergiesDetails.forEach((field, index) => {
        addText(
          `${index + 1}. ${field.allergen}, reaction: ${
            field.reaction
          }, severity: ${field.severity}`
        );
      });
    } else {
      addText(`Allergies: Details Unknown`);
    }
  }

  addText("FAMILY HX", true);
  if (!data.familyMemberDiagnosedWithCondition) {
    addText(`No family members diagnosed with conditions`);
  }
  if (data.familyMemberDiagnosedWithCondition) {
    if (
      data.familyMemberDiagnosedWithConditionDetails &&
      data.familyMemberDiagnosedWithConditionDetails.length > 0
    ) {
      addText(`Family members diagnoses details:`);
      data.familyMemberDiagnosedWithConditionDetails.forEach((field, index) => {
        addText(`${index + 1}. ${field.familyMember}, ${field.diagnosis}`);
      });
    } else {
      addText(`Family members diagnoses details: Unknown`);
    }
  }
  return text;
};
