import {
  Pagination as ReactAdminPagination,
  PaginationProps as ReactAdminPaginationProps,
} from "react-admin";

export function Pagination(props: ReactAdminPaginationProps) {
  return (
    <ReactAdminPagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
  );
}
