import {
  useAuth0,
  withAuth0,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import { datadogLogs } from "@datadog/browser-logs";
import BlockIcon from "@material-ui/icons/Block";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import Assessment from "@mui/icons-material/Assessment";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import FeedbackIcon from "@mui/icons-material/Feedback";
import PersonIcon from "@mui/icons-material/Person";
import PostAdd from "@mui/icons-material/PostAdd";
import Security from "@mui/icons-material/Security";
import { CondOperator } from "@nestjsx/crud-request";
import axios, { AxiosRequestHeaders } from "axios";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLDClient } from "launchdarkly-react-client-sdk";
import crudProvider from "ra-data-nestjsx-crud";
import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import {
  Admin,
  AuthProvider,
  CustomRoutes,
  Resource,
  UserIdentity,
} from "react-admin";
import { Route } from "react-router";
import ClientIntake from "./ClientIntake";
import Dashboard from "./Dashboard";
import CustomLayout from "../components/CustomLayout";
import theme from "../components/theme";
import {
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_BASE_URL,
  REACT_APP_THIRD_PARTY_ADMIN,
  getUserListUrl,
  useHttpClient,
} from "../utils";
import { checkForRole, getRoles } from "../utils/checkRoles";
import { doesTokenHaveRole } from "../utils/jwtUtils";
import { initializeDatadogLogging } from "../utils/logging";
import { AddResults } from "../views/AddResults/AddResults";
import { AdhdInfo } from "../views/AdhdInfo/AdhdInfo";
import { CalendlyList, CalendlyShow } from "../views/calendly";
import { Configuration } from "../views/configuration";
import { FailedReferralReasonsList } from "../views/failedReferralReasons/failedReferralReasonsList";
import { FeedbackList } from "../views/feedback";
import { IneligibleRecord } from "../views/IneligibleRecords/IneligibleRecord";
import { IneligibleRecordsList } from "../views/IneligibleRecords/IneligibleRecordList";
import {
  LoginActivitiesList,
  LoginActivitiesShow,
} from "../views/loginActivities";
import { MessageCreate } from "../views/Messages/MessageCreate";
import { MessageShow } from "../views/Messages/MessageShow";
import {
  PrescriptionCreate,
  PrescriptionEdit,
  PrescriptionShow,
} from "../views/prescriptions";
import { Progress } from "../views/progress/Progress";
import { RefillCreate, RefillEdit } from "../views/refills";
import { ReportingDashboard } from "../views/ReportingDashboard/ReportingDashboard";
import { SatisfactionSurveyResults } from "../views/SatisfactionSurveyResults/SatisfactionSurveyResults";
import { SurveyResponse } from "../views/SurveyResponse/SurveyResponse";
import { UserEdit, UserShow, UsersList } from "../views/users";

initializeDatadogLogging();

const getResources = permissions => {
  const isPharmacist = checkForRole("pharmacist", permissions);
  let isThirdParty = false;
  let isTeamLead = false;
  if (REACT_APP_THIRD_PARTY_ADMIN) {
    isThirdParty = checkForRole(REACT_APP_THIRD_PARTY_ADMIN, permissions);
  }
  isTeamLead = checkForRole("teamLead", permissions);

  if (isThirdParty) {
    return [];
  }

  let resources = [
    <CustomRoutes>
      <Route path="/configuration" element={<Configuration />} />
    </CustomRoutes>,
    <Resource
      name="users"
      list={UsersList}
      show={UserShow}
      edit={isTeamLead ? UserEdit : undefined}
      // create={UserCreate}
      icon={PersonIcon}
    />,
    <Resource
      name="prescriptions"
      options={{ label: "Prescriptions" }}
      create={PrescriptionCreate}
      show={PrescriptionShow}
      edit={PrescriptionEdit}
    />,
    <Resource
      name="refills"
      options={{ label: "Refills" }}
      create={RefillCreate}
      edit={RefillEdit}
    />,
  ];

  if (!isPharmacist) {
    resources = [
      ...resources,
      <Resource
        name="calendly"
        options={{ label: "Bookings" }}
        list={CalendlyList}
        show={CalendlyShow}
        // TODO: Bring back once we have an idea who can edit/create events
        // edit={CalendlyEdit}
        // create={CalendlyCreate}
        icon={CalendarMonth}
      />,
      <Resource name="adhdInfo" show={AdhdInfo} />,
      <Resource name="progress" show={Progress} />,
      <Resource
        name="admin/messages"
        show={MessageShow}
        create={MessageCreate}
        options={{ label: "Messages" }}
      />,
      <Resource name="survey_response" show={SurveyResponse} />,
      <Resource
        name="add-results"
        options={{ label: "Add GAD7/PHQ9 Results" }}
        list={AddResults}
        icon={PostAdd}
      />,
    ];
  }

  if (isTeamLead) {
    resources = [
      ...resources,
      <Resource
        name="feedback"
        options={{ label: "Feedback" }}
        list={FeedbackList}
        icon={FeedbackIcon}
      />,

      <Resource
        name="login-activity"
        options={{ label: "Login Activity" }}
        list={LoginActivitiesList}
        show={LoginActivitiesShow}
        icon={Security}
      />,
      <Resource
        name="reporting-dashboard"
        options={{ label: "Reporting Dashboard" }}
        list={ReportingDashboard}
        icon={Assessment}
      />,
      <Resource
        name="satisfaction-survey"
        options={{ label: "Satisfaction Survey Info" }}
        list={SatisfactionSurveyResults}
        icon={SentimentSatisfiedAltIcon}
      />,
      <Resource
        name="failed_referral_reasons"
        options={{ label: "Failed Referral Reasons" }}
        list={FailedReferralReasonsList}
      />,
      <Resource
        name="admin/ineligible-records"
        options={{ label: "Ineligible Records" }}
        list={IneligibleRecordsList}
        icon={BlockIcon}
        edit={IneligibleRecord}
      />,
    ];
  }
  return resources;
};

const App: FunctionComponent = () => {
  const { httpClient } = useHttpClient();
  const [isThirdParty, setIsThirdParty] = useState(false);
  const sentRequestRef = useRef(false);
  const { appDownForMaintenance } = useFlags();

  const {
    isAuthenticated,
    user: authUser,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();
  const ldClient = useLDClient();

  const dataProvider = useMemo(
    () => crudProvider(`${REACT_APP_BASE_URL}/api`, httpClient),
    [httpClient]
  );

  useEffect(() => {
    const checkPermissions = async () => {
      const accessToken = await getAccessTokenSilently();
      const roles = getRoles(accessToken);
      if (REACT_APP_THIRD_PARTY_ADMIN) {
        setIsThirdParty(checkForRole(REACT_APP_THIRD_PARTY_ADMIN, roles));
      }
    };

    /**
     * Create a new Medical Staff member. No action if medical staff member
     * already exists.
     */
    const createMedicalStaff = async () => {
      // For some unknown reason when the app loads the first time, the request
      // is sent twice. This prevents this. OK taking on this debt as we will
      // eventually be revamping the admin portal.
      if (sentRequestRef.current) {
        return;
      }
      sentRequestRef.current = true;
      const accessToken = await getAccessTokenSilently();

      if (doesTokenHaveRole(accessToken, "admin")) {
        // Only create medical staff if the user is an admin
        // Add content-type and Authorization
        const headers: AxiosRequestHeaders = {
          "content-type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        };
        const url = `${REACT_APP_AUTH0_AUDIENCE}/admin/medical-staff/me`;
        await axios.request({
          method: "POST",
          url,
          headers,
        });
      }
    };

    checkPermissions();
    createMedicalStaff();
  }, []);

  useEffect(() => {
    if (isAuthenticated && authUser?.sub) {
      datadogLogs.setUser({ id: authUser.sub });
      ldClient?.identify({ key: authUser.sub });
    } else {
      datadogLogs.clearUser();
      ldClient?.identify({ key: "" });
    }
  }, [isAuthenticated, authUser]);

  function trimFilter(filter: Record<string, any>) {
    for (const key in filter) {
      const value = filter[key];
      if (typeof value === "string") {
        filter[key] = value.trim();
      } else if (typeof value === "object") {
        filter[key] = trimFilter(value);
      } else {
        filter[key] = value;
      }
    }
    return filter;
  }

  // mutate the filter to use the correct operators for the backend
  function mutateFilter(filter) {
    const operators = {
      _gte: CondOperator.GREATER_THAN_EQUALS,
      _lte: CondOperator.LOWER_THAN_EQUALS,
      _neq: CondOperator.NOT_EQUALS,
    };
    const filters = Object.create(null);
    for (const key of Object.keys(filter)) {
      const operator = operators[key.slice(-4)];
      if (operator) {
        // new key is the same as the old key, but with the desired operator concatenated after || (the default deliminator token)
        const newKey = key.slice(0, -4).concat("||".concat(operator));
        filters[newKey] = filter[key];
      } else {
        filters[key] = filter[key];
      }
    }
    return filters;
  }

  async function getList(resource, params) {
    params.filter = trimFilter(params.filter);

    if (resource !== "users") {
      params.filter = mutateFilter(params.filter);
      return dataProvider.getList(resource, params);
    }

    // Only show results if the user has started searching for clients
    const paramFilterKeys = Object.keys(params.filter);
    if (paramFilterKeys.length === 0) {
      return { data: [], total: 0 };
    }
    const userListUrl = getUserListUrl(params);
    const { json } = await httpClient(userListUrl);
    const users = json.data;

    return {
      data: users,
      total: json.total,
    };
  }

  const customDataProvider = useMemo(
    () => ({
      ...dataProvider,
      getList: getList,
    }),
    []
  );

  const authProvider: AuthProvider = useMemo(
    () => ({
      login: () => loginWithRedirect(),
      checkError: ({ status }) => {
        if (status === 401 || status === 403) {
          return Promise.reject();
        }
        return Promise.resolve();
      },
      checkAuth: () => (isAuthenticated ? Promise.resolve() : Promise.reject()),
      logout: async () => logout({ returnTo: window.location.origin }),
      getIdentity: (): Promise<UserIdentity> =>
        authUser
          ? Promise.resolve({
              id: authUser.sub,
              fullName: authUser.name,
              avatar: authUser.picture?.toString(),
            } as UserIdentity)
          : Promise.reject(),
      getPermissions: async () => {
        const tok = await getAccessTokenSilently();
        const roles = getRoles(tok);
        return roles;
      },
    }),
    [isAuthenticated, authUser, loginWithRedirect, logout]
  );

  if (appDownForMaintenance) {
    return <div>App is down for maintenance</div>;
  } else {
    return (
      <Admin
        dashboard={isThirdParty ? ClientIntake : Dashboard}
        dataProvider={customDataProvider}
        authProvider={authProvider}
        layout={CustomLayout}
        theme={theme}
      >
        {getResources}
      </Admin>
    );
  }
};

export default withAuthenticationRequired(withAuth0(App));
