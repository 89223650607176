import { Box } from "@material-ui/core";
import moment from "moment";
import React from "react";

export const convertNameToTitle = (name: string) => {
  const result = name.replace(/([A-Z])/g, " $1");
  const capitalized = `${
    result[0]?.toLocaleUpperCase() ?? ""
  }${result.substring(1)}`;
  return capitalized;
};

export const DataContainer: React.FC<{
  title: string;
  data: string | undefined | string[] | number | boolean | object;
  alwaysShowValue?: boolean;
  highlight?: boolean;
}> = ({ alwaysShowValue, data, highlight, title }) => {
  const displayValue = (
    data: string | undefined | string[] | number | boolean | object,
    wasArray?: boolean
  ) => {
    const isArray = Array.isArray(data);
    const isObject = data !== null && typeof data === "object";
    const dateAttempt = moment(data as any, "YYYY-MM-DD", true);

    if (isArray) {
      return (
        <Box>
          {data.map((element, index) => (
            <Box key={index}>
              {displayValue(element, true)}
              {index < data.length - 1 && <br />}{" "}
            </Box>
          ))}
        </Box>
      );
    }

    if (isObject) {
      if (wasArray) {
        return (
          <Box>
            {Object.entries(data).map(([key, value], index, array) => (
              <Box key={key}>
                <span style={{ fontWeight: "bold" }}>{`${convertNameToTitle(
                  key
                )}: `}</span>{" "}
                {value?.endsWith("000Z")
                  ? moment(new Date(value)).format("MMMM Do YYYY")
                  : value}
                {index < array.length - 1 && <br />}{" "}
              </Box>
            ))}
          </Box>
        );
      }

      return (
        <Box display="flex" alignItems="end" flexDirection={"row"}>
          {Object.keys(data).map(key => {
            return (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="end"
                key={key}
              >
                <Box style={{ fontWeight: "bold", marginRight: "0.25rem" }}>
                  {`${convertNameToTitle(key)}:`}
                </Box>
                <Box style={{ marginRight: "0.25rem" }}>
                  {displayValue(data[key])}
                </Box>
              </Box>
            );
          })}
        </Box>
      );
    }

    if (dateAttempt.isValid()) {
      return <Box>{dateAttempt.format("MMMM Do YYYY")}</Box>;
    }

    let convertedValue;
    if (data === true) {
      convertedValue = "Yes";
    }
    if (data === false) {
      convertedValue = "No";
    }
    return `${convertedValue ?? data?.toString() ?? "null"}`;
  };
  const titleToUse = data === true && !alwaysShowValue ? title : `${title}: `;
  return (
    <div style={{ color: highlight ? "red" : undefined }}>
      {title && <span style={{ fontWeight: "bold" }}>{titleToUse}</span>}
      {(data !== true || alwaysShowValue) && displayValue(data)}
    </div>
  );
};
