import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { reasonForViewing, relationshipOptions } from "./UsersRelationships";

export interface ViewingDetails {
  relationship: string;
  reason: string;
  reasonDescription?: string;
}

interface UserAccessDialogProps {
  selectedId: string;
  onCloseDialog: (viewingDetails?: ViewingDetails) => void;
}

export const UserAccessDialog: React.FC<UserAccessDialogProps> = ({
  onCloseDialog,
  selectedId,
}) => {
  const [viewingDetails, setViewingDetails] = useState<ViewingDetails>({
    relationship: "",
    reason: "",
  });

  const handleCloseDialog = (viewingDetails?: ViewingDetails) => {
    setViewingDetails({ relationship: "", reason: "" });
    onCloseDialog(viewingDetails);
  };

  const disableContinueButton = () => {
    if (!viewingDetails.reason || !viewingDetails.relationship) {
      return true;
    }
    if (
      viewingDetails.reason === "Other" &&
      !viewingDetails.reasonDescription
    ) {
      return true;
    }
    return false;
  };

  return (
    <Dialog open={!!selectedId} onClose={() => handleCloseDialog()}>
      <DialogTitle>Why do you need this data?</DialogTitle>
      <DialogContent>
        <FormLabel component="legend">Relationship to client</FormLabel>
        <Select
          fullWidth
          value={viewingDetails.relationship}
          onChange={e =>
            setViewingDetails({
              ...viewingDetails,
              relationship: e.target.value,
            })
          }
        >
          {relationshipOptions.map(reason => (
            <MenuItem key={reason.value} value={reason.value}>
              {reason.label}
            </MenuItem>
          ))}
        </Select>
        <FormLabel component="legend">Reason for accessing data</FormLabel>
        <Select
          fullWidth
          onChange={e =>
            setViewingDetails({
              ...viewingDetails,
              reason: e.target.value,
            })
          }
          value={viewingDetails.reason}
        >
          {reasonForViewing.map(reason => (
            <MenuItem key={reason.value} value={reason.value}>
              {reason.label}
            </MenuItem>
          ))}
        </Select>

        {viewingDetails.reason === "Other" && (
          <FormControl fullWidth>
            <FormLabel>Please Describe Your Reason (256 characters)</FormLabel>
            <TextField
              autoFocus
              rows={5}
              inputProps={{ maxLength: 256 }}
              multiline
              onChange={e =>
                setViewingDetails({
                  ...viewingDetails,
                  reasonDescription: e.target.value,
                })
              }
            />
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCloseDialog()}>Cancel</Button>
        <Button
          disabled={disableContinueButton()}
          onClick={() => handleCloseDialog(viewingDetails)}
        >
          View Client
        </Button>
      </DialogActions>
    </Dialog>
  );
};
