export function getMissingVariables(
  dictionary: { [key: string]: string },
  text: string
) {
  const mustacheVariables = getDistinctMustacheVariables(text);
  const missingVariables: string[] = [];

  for (const mustacheVariable of mustacheVariables) {
    if (!dictionary[mustacheVariable]) {
      missingVariables.push(mustacheVariable);
    }
  }
  return missingVariables;
}

export function getDistinctMustacheVariables(text: string) {
  const MUSTACHE_REPLACEMENT_TERMS = /{{(.*?)}}/g; // {{variable}}
  const replacementTerms = text.match(MUSTACHE_REPLACEMENT_TERMS);
  return Array.from(new Set(replacementTerms));
}

export function doReplacements(
  text: string,
  replacements: { [key: string]: string }
) {
  let updatedText = text;
  for (const [key, value] of Object.entries(replacements)) {
    const regex = new RegExp(key, "g");
    updatedText = updatedText.replace(regex, value);
  }
  return updatedText;
}
