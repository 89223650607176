import { FileRejection } from "react-dropzone";

export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB
export const MAX_FILES = 10;
export const SUPPORTED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "image/heic",
  "image/heif",
];

export const handleFileRejections = (
  fileRejections: FileRejection[],
  notify
) => {
  // just notify the user of the first error
  const file = fileRejections[0].file;
  const error = fileRejections[0].errors[0];

  if (error.code === "file-too-large") {
    const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
    notify(
      `${file.name}: File size (${fileSizeMB} MB) exceeds the maximum allowed size of 10 MB.`,
      {
        type: "error",
      }
    );
  } else if (error.code === "too-many-files") {
    notify(`Too many files: A maximum of 10 are allowed.`, {
      type: "error",
    });
  } else {
    notify(`${error.message}`, {
      type: "error",
    });
  }
};
