import { Box } from "@material-ui/core";
import {
  Document as PDFDocument,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import React from "react";
import { Button } from "react-admin";
import { SheehanPage } from "./SheehanPage";
import { SheehanDisabilityScaleForm } from "./types";
import { convertDateToHumanReadable } from "../../progress/utils";

export const SimpleSheehanDocument: React.FC<{
  data: SheehanDisabilityScaleForm;
  clientName: string;
  hideCallback: () => void;
}> = ({ data, clientName, hideCallback }) => {
  const Document = () => (
    <PDFDocument>
      <SheehanPage data={data} clientName={clientName} />
    </PDFDocument>
  );

  return (
    <Box>
      <Box display="flex" flexDirection={"row"} justifyContent="center">
        <Box flex={1}>
          <PDFDownloadLink
            document={<Document />}
            fileName={`${clientName} - Sheehan Disability Scale - ${convertDateToHumanReadable(
              data?.createdDate
            )}`}
          >
            {({ loading }) =>
              loading ? "Loading document..." : "Download now"
            }
          </PDFDownloadLink>
          <Button label="Hide Result" onClick={hideCallback} />
        </Box>
      </Box>
      <Box sx={{ height: "80vh" }}>
        <PDFViewer showToolbar={false} height="100%" width="100%">
          <Document />
        </PDFViewer>
      </Box>
    </Box>
  );
};
