import { WeissSymptomRecordIIForm } from "./weissSymptomRecordII/types";
import { WfirsForm } from "./wfirs/types";

export const flagSection = (
  data: WfirsForm | WeissSymptomRecordIIForm,
  questions: { field: string }[],
  sectionTitle: string,
  isWFIRS?: boolean
) => {
  let numberOfSevere = 0;
  let numberOfModerate = 0;

  questions.forEach(question => {
    if (data[question.field] === 2) {
      numberOfModerate++;
    }
    if (data[question.field] === 3) {
      numberOfSevere++;
    }
  });
  if (numberOfModerate > 2 || numberOfSevere > 0) {
    return `${sectionTitle}: ${numberOfModerate} ${
      isWFIRS ? "Often or much" : "Moderate"
    }, ${numberOfSevere} ${isWFIRS ? "Very often or very much" : "Severe"}`;
  }
};
