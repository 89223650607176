import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { RaRecord, useRecordContext } from "react-admin";

interface ConfirmationDialogProps {
  content: React.ReactNode | ((record: RaRecord) => React.ReactNode);
  open: boolean;
  onCloseDialog: (confirmed: boolean) => void;
  disableCancel?: boolean;
  disabledOk?: boolean;
}

/** If placed within a Form, will have access to the record. */
export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  content,
  disableCancel,
  disabledOk,
  onCloseDialog,
  open,
}) => {
  const record = useRecordContext();

  const renderContent = () => {
    if (typeof content === "function") {
      return content(record);
    }
    return content;
  };

  return (
    <Dialog open={open} onClose={() => onCloseDialog(false)}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <Button disabled={disableCancel} onClick={() => onCloseDialog(false)}>
          Cancel
        </Button>
        <Button disabled={disabledOk} onClick={() => onCloseDialog(true)}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
