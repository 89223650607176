import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DateInput, NumberInput, SimpleForm } from "react-admin";
import Select from "react-select";
import { REACT_APP_AUTH0_AUDIENCE, useHttpClient } from "../../utils";

type RadioValue = "phq9" | "gad7" | "both";

export const AddResults = () => {
  const { httpClient } = useHttpClient();
  const [activeUsers, setActiveUsers] = useState<
    { label: string; value: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedClientId, setSelectedClientId] = useState<{
    value: string;
    label: string;
  }>({ value: "", label: "" });
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [radioValue, setRadioValue] = useState<RadioValue>("both");
  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setInitiated(true);
      let users: any[] = [];
      let page = 1;
      let shouldPageinate = true;
      while (shouldPageinate) {
        const result = await httpClient(
          `${REACT_APP_AUTH0_AUDIENCE}/users?limit=100&page=${page}`
        );
        users.push(...result.json.data);
        page++;
        if (page > result.json.pageCount) {
          shouldPageinate = false;
        }
      }

      const activeCustomerIds = JSON.parse(
        (await httpClient(`${REACT_APP_AUTH0_AUDIENCE}/all_active_customers`))
          .body
      );

      users = users.filter(({ stripeCustomerId }) => {
        return activeCustomerIds.includes(stripeCustomerId);
      });

      const userMetadata = users
        .map(user => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName} - ${user.email}`,
        }))
        .sort((a, b) =>
          a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? -1 : 1
        );

      setActiveUsers(userMetadata);

      setIsLoading(false);
    };
    if (!initiated) {
      fetchData();
    }
  }, []);

  const handleSubmission = async (values: any) => {
    if (radioValue !== "phq9") {
      try {
        await httpClient(
          `${REACT_APP_AUTH0_AUDIENCE}/anxiety_forms/${selectedClientId.value}`,
          {
            method: "POST",
            body: JSON.stringify({
              createdDate: values.dateCompleted,
              totalSum: values.gad7TotalSum,
            }),
          }
        );
      } catch {
        setShowErrorToast(true);
        return;
      }
    }
    if (radioValue !== "gad7") {
      try {
        await httpClient(
          `${REACT_APP_AUTH0_AUDIENCE}/depression_forms/${selectedClientId.value}`,
          {
            method: "POST",
            body: JSON.stringify({
              createdDate: values.dateCompleted,
              totalSum: values.phq9TotalSum,
            }),
          }
        );
      } catch {
        setShowErrorToast(true);
        return;
      }
    }
    setShowSuccessToast(true);
  };

  return (
    <Box>
      {isLoading ? (
        <Backdrop open>
          <Box display={"flex"} flexDirection="column" alignItems={"center"}>
            <CircularProgress color="inherit" />
            <Box marginTop={"1rem"}>Loading... This may take a minute</Box>
          </Box>
        </Backdrop>
      ) : (
        <>
          <Box margin={"1rem 0 0 1rem"}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Which forms do you want to add results for?
              </FormLabel>
              <RadioGroup
                aria-label="result-types"
                value={radioValue}
                onChange={e => setRadioValue(e.target.value as RadioValue)}
                style={{ flexDirection: "row" }}
              >
                <FormControlLabel
                  value="phq9"
                  control={<Radio />}
                  label="Just PHQ9"
                />
                <FormControlLabel
                  value="gad7"
                  control={<Radio />}
                  label="Just GAD7"
                />
                <FormControlLabel
                  value="both"
                  control={<Radio />}
                  label="Both PHQ9 and GAD7"
                />
              </RadioGroup>
            </FormControl>
            <FormLabel component="legend">Client</FormLabel>
            <Select
              styles={{
                menu: base => ({
                  ...base,
                  zIndex: 2,
                }),
              }}
              options={activeUsers}
              onChange={e => setSelectedClientId(e!)}
            />
          </Box>
          <SimpleForm onSubmit={handleSubmission}>
            {radioValue !== "phq9" && (
              <NumberInput
                disabled={!selectedClientId.value}
                max={21}
                min={0}
                source="gad7TotalSum"
                label="GAD7 Total Score"
                required
              />
            )}
            {radioValue !== "gad7" && (
              <NumberInput
                disabled={!selectedClientId.value}
                max={27}
                min={0}
                source="phq9TotalSum"
                label="PHQ9 Total Score"
                required
              />
            )}
            <DateInput
              disabled={!selectedClientId.value}
              parse={value => {
                const date = new Date(value);
                return JSON.parse(
                  JSON.stringify(
                    new Date(
                      date.setTime(
                        date.getTime() + date.getTimezoneOffset() * 1000 * 60
                      )
                    )
                  )
                );
              }}
              required
              source="dateCompleted"
            />
          </SimpleForm>
        </>
      )}
      <Snackbar
        open={showSuccessToast || showErrorToast}
        autoHideDuration={5000}
        onClose={() => {
          setShowSuccessToast(false);
          setShowErrorToast(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowSuccessToast(false);
            setShowErrorToast(false);
          }}
          severity={showErrorToast ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {showSuccessToast
            ? `Successfully added ${radioValue.toLocaleUpperCase()} results for ${
                selectedClientId.label
              }`
            : "Something has gone wrong and the results most likely were not added. Please contact your administrator for further instructions."}
        </Alert>
      </Snackbar>
    </Box>
  );
};
