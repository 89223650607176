import { Box, Link, Typography } from "@mui/material";
import React from "react";

interface ClientIntakeErrorMessageProps {
  errorMessage: string;
  httpErrorMessage: string;
  showTraumaExplanation: boolean;
  showClientNotInformedError: boolean;
}

export const ClientIntakeErrorMessage: React.FC<
  ClientIntakeErrorMessageProps
> = ({
  errorMessage,
  httpErrorMessage,
  showClientNotInformedError,
  showTraumaExplanation,
}) => {
  const renderIssues = () => {
    if (!errorMessage) {
      return null;
    }
    return (
      <>
        Based on the eligibility screening process, you have identified the
        following: <span style={{ fontWeight: "bold" }}>{errorMessage}.</span>{" "}
      </>
    );
  };

  const genericError = (
    <Typography
      sx={{ backgroundColor: "rgba(204,0,0,0.6)" }}
      textAlign={"center"}
      variant="h5"
      width="100%"
      fontWeight={"bold"}
      padding="1rem"
    >
      CLIENT REFERRAL UNSUCCESSFUL
    </Typography>
  );

  if (httpErrorMessage) {
    return (
      <Box>
        {genericError}
        <Typography variant="h5" margin="1rem 1rem 1rem 0">
          {`Error: ${httpErrorMessage}.`}
        </Typography>
        <Typography variant="h5" margin="1rem 1rem 1rem 0">
          Please clear your cache and try again. The following instructions are
          for the Chrome browser: To clear your cache, right click anywhere on
          the screen and select "Inspect". A window will open which you can
          ignore. Then, right click on the "Refresh Page" button in the top left
          corner next to the URL and select "Empty Cache and Hard Reload". The
          page will then reload.
        </Typography>
        <Typography variant="h5" margin="1rem 1rem 1rem 0">
          If this still does not work, please refresh the page and then right
          click anywhere on the screen and select "Inspect". The new window
          (which is called "DevTools") will open, please select the "Network"
          tab. Attempt to refer the client again, when you see this error page
          there will be a list of items in red in the "DevTools" window. Please
          make the "DevTools" window full screen. You can do this by clicking
          the 3 dot menu on the top right of the "DevTools" window and select
          the left most option next to "Dock Side". Please take a screenshot of
          the "DevTools" window. For any items in red please click on them and
          then select "Preview", please take another screenshot.
        </Typography>
        <Typography variant="h5" margin="1rem 1rem 1rem 0">
          Once the screenshots have been taken, please email them along with the
          error message above to our Lead Software Developer{" "}
          <Link href="mailto:conner.leverett@getcognito.ca">
            conner.leverett@getcognito.ca
          </Link>
          . This will help us figure out the issue and provide a smoother
          experience for you in the future. We apologize for this inconvenience
          and it is our highest priority to resolve the issue.
        </Typography>
      </Box>
    );
  }
  if (showClientNotInformedError) {
    return (
      <Box>
        {genericError}
        <Typography variant="h5" margin="1rem 1rem 1rem 0">
          Based on the eligibility screening process, you have identified the
          following:{" "}
          <Box component={"span"} fontWeight={"bold"}>
            The client has not been informed of the program
          </Box>
          . In order for Cognito to support the client and ensure their
          readiness for our program, it is important the client be informed
          about our program with clear treatment goals identified. Please reach
          out to the client and confirm their goals and obtain consent before
          returning to refer the client.
        </Typography>
      </Box>
    );
  }
  return (
    <Box>
      {genericError}
      <Typography variant="h5" margin="1rem 1rem 1rem 0">
        {renderIssues()}
        {showTraumaExplanation
          ? "Our program is designed to offer CBT for anxiety, depression or insomnia. At this time, we do not offer trauma focused CBT and clients with concerns related to trauma would be better supported with referral to a trauma focused therapy program. "
          : ""}
        The above criteria indicates the client is unlikely to benefit from the
        standard program at Cognito Health and thus we will be unable to accept
        this referral. If you would like to discuss this further, please email{" "}
        <Link href="mailto:danica@getcognito.ca">danica@getcognito.ca</Link> or{" "}
        <Link
          target="_blank"
          rel="noopener"
          href="https://calendly.com/danica-operations/island-health-information-call"
        >
          book a time to discuss further
        </Link>
        .
      </Typography>
    </Box>
  );
};
