import { IAdhdInfo } from "./UpdateAdhdStatus";

export enum DiagnosedBy {
  PSYCHIATRIST = "psychiatrist",
  ANYONE = "anyone",
}

export enum StepsKey {
  ELIGIBLE_FOR_PROGRAM_CHECK = "eligibleForProgramCheck",
  INELIGIBLE_FOR_PROGRAM = "ineligibleForProgram",
  EXTERNAL_DIAGNOSIS_VALIDITY_CHECK = "externalDiagnosisValidityCheck",
  EXTERNAL_DIAGNOSIS_VALID = "externalDiagnosisValid",
  INTERNAL_DIAGNOSIS_VALID = "internalDiagnosisValid",
  INTERNAL_DIAGNOSIS_INVALID = "internalDiagnosisInvalid",
  INTERNAL_DIAGNOSIS = "internalDiagnosisStep",
  WHO_SHOULD_DIAGNOSE_CLIENT = "whoShouldDiagnoseClient",
}

export interface Action {
  label?: string | ((info: IAdhdInfo) => string);
  data: IAdhdInfo | ((info: IAdhdInfo) => IAdhdInfo);
  nextStep: (info: IAdhdInfo) => StepsKey;
  toastMessage?: string;
  warningMessage?: string;
}

export interface IActionItem {
  title: string;
  type?: "discharge" | "confirmed";
  yesAction?: Action;
  noAction?: Action;
  editAction?: Action;
  otherButtons?: {
    data: IAdhdInfo;
    label: string;
    nextStep: () => StepsKey;
    disabled?: boolean;
  }[];
}

export const steps: Record<StepsKey, IActionItem> = {
  eligibleForProgramCheck: {
    title: "Is the client eligible for the ADHD program?",
    yesAction: {
      nextStep: (info: IAdhdInfo) => {
        const { externallyDiagnosedWithAdhd, externalAdhdDiagnosisValid } =
          info;
        if (
          !externallyDiagnosedWithAdhd ||
          externalAdhdDiagnosisValid === false
        ) {
          return StepsKey.WHO_SHOULD_DIAGNOSE_CLIENT;
        } else {
          return StepsKey.EXTERNAL_DIAGNOSIS_VALIDITY_CHECK;
        }
      },
      data: { eligibleForAdhdProgram: true },
      warningMessage:
        "By continuing an email will automatically be sent to the client with information about our ADHD program.",
      toastMessage:
        "An email has automatically been sent to the client with information about our ADHD program.",
    },
    noAction: {
      nextStep: () => StepsKey.INELIGIBLE_FOR_PROGRAM,
      data: { eligibleForAdhdProgram: false },
    },
  },
  ineligibleForProgram: {
    title: "Client is ineligible for ADHD program.",
    type: "discharge",
    editAction: {
      label: "Edit eligibility for program",
      data: { eligibleForAdhdProgram: null },
      nextStep: () => StepsKey.ELIGIBLE_FOR_PROGRAM_CHECK,
    },
  },
  externalDiagnosisValidityCheck: {
    title:
      "Is the previous diagnosis of ADHD valid? (Do not answer until confirmation received from NP or Psychiatrist)",
    noAction: {
      nextStep: () => StepsKey.WHO_SHOULD_DIAGNOSE_CLIENT,
      data: { externalAdhdDiagnosisValid: false },
    },
    yesAction: {
      nextStep: () => StepsKey.EXTERNAL_DIAGNOSIS_VALID,
      data: { externalAdhdDiagnosisValid: true },
      warningMessage:
        "By continuing, the client will automatically be emailed a list of external ADHD resources.",
      toastMessage:
        "The client has automatically been emailed a list of external ADHD resources.",
    },
    editAction: {
      label: "Edit eligibility for program",
      data: { eligibleForAdhdProgram: null },
      nextStep: () => StepsKey.ELIGIBLE_FOR_PROGRAM_CHECK,
    },
  },
  externalDiagnosisValid: {
    title: "ADHD diagnosis confirmed",
    type: "confirmed",
    editAction: {
      label: "Edit Diagnosis",
      data: {
        externalAdhdDiagnosisValid: null,
      },
      nextStep: () => StepsKey.EXTERNAL_DIAGNOSIS_VALIDITY_CHECK,
    },
  },
  internalDiagnosisValid: {
    title: "ADHD diagnosis confirmed",
    type: "confirmed",
    editAction: {
      label: "Edit Diagnosis",
      data: {
        internallyDiagnosedWithAdhd: null,
      },
      nextStep: () => StepsKey.INTERNAL_DIAGNOSIS,
    },
  },
  internalDiagnosisInvalid: {
    title: "Alternate diagnosis provided (does not have ADHD)",
    type: "discharge",
    editAction: {
      label: "Edit Diagnosis",
      data: {
        internallyDiagnosedWithAdhd: null,
      },
      nextStep: () => StepsKey.INTERNAL_DIAGNOSIS,
    },
  },
  internalDiagnosisStep: {
    title:
      "Client is pending formal assessment. Once the assessment has been completed, has the client been diagnosed with ADHD at Cognito?",
    yesAction: {
      nextStep: () => StepsKey.INTERNAL_DIAGNOSIS_VALID,
      data: { internallyDiagnosedWithAdhd: true },
      warningMessage:
        "By continuing, the client will automatically be emailed a list of external ADHD resources.",
      toastMessage:
        "The client has automatically been emailed a list of external ADHD resources.",
    },
    noAction: {
      nextStep: () => StepsKey.INTERNAL_DIAGNOSIS_INVALID,
      data: { internallyDiagnosedWithAdhd: false },
      warningMessage:
        "By continuing, the client's subscription will automatically be changed to the `Therapy + Medication` plan. They will also be emailed with a summary that their symptoms are not indicative of ADHD.",
      toastMessage:
        "The client has automatically been emailed with a summary that their symptoms are not indicative of ADHD. Their subscription has also automatically been changed to the `Therapy + Medication` plan.",
    },
    editAction: {
      label: "Edit who the client should be assessed by",
      data: { toBeDiagnosedBy: null },
      nextStep: () => StepsKey.WHO_SHOULD_DIAGNOSE_CLIENT,
    },
  },
  whoShouldDiagnoseClient: {
    title: "Who should this client be assessed by?",
    otherButtons: [
      {
        label: "Nurse Practitioner",
        data: { toBeDiagnosedBy: DiagnosedBy.ANYONE },
        nextStep: () => StepsKey.INTERNAL_DIAGNOSIS,
      },
      {
        label: "Psychiatrist Only",
        data: { toBeDiagnosedBy: DiagnosedBy.PSYCHIATRIST },
        nextStep: () => StepsKey.INTERNAL_DIAGNOSIS,
        disabled: true,
      },
    ],
    editAction: {
      nextStep: (info: IAdhdInfo) => {
        if (info.externallyDiagnosedWithAdhd) {
          return StepsKey.EXTERNAL_DIAGNOSIS_VALIDITY_CHECK;
        } else {
          return StepsKey.ELIGIBLE_FOR_PROGRAM_CHECK;
        }
      },
      data: (info: IAdhdInfo) => {
        if (info.externalAdhdDiagnosisValid === false) {
          return { externalAdhdDiagnosisValid: null };
        } else {
          return { eligibleForAdhdProgram: null };
        }
      },
      label: (info: IAdhdInfo) => {
        if (info.externalAdhdDiagnosisValid === false) {
          return "Edit previous diagnosis validity";
        } else {
          return "Edit eligibility for program";
        }
      },
    },
  },
};
