import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { snapQuestions } from "./questions";
import { SnapForm } from "./types";
import { getDiagnosis } from "./utils";
import { AnswerLabels, QuestionAndAnswer, genericStyles } from "../PdfBuilder";

const styles = StyleSheet.create({
  diagnosis: { fontSize: "16px", fontStyle: "bold" },
  didNotComplete: { fontSize: "16px" },
});

export const SnapPage: React.FC<{
  data: SnapForm;
  clientName: string;
}> = ({ data, clientName }) => {
  return (
    <Page size="A4" style={genericStyles.page}>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>SNAP-IV 26</Text>
      </View>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>{clientName}</Text>
      </View>
      {data.completedByName && (
        <View style={{ display: "flex", alignItems: "center" }}>
          <Text>Completed by: {data.completedByName}</Text>
        </View>
      )}
      {data.relationshipToClient && (
        <View style={{ display: "flex", alignItems: "center" }}>
          <Text>Relationship to client: {data.relationshipToClient}</Text>
        </View>
      )}
      {data.relationshipLength && (
        <View style={{ display: "flex", alignItems: "center" }}>
          <Text>Length of relationship: {data.relationshipLength}</Text>
        </View>
      )}
      {data.cantCompleteReason && (
        <View>
          <View style={{ paddingTop: "20pt" }}>
            <Text style={genericStyles.sectionTitle}>Did Not Complete</Text>
            <Text
              style={styles.didNotComplete}
            >{`Reason: ${data.cantCompleteReason}`}</Text>
          </View>
        </View>
      )}
      {!data.cantCompleteReason &&
        snapQuestions.map(questionSet => (
          <View key={questionSet.section}>
            <View style={{ paddingTop: "20pt" }}>
              <Text style={genericStyles.sectionTitle}>
                {questionSet.title}
              </Text>
            </View>
            <AnswerLabels
              questions={[
                "Not at all",
                "Just a little",
                "Quite a bit",
                "Very Often",
              ]}
            />
            {questionSet.questions.map(question => (
              <View key={question.field}>
                <QuestionAndAnswer
                  question={question.question}
                  answer={data[question.field]!}
                  potentialScores={[0, 1, 2, 3]}
                />
              </View>
            ))}
            <View style={{ paddingTop: "10pt" }}>
              <Text style={styles.diagnosis}>
                {`${questionSet.title} - ${getDiagnosis(
                  data,
                  questionSet.questions,
                  questionSet.section
                )}`}
              </Text>
            </View>
          </View>
        ))}
    </Page>
  );
};
