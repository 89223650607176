import { WeissSymptomRecordIIFormFields } from "./types";

interface Question {
  question: string;
  field: WeissSymptomRecordIIFormFields;
}

export const attentionQuestions: Question[] = [
  {
    question: "Attention to details or makes careless mistakes",
    field: "makesCarelessMistakes",
  },
  {
    question: "Holding attention or remaining focused",
    field: "remainingFocused",
  },
  {
    question: "Listening or mind seems elsewhere",
    field: "mindSeemsElsewhere",
  },
  {
    question: "Instructions or finishing work",
    field: "instructionsOrFinishingWork",
  },
  {
    question: "Organizing (e.g. time, messy, deadlines)",
    field: "organizing",
  },
  {
    question: "Avoids or dislikes activities requiring effort",
    field: "dislikesDifficultActivities",
  },
  {
    question: "Loses or misplaces things",
    field: "losesOrMisplacesThings",
  },
  {
    question: "Easily distracted",
    field: "easilyDistracted",
  },
  {
    question: "Forgetful (e.g. chores, bills, appointments)",
    field: "forgetful",
  },
];

export const hyperActivityAndImpulsivityQuestions: Question[] = [
  {
    question: "Fidgets or squirms",
    field: "fidgetsOrSquirms",
  },
  {
    question: "Trouble staying seated",
    field: "troubleStayingSeated",
  },
  {
    question: "Runs about or feels restless inside",
    field: "feelsRestlessInside",
  },
  {
    question: "Loud or difficulty being quiet",
    field: "loud",
  },
  {
    question: "Often on the go",
    field: "oftenOnTheGo",
  },
  {
    question: "Talks too much",
    field: "talksTooMuch",
  },
  {
    question: "Blurts out comments",
    field: "blurtsOutComments",
  },
  {
    question: "Dislikes waiting (e.g. taking turns or in line)",
    field: "dislikesWaiting",
  },
  {
    question: "Interrupts or intrudes on others (e.g. butting in)",
    field: "interruptsOthers",
  },
];

export const oppositionalQuestions: Question[] = [
  {
    question: "Loses temper",
    field: "losesTemper",
  },
  {
    question: "Easily annoyed",
    field: "easilyAnnoyed",
  },
  {
    question: "Angry and resentful",
    field: "angryAndResentful",
  },
  {
    question: "Argues",
    field: "argues",
  },
  {
    question: "Defiant",
    field: "defiant",
  },
  {
    question: "Deliberately annoys other people",
    field: "deliberatelyAnnoysOtherPeople",
  },
  {
    question: "Blames other people rather than themselves",
    field: "blamesOthers",
  },
  {
    question: "Spiteful",
    field: "spiteful",
  },
];

export const developmentAndLearningQuestions: Question[] = [
  {
    question: "Wetting, (after age 5)",
    field: "wetting",
  },
  {
    question: "Soiling (after age 4)",
    field: "soiling",
  },
  {
    question: "Reading",
    field: "reading",
  },
  {
    question: "Spelling",
    field: "spelling",
  },
  {
    question: "Math",
    field: "math",
  },
  {
    question: "Writing",
    field: "writing",
  },
];

export const autismSpectrumQuestions: Question[] = [
  {
    question: "Difficulty with talking back and forth",
    field: "difficultyTalkingBackAndForth",
  },
  {
    question: "Unusual eye contact or body language",
    field: "unusualEyeContactOrBodyLanguage",
  },
  {
    question: "Speech is odd (monotone, unusual words)",
    field: "speechIsOdd",
  },
  {
    question: "Restricted, fixed, intense interests",
    field: "restrictedFixedOrIntenseInterests",
  },
  {
    question: "Odd, repetitive movements (e.g. flapping)",
    field: "oddRepetitiveMovements",
  },
  {
    question: `Does not easily "chit chat"`,
    field: "doesNotEasilyChitChat",
  },
];

export const motorDisordersQuestions: Question[] = [
  {
    question: "Repetitive noises (e.g. sniffing, throat clearing)",
    field: "repetitiveNoises",
  },
  {
    question: "Repetitive movements (blinking, shrugging)",
    field: "repetitiveMovements",
  },
  {
    question: "Clumsy",
    field: "clumsy",
  },
];

export const psychosisQuestions: Question[] = [
  {
    question: "Hearing voices that are not there",
    field: "hearingVoices",
  },
  {
    question: "Seeing things that are not there",
    field: "seeingThings",
  },
  {
    question: "Scrambled thinking",
    field: "scrambledThinking",
  },
  {
    question: "Paranoia (feeling people are against you)",
    field: "paranoia",
  },
];

export const depressionQuestions: Question[] = [
  {
    question: "Sad or depressed most of the day",
    field: "sadOrDepressedMostOfTheDay",
  },
  {
    question: "Lack of interest or pleasure most of the day",
    field: "lackOfInterestOrPleasureMostOfTheDay",
  },
  {
    question: "Weight loss, weight gain or change in appetite",
    field: "weightOrAppetiteChange",
  },
  {
    question: "Difficulty sleeping or sleeping too much",
    field: "difficultySleepingOrSleepingTooMuch",
  },
  {
    question: "Agitated",
    field: "agitated",
  },
  {
    question: "Slowed down",
    field: "slowedDown",
  },
  {
    question: "Feels worthless",
    field: "feelsWorthless",
  },
  {
    question: "Tired, no energy",
    field: "tired",
  },
  {
    question: "Hopeless, pessimistic",
    field: "hopeless",
  },
  {
    question: "Withdrawal from usual interests/people",
    field: "withdrawalFromUsualInterestsOrPeople",
  },
  {
    question: "Decrease in concentration",
    field: "decreaseInConcentration",
  },
];

export const moodRegulationQuestions: Question[] = [
  {
    question: "Distinct period(s) of intense excitement",
    field: "periodsOfIntenseExcitement",
  },
  {
    question: "Distinct period(s) of inflated self-esteem, grandiose",
    field: "periodsOfInflatedSelfEsteem",
  },
  {
    question: "Distinct period(s) of increased energy",
    field: "periodsOfIncreasedEnergy",
  },
  {
    question: "Distinct period(s) of decreased need for sleep",
    field: "periodsOfDecreasedNeedForSleep",
  },
  {
    question: "Distinct Period(s) of racing thoughts or speech",
    field: "periodsOfRacingThoughtsOrSpeech",
  },
  {
    question: "Irritable behaviour that is out of character",
    field: "outOfCharacterIrritableBehaviour",
  },
  {
    question: "Rage attacks, anger outbursts, hostility",
    field: "rageAttacksAngerOutburstsHostility",
  },
];

export const suicideQuestions: Question[] = [
  {
    question: "Suicidal thoughts",
    field: "suicidalThoughts",
  },
  {
    question: "Suicide attempt(s) or a plan",
    field: "suicidePlansOrAttempts",
  },
];

export const anxietyQuestions: Question[] = [
  {
    question: "Intense fears (e.g. heights, crowds, spiders)",
    field: "intenseFears",
  },
  {
    question: "Fear of social situations or performing",
    field: "fearOfSocialSituationsOrPerforming",
  },
  {
    question: "Panic attacks",
    field: "panicAttacks",
  },
  {
    question: "Fear of leaving e.g. the house, public transportation",
    field: "fearOfLeaving",
  },
  {
    question: "Worrying and/or anxious most days",
    field: "worryingOrAnxiousMostDays",
  },
  {
    question: "Nervous, can't relax",
    field: "nervous",
  },
  {
    question: "Obsessive thoughts (e.g. germs, perfectionism)",
    field: "obsessiveThoughts",
  },
  {
    question: "Compulsive rituals (e.g. checking, hand washing)",
    field: "compulsiveRituals",
  },
  {
    question: "Hair pulling, nail biting or skin picking",
    field: "hairPullingNailBitingSkinPicking",
  },
  {
    question: "Preoccupation with physical complaints",
    field: "preoccupationWithPhysicalComplaints",
  },
  {
    question: "Chronic pain",
    field: "chronicPain",
  },
];
export const stressRelatedQuestions: Question[] = [
  {
    question: "Physical abuse",
    field: "physicalAbuse",
  },
  {
    question: "Sexual abuse",
    field: "sexualAbuse",
  },
  {
    question: "Neglect",
    field: "neglect",
  },
  {
    question: "Other severe trauma",
    field: "otherSevereTrauma",
  },
];
export const ptsdQuestions: Question[] = [
  {
    question: "Flashbacks or nightmares",
    field: "flashbacksOrNightmares",
  },
  {
    question: "Avoidance",
    field: "avoidance",
  },
  {
    question: "Intrusive thoughts of traumatic events",
    field: "intrusiveThoughtsOfTraumaticEvents",
  },
];

export const sleepQuestions: Question[] = [
  {
    question: "Trouble falling asleep or staying asleep",
    field: "troubleFallingAsleepOrStayingAsleep",
  },
  {
    question: "Excessive daytime sleepiness",
    field: "excessiveDaytimeSleepiness",
  },
  {
    question: "Snoring or stops breathing during sleep",
    field: "snoringOrStopsBreathingDuringSleep",
  },
];

export const eatingQuestions: Question[] = [
  {
    question: "Distorted body image",
    field: "distortedBodyImage",
  },
  {
    question: "Underweight",
    field: "underweight",
  },
  {
    question: "Binge eating",
    field: "bingeEating",
  },
  {
    question: "Overweight",
    field: "overweight",
  },
  {
    question: "Eating too little or refusing to eat",
    field: "eatingTooLittleOrRefusingToEat",
  },
];

export const conductQuestions: Question[] = [
  {
    question: "Verbal aggression",
    field: "verbalAggression",
  },
  {
    question: "Physical aggression",
    field: "physicalAggression",
  },
  {
    question: "Used a weapon against people (stones, sticks etc.)",
    field: "usedAWeaponAgainstPeople",
  },
  {
    question: "Cruel to animals",
    field: "cruelToAnimals",
  },
  {
    question: "Physically cruel to people",
    field: "physicallyCruelToPeople",
  },
  {
    question: "Stealing or shoplifting",
    field: "stealingOrShoplifting",
  },
  {
    question: "Deliberately sets fires",
    field: "deliberatelySetsFires",
  },
  {
    question: "Deliberately destroys property",
    field: "deliberatelyDestroysProperty",
  },
  {
    question: "Frequent lying",
    field: "frequentLying",
  },
  {
    question: "Lack of remorse or guilt",
    field: "lackOfRemorseOrGuilt",
  },
  {
    question: "Lack of empathy or concern for others",
    field: "lackOfEmpathyOrConcernForOthers",
  },
];

export const substanceUseQuestions: Question[] = [
  {
    question: "Misuse of prescription drugs",
    field: "misuseOfPrescriptionDrugs",
  },
  {
    question: "Alcohol > 14 drinks/week or 4 drinks at once",
    field: "moreThan14DrinksAWeekOr4DrinksAtOnce",
  },
  {
    question: "Smoking or tobacco use",
    field: "smokingOrTobaccoUse",
  },
  {
    question: "Marijuana",
    field: "marijuana",
  },
  {
    question: "Other street drugs",
    field: "otherStreetDrugs",
  },
  {
    question: "Excessive over the counter medications",
    field: "excessiveOverTheCounterMedications",
  },
  {
    question: "Excessive caffeine (colas, coffee, tea, pills)",
    field: "excessiveCaffeine",
  },
];

export const addictionsQuestions: Question[] = [
  {
    question: "Gambling",
    field: "gambling",
  },
  {
    question: "Excessive internet, gaming or screen time",
    field: "excessiveScreenTime",
  },
];

export const personalityQuestions: Question[] = [
  {
    question: "Self-destructive",
    field: "selfDestructive",
  },
  {
    question: "Stormy, conflicted relationships",
    field: "conflictedRelationships",
  },
  {
    question: "Self-injurious behaviour (e.g. cutting)",
    field: "selfInjuriousBehaviour",
  },
  {
    question: "Low self-esteem",
    field: "lowSelfEsteem",
  },
  {
    question: "Manipulative",
    field: "manipulative",
  },
  {
    question: "Self-centered",
    field: "selfCentered",
  },
  {
    question: "Arrogant",
    field: "arrogant",
  },
  {
    question: "Suspicious",
    field: "suspicious",
  },
  {
    question: "Deceitful with no remorse",
    field: "deceitfulWithNoRemorse",
  },
  {
    question: "Breaking the law or antisocial behaviour",
    field: "breakingTheLawOrAntisocialBehaviour",
  },
  {
    question: "Tends to be a loner",
    field: "tendsToBeALoner",
  },
];

export const weissSymptomRecordIIQuestions = [
  { title: "Attention", questions: attentionQuestions },
  {
    title: "Hyperactivity and Impulsivity",
    questions: hyperActivityAndImpulsivityQuestions,
  },
  { title: "Oppositional", questions: oppositionalQuestions },
  {
    title: "Development and Learning",
    questions: developmentAndLearningQuestions,
  },
  { title: "Autism Spectrum", questions: autismSpectrumQuestions },
  { title: "Motor Disorders", questions: motorDisordersQuestions },
  { title: "Psychosis", questions: psychosisQuestions },
  { title: "Depression", questions: depressionQuestions },
  { title: "Mood Regulation", questions: moodRegulationQuestions },
  { title: "Suicide", questions: suicideQuestions },
  { title: "Anxiety", questions: anxietyQuestions },
  { title: "Stress", questions: stressRelatedQuestions },
  { title: "PTSD", questions: ptsdQuestions },
  { title: "Addictions", questions: addictionsQuestions },
  { title: "Personality", questions: personalityQuestions },
];
