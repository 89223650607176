export const prescriberChoices = [
  {
    id: "0f1dbbfe-622f-4de2-be49-21ab08c2461b",
    name: "Ligate, Lois",
    value: "Lois Ligate",
  },
  {
    id: "ebc56471-7271-422d-885e-96da288736f0",
    name: "Manhas, Bhavan",
    value: "Bhavan Manhas",
  },
  {
    id: "b88b8842-7a21-404e-93e4-fd024fa86ca8",
    name: "Milley, Kayla",
    value: "Kayla Milley",
  },
  {
    id: "EBAA3EZANLDIM2ZS",
    name: "Muller, Francois",
    value: "Francois Muller",
  },
  {
    id: "c37e02d6-e59a-4b5f-804d-b76f7f04eba2",
    name: "Muller, Marlene",
    value: "Marlene Muller",
  },
  {
    id: "059460a5-8afa-445c-8bd6-5b8b133f9743",
    name: "Soliven-Llaguno, John",
    value: "John Soliven-Llaguno",
  },
  {
    id: "fedd5f57-d679-458b-bc33-70222c012b91",
    name: "Stevenson, JoAnna",
    value: "JoAnna Stevenson",
  },
];

export const pharmacistNameChoices = [
  { id: "Danica Hart", name: "Hart, Danica" },
  { id: "Lily McWilliams", name: "McWilliams, Lily" },
  { id: "Mateya Radisavljevic", name: "Radisavljevic, Mateya" },
];
