import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { Datagrid, DateField, List, TextField } from "react-admin";
import { eventDateOptions } from "../../utils/dateTime";

export const Configuration: FunctionComponent = () => {
  const { user } = useAuth0();
  return (
    <>
      <Typography sx={{ mt: 3, fontSize: 20 }} variant="h2">
        Login Activity
      </Typography>
      <List
        sort={{ field: "createdAt", order: "DESC" }}
        resource="login-activity"
        filter={{ "loginUser.id": user?.sub }}
      >
        <Datagrid rowClick="show">
          <TextField label="ID" source="id" />
          <TextField label="IP" source="ip" />
          <TextField label="First Name" source="user.firstName" />
          <TextField label="Last Name" source="user.lastName" />
          <TextField label="Email" source="email" />
          <TextField source="portal" />
          <DateField source="createdAt" options={eventDateOptions} showTime />
        </Datagrid>
      </List>
    </>
  );
};
