import { getCognitoAdhdAssessmentFormText } from "./cognitoAdhdAssessmentForm/CognitoAdhdAssessmentFormText";
import { CognitoAdhdAssessmentForm } from "./cognitoAdhdAssessmentForm/types";
import { flagSection } from "./flagWeissSection";
import { CADDRAMedicationQuestions } from "./medicationForm/questions";
import { CADDRAMedicationForm } from "./medicationForm/types";
import { selfReportQuestions } from "./selfReport/questions";
import { SelfReportForm } from "./selfReport/types";
import { calculateScore as calculateASRSScore } from "./selfReport/utils";
import { translateScore as translateSheehanScore } from "./sheehan/SheehanPage";
import { SheehanDisabilityScaleForm } from "./sheehan/types";
import { snapQuestions } from "./snap/questions";
import { SnapForm } from "./snap/types";
import { getDiagnosis as getSnapDiagnosis } from "./snap/utils";
import { weissSymptomRecordIIQuestions } from "./weissSymptomRecordII/questions";
import { WeissSymptomRecordIIForm } from "./weissSymptomRecordII/types";
import { wfirsQuestions } from "./wfirs/questions";
import { WfirsForm } from "./wfirs/types";
import { WenderUtahRatingScaleForm } from "./wurs/types";
import {
  questionsToTakeIntoConsideration as WURSQuestionsToTakeIntoConsideration,
  calculateScore as calculateWURSScore,
} from "./wurs/utils";
import { IPrescreening } from "../Prescreenings/Prescreening";
import { getPrescreeningTextSummary } from "../Prescreenings/PrescreeningTextSummary";

export const convertBooleanToYesNo = (value: boolean | undefined) => {
  if (value === undefined || value === null) {
    return "Not answered";
  }
  return value ? "Yes" : "No";
};

/**
 * Only clients created after the new ADHD flow launch should see ADHD
 * instructions and bookings. The old flow had admins book the events while
 * now clients can book the events themselves.
 */
export const onNewAdhdFlow = (createdAt?: Date) => {
  if (!createdAt) {
    return false;
  }
  return createdAt > new Date("2023-10-03");
};

export const createPasteableText = (args: {
  data: SelfReportForm &
    SnapForm &
    SheehanDisabilityScaleForm &
    WfirsForm &
    WeissSymptomRecordIIForm &
    CADDRAMedicationForm &
    CognitoAdhdAssessmentForm;
  // There's a duplicate key in WFIRS and WURS so the data doesn't get
  // overridden have WURS be its own data.
  wursData: WenderUtahRatingScaleForm;
  clientName: string;
  clientCreatedAt: Date;
  prescreeningData: IPrescreening;
}) => {
  const { clientName, data } = args;

  const wursScore = calculateWURSScore(args.wursData);
  const wursScoreDescription = Number.isNaN(wursScore)
    ? "N/A"
    : `${wursScore} / ${WURSQuestionsToTakeIntoConsideration.length * 4}`;

  let text = ``;
  const addText = (addedText: string, newLine?: boolean) => {
    text += `${newLine ? "\n" : ""}${addedText}\n`;
  };

  addText(`${clientName} Summary`);
  addText(`All answers provided by ${clientName}`);

  addText(getCognitoAdhdAssessmentFormText(args), true);

  addText(getPrescreeningTextSummary(args.prescreeningData), true);

  addText("CADDRA SCALES", true);

  addText(`ASRS`, true);
  selfReportQuestions.forEach(questionSet =>
    addText(
      `${questionSet.title}: ${calculateASRSScore(
        data,
        questionSet.questions
      )}/${questionSet.questions.length}`
    )
  );

  addText("SNAP", true);
  if (data.cantCompleteReason) {
    addText(`Did Not Complete`);
    addText(`Reason: ${data.cantCompleteReason}`);
  } else {
    addText(`Completed by: ${data.completedByName}`);
    addText(`Relationship to client: ${data.relationshipToClient}`);
    addText(`Length of relationship: ${data.relationshipLength}`);
    snapQuestions.forEach(questionSet => {
      addText(
        `${questionSet.title} - ${getSnapDiagnosis(
          data,
          questionSet.questions,
          questionSet.section
        )}`
      );
    });
  }

  addText("WURS", true);
  addText(wursScoreDescription);

  addText("SHEEHAN", true);
  if (data.hasNotWorkedOrStudiedInPastWeek) {
    addText(
      "Has not worked in the past week for reasons unrelated to the disorder"
    );
  } else {
    addText(
      `Work/School: ${
        data.symptomsHaveDisruptedWorkOrSchool
      } (${translateSheehanScore(data.symptomsHaveDisruptedWorkOrSchool)})`
    );
  }
  addText(
    `Social Life/Leisure: ${
      data.symptomsHaveDisruptedSocialLifeOrLeisureActivities
    } (${translateSheehanScore(
      data.symptomsHaveDisruptedSocialLifeOrLeisureActivities
    )})`
  );
  addText(
    `Family Life / Home: ${
      data.symptomsHaveDisruptedFamilyLifeOrHomeResponsibilities
    } (${translateSheehanScore(
      data.symptomsHaveDisruptedFamilyLifeOrHomeResponsibilities
    )})`
  );

  addText("WSR", true);
  weissSymptomRecordIIQuestions.forEach(questionSet => {
    const section = flagSection(
      data,
      questionSet.questions,
      questionSet.title,
      false
    );
    if (!section) {
      return;
    }
    addText(section);
  });

  addText("WFIRS", true);
  wfirsQuestions.forEach(questionSet => {
    const section = flagSection(
      data,
      questionSet.questions,
      questionSet.title,
      true
    );
    if (!section) {
      return;
    }
    addText(section);
  });

  addText("CADDRA Patient ADHD Medication Form", true);

  if (data.hasNeverTakenADHDMedication) {
    addText("Has never taken ADHD medication");
  } else {
    if (data.medications) {
      data.medications.forEach(medication => {
        addText(medication);
      });
    }
    addText(`ADHD Symptom Control: ${data.adhdSymptomControl}`);
    addText(`Tolerability of Medication: ${data.tolerabilityOfMedication}`);
    addText(`Quality of Life: ${data.qualityOfLife}`);
    addText(`Additional Comments: ${data.comments ?? "None"}`);
    CADDRAMedicationQuestions.forEach(questionSet => {
      questionSet.questions.forEach(questionInfo => {
        if (data[questionInfo.field]) {
          addText(`${questionInfo.question}: ${data[questionInfo.field]}`);
        }
      });
    });
    addText(`Other Side Effects: ${data.otherSideEffects ?? "None"}`);
    addText(`Items to Discuss: ${data.itemsToDiscuss ?? "None"}`);
  }

  navigator.clipboard.writeText(text);
};
