import {
  BooleanInput,
  DateInput,
  Edit,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";

const validateRequired = required();

const CalendlyEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const CalendlyEdit = props => {
  const pageTitle = "Edit User";

  return (
    <Edit {...props} title={pageTitle}>
      <SimpleForm toolbar={<CalendlyEditToolbar />}>
        <BooleanInput source="canceled" fullWidth />
        <TextInput source="email" validate={validateRequired} fullWidth />
        <TextInput source="name" validate={validateRequired} fullWidth />
        <TextInput source="status" validate={validateRequired} fullWidth />
        <TextInput source="host_name" validate={validateRequired} fullWidth />
        <BooleanInput source="canceled" fullWidth />
        <DateInput source="eventStartTime" fullWidth />
        <DateInput source="eventEndTime" fullWidth />
      </SimpleForm>
    </Edit>
  );
};
