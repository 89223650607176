import { WenderUtahRatingScaleFormFields } from "./types";

const questionsPartA: {
  question: string;
  field: WenderUtahRatingScaleFormFields;
}[] = [
  {
    question: "Active, restless, always on the go.",
    field: "activeRestlessAlwaysOnTheGo",
  },
  {
    question: "Afraid of things.",
    field: "afraidOfThings",
  },
  {
    question: "Concentration problems, easily distracted",
    field: "easilyDistracted",
  },
  {
    question: "Anxious, full of worry.",
    field: "anxious",
  },
  {
    question: "Nervous, fidgety",
    field: "nervous",
  },
  {
    question: "Inattentive, daydreaming.",
    field: "inattentive",
  },
  {
    question: "Hot- or short-tempered, low boiling point",
    field: "hotOrShortTempered",
  },
  {
    question: "Shy, sensitive.",
    field: "shy",
  },
  {
    question: "Temper outbursts, tantrums",
    field: "temperOutbursts",
  },
  {
    question:
      "Trouble with following through. Failing to finish things started",
    field: "failToFinishThings",
  },
  {
    question: "Stubborn, strong-willed.",
    field: "stubborn",
  },
  {
    question: "Sad or blue, depressed, unhappy",
    field: "unhappy",
  },
  {
    question: "Incautious, dare-devilish, involved in pranks",
    field: "dareDevil",
  },
  {
    question: "Not getting a kick out of things, dissatisfied with life",
    field: "dissatisfiedWithLife",
  },
  {
    question: "Disobedient with parents, rebellious, sassy",
    field: "rebellious",
  },
  {
    question: "Low opinion of myself",
    field: "lowOpinionOfMyself",
  },
  {
    question: "Irritable",
    field: "irritable",
  },
  {
    question: "Outgoing, friendly, enjoyed company of people",
    field: "enjoyedCompanyOfPeople",
  },
  {
    question: "Sloppy, disorganized",
    field: "disorganized",
  },
  {
    question: "Moody, ups and downs",
    field: "moodyUpsAndDowns",
  },
  {
    question: "Angry",
    field: "angry",
  },
  {
    question: "Friends, popular",
    field: "popular",
  },
  {
    question: "Well-organized, tidy, neat",
    field: "wellOrganized",
  },
  {
    question: "Acting without thinking, impulsive",
    field: "impulsive",
  },
  {
    question: "Tendency to be immature",
    field: "tendencyToBeImmature",
  },
  {
    question: "Guilty feelings, regretful",
    field: "guiltyFeelings",
  },
  {
    question: "Losing control of myself",
    field: "losingControlOfMyself",
  },
  {
    question: "Tendency to be or act irrational",
    field: "irrational",
  },
  {
    question:
      "Unpopular with other children, didn't keep friends for long, didn't get along with other children",
    field: "unpopular",
  },
  {
    question: "Poorly coordinated, did not participate in sports",
    field: "poorlyCoordinated",
  },
  {
    question: "Afraid of losing control of self",
    field: "afraidOfLosingControlOfSelf",
  },
  {
    question: "Well-coordinated, picked first in games",
    field: "wellCoordinated",
  },
  {
    question: "Running away from home",
    field: "runningAwayFromHome",
  },
  {
    question: "Getting into fights",
    field: "gettingIntoFights",
  },
  {
    question: "Teasing other children",
    field: "teasingOtherChildren",
  },
  {
    question: "Leader, bossy",
    field: "leader",
  },
  {
    question: "Difficulty getting awake",
    field: "difficultyGettingAwake",
  },
  {
    question: "Follower, led around too much",
    field: "follower",
  },
  {
    question: "Trouble seeing things from someone else's point of view",
    field: "troubleSeeingThingsFromAnotherPointOfView",
  },
  {
    question:
      "Trouble with authorities, trouble with school, visits to principal's office",
    field: "troublesWithAuthorities",
  },
  {
    question: "Trouble with police, booked, convicted",
    field: "troubleWithPolice",
  },
];

const medicalProblems: {
  question: string;
  field: WenderUtahRatingScaleFormFields;
}[] = [
  {
    question: "Headaches",
    field: "headaches",
  },
  {
    question: "Stomachaches",
    field: "stomachaches",
  },
  {
    question: "Constipation",
    field: "constipation",
  },
  {
    question: "Diarrhea",
    field: "diarrhea",
  },
  {
    question: "Food allergies",
    field: "foodAllergies",
  },
  {
    question: "Other allergies",
    field: "otherAllergies",
  },
  {
    question: "Bedwetting",
    field: "bedwetting",
  },
];

const schoolQuestions: {
  question: string;
  field: WenderUtahRatingScaleFormFields;
}[] = [
  {
    question: "Overall a good student, fast",
    field: "overallAGoodStudent",
  },
  {
    question: "Overall a poor student, slow learner",
    field: "overallAPoorStudent",
  },
  {
    question: "Slow in learning to read",
    field: "slowLearningToRead",
  },
  {
    question: "Slow reader",
    field: "slowReader",
  },
  {
    question: "Trouble reversing letters",
    field: "troubleReversingLetters",
  },
  {
    question: "Problems with spelling",
    field: "problemsWithSpelling",
  },
  {
    question: "Trouble with mathematics or numbers",
    field: "troubleWithMathOrNumbers",
  },
  {
    question: "Bad handwriting",
    field: "badHandwriting",
  },
  {
    question: "Able to read pretty well but never really enjoyed reading",
    field: "readWellButNeverEnjoyedReading",
  },
  {
    question: "Not achieving up to potential",
    field: "notAchievingUpToPotential",
  },
  {
    question: "Repeating grades",
    field: "repeatingGrades",
  },
  {
    question: "Suspended or expelled",
    field: "suspendedOrExpelled",
  },
];

export const wenderUtahRatingScaleQuestions = [
  { title: "As a child I was (or had)", questions: questionsPartA },
  { title: "Medical problems as a child", questions: medicalProblems },
  { title: "As a child in school I was (or had)", questions: schoolQuestions },
];
