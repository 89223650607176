import { Box, Typography } from "@material-ui/core";
import {
  Document as PDFDocument,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import React from "react";
import { SheehanPage } from "./SheehanPage";
import { SheehanDisabilityScaleForm } from "./types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../Prescreenings/Accordions";

export const SheehanDocument: React.FC<{
  data: SheehanDisabilityScaleForm;
  clientName: string;
}> = ({ data, clientName }) => {
  const Document = () => (
    <PDFDocument>
      <SheehanPage data={data} clientName={clientName} />
    </PDFDocument>
  );

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="Sheehan Disability Scale-content"
        id="Sheehan Disability Scale-header"
      >
        <Typography>Sheehan Disability Scale</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PDFDownloadLink
          document={<Document />}
          fileName="Sheehan Disability Scale.pdf"
        >
          {({ loading }) => (loading ? "Loading document..." : "Download now")}
        </PDFDownloadLink>
        <Box sx={{ height: "80vh" }}>
          <PDFViewer showToolbar={false} height="100%" width="100%">
            <Document />
          </PDFViewer>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
