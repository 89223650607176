import { Typography } from "@mui/material";
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "./Accordions";
import { IPrescreening, PrescreeningProps } from "./Prescreening";
import { SortFields } from "./SortFields";
import { formatPhoneNumber } from "./utils";

interface SocialHistoryProps {
  data: IPrescreening & PrescreeningProps;
}

export const fields: {
  name: string;
  label?: string;
  hideWhenTrue?: boolean;
}[] = [
  { name: "occupation", label: "Employment" },
  { name: "highestLevelOfEducation", label: "Education" },
  { name: "livingSituation" },
  { name: "supportName" },
  { name: "supportRelationship" },
  { name: "knowSomeoneWhoHasUsedCognito", hideWhenTrue: true },
];
export const SocialHistory: React.FC<SocialHistoryProps> = ({ data }) => {
  if (
    data.knowSomeoneWhoHasUsedCognito &&
    !fields.find(it => it.name === "knowSomeoneWhoHasUsedCognitoDetails")
  ) {
    fields.push({ name: "knowSomeoneWhoHasUsedCognitoDetails" });
  }

  const dataToUse = {
    ...data,
    emergencyContactPhone: formatPhoneNumber(data.emergencyContactPhone),
  };

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="SocialHistory-content"
        id="SocialHistory-header"
      >
        <Typography>Social History</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SortFields data={dataToUse} fields={fields} />
      </AccordionDetails>
    </Accordion>
  );
};
