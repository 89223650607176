import { SnapForm } from "./types";

export const getDiagnosis = (
  form: SnapForm,
  questions: { field: string }[],
  section: number
) => {
  const score = calculateScore(form, questions);
  return `${getScoreMapping(section, score)} ${
    score === undefined ? "" : score
  }`;
};

const calculateScore = (form: SnapForm, questions: { field: string }[]) => {
  let totalScore = 0;
  questions.forEach(question => {
    totalScore = totalScore + form[question.field]!;
  });

  return Number.isNaN(totalScore) ? undefined : totalScore;
};

const getScoreMapping = (section: number, totalScore: number | undefined) => {
  if (totalScore === undefined) {
    return "Not completed";
  }
  if (section === 2) {
    if (totalScore < 8) {
      return "Not Significant";
    }
    if (totalScore < 14) {
      return "Mild";
    }
    if (totalScore < 19) {
      return "Moderate";
    }
    return "Severe";
  }
  if (totalScore < 13) {
    return "Not Significant";
  }
  if (totalScore < 18) {
    return "Mild";
  }
  if (totalScore < 23) {
    return "Moderate";
  }
  return "Severe";
};
