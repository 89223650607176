import { SnapFormQuestionsFields } from "./types";

const attentionQuestions: {
  field: SnapFormQuestionsFields;
  question: string;
}[] = [
  {
    question:
      "Often fails to give close attention to details or makes careless mistakes in schoolwork or tasks",
    field: "oftenFailsToGiveCloseAttentionToDetails",
  },
  {
    question:
      "Often has difficulty sustaining attention in tasks or play activities",
    field: "oftenHasDifficultySustainingAttention",
  },
  {
    question: "Often does not seem to listen when spoken to directly",
    field: "oftenDoesNotSeemToListenWhenSpoken",
  },
  {
    question:
      "Often does not follow through on instructions and fails to finish schoolwork, chores, or duties",
    field: "oftenDoesNotFollowThroughOnInstructions",
  },
  {
    question: "Often has difficulty organizing tasks and activities",
    field: "oftenHasDifficultyOrganizingTasksAndActivities",
  },
  {
    question:
      "Often avoids, dislikes, or reluctantly engages in tasks requiring sustained mental effort",
    field: "oftenAvoidsDislikesOrReluctantlyEngagesInTasks",
  },
  {
    question:
      "Often loses things necessary for activities (e.g., toys, school assignments, pencils or books",
    field: "oftenLosesThingsNecessaryForActivities",
  },
  {
    question: "Often is distracted by extraneous stimuli",
    field: "oftenIsDistractedByExtraneousStimuli",
  },
  {
    question: "Often is forgetful in daily activities",
    field: "oftenIsForgetfulInDailyActivities",
  },
];
const hyperactivityQuestions: {
  field: SnapFormQuestionsFields;
  question: string;
}[] = [
  {
    question: "Often fidgets with hands or feet or squirms in seat",
    field: "oftenFidgetsWithHandsOrFeet",
  },
  {
    question:
      "Often leaves seat in classroom or in other situations in which remaining seated is expected",
    field: "oftenLeavesSeatInClassroom",
  },
  {
    question:
      "Often runs about or climbs excessively in situations in which it is inappropriate",
    field: "oftenRunsAboutOrClimbsExcessively",
  },
  {
    question:
      "Often has difficulty playing or engaging in leisure activities quietly",
    field: "oftenHasDifficultyPlayingOrEngagingInLeisure",
  },
  {
    question: "Often is “on the go” or often acts as if “driven by a motor”",
    field: "oftenIsOnTheGo",
  },
  {
    question: "Often talks excessively",
    field: "oftenTalksExcessively",
  },
  {
    question: "Often blurts out answers before questions have been completed",
    field: "oftenBlurtsOutAnswersBeforeQuestionsHaveBeenCompleted",
  },
  {
    question: "Often has difficulty awaiting turn",
    field: "oftenHasDifficultyAwaitingTurn",
  },
  {
    question:
      "Often interrupts or intrudes on others (e.g., butts into conversations/games)",
    field: "oftenInterruptsOthers",
  },
];

const agitationQuestions: {
  field: SnapFormQuestionsFields;
  question: string;
}[] = [
  {
    question: "Often loses temper",
    field: "oftenLosesTemper",
  },
  {
    question: "Often argues with adults",
    field: "oftenArguesWithAdults",
  },
  {
    question: "Often actively defies or refuses adult requests or rules",
    field: "oftenActivelyDefiesOrRefuseAdultRequests",
  },
  {
    question: "Often deliberately does things that annoy other people",
    field: "oftenDeliberatelyDoesThingsThatAnnoyOtherPeople",
  },
  {
    question: "Often blames others for his or her mistakes or misbehaviour",
    field: "oftenBlamesOthersForTheirMistakes",
  },
  {
    question: "Often is touchy or easily annoyed by others",
    field: "oftenTouchyOrEasilyAnnoyedByOther",
  },
  {
    question: "Often is angry and resentful",
    field: "oftenIsAngryAndResentful",
  },
  {
    question: "Often is spiteful or vindictive",
    field: "oftenIsSpitefulOrVindictive",
  },
];

export const snapQuestions = [
  { title: "Inattention", questions: attentionQuestions, section: 0 },
  { title: "Hyperactivity", questions: hyperactivityQuestions, section: 1 },
  { title: "Opposition/Defiance", questions: agitationQuestions, section: 2 },
];
