import { Box, Typography } from "@material-ui/core";
import {
  Document as PDFDocument,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import React from "react";
import { MedicationPage } from "./MedicationPage";
import { CADDRAMedicationForm } from "./types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../Prescreenings/Accordions";

export const MedicationDocument: React.FC<{
  data: CADDRAMedicationForm;
  clientName: string;
}> = ({ data, clientName }) => {
  const Document = () => (
    <PDFDocument>
      <MedicationPage data={data} clientName={clientName} />
    </PDFDocument>
  );

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="Medication-content"
        id="Medication-header"
      >
        <Typography>CADDRA Patient ADHD Medication Form</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PDFDownloadLink
          document={<Document />}
          fileName={`${clientName} - CADDRA Patient ADHD Medication Form.pdf`}
        >
          {({ loading }) => (loading ? "Loading document..." : "Download now")}
        </PDFDownloadLink>
        <Box sx={{ height: "80vh" }}>
          <PDFViewer showToolbar={false} height="100%" width="100%">
            <Document />
          </PDFViewer>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
