import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@material-ui/core";
import { Download as DownloadIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { InputLabel } from "@mui/material";
import { useState } from "react";
import {
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  WithRecord,
} from "react-admin";
import { REACT_APP_AUTH0_AUDIENCE } from "../../utils";

function getFileUrl(blobNameInAzure) {
  return `${REACT_APP_AUTH0_AUDIENCE}/admin/storage/${blobNameInAzure}`;
}

const DownloadButton = ({ attachment }) => {
  const { id, blobNameInAzure, originalName } = attachment;
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  async function downloadFile() {
    setLoading(true);

    const fileUrl = getFileUrl(blobNameInAzure);
    const accessToken = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await fetch(fileUrl, {
      headers,
    });
    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = originalName;
    link.click();
    URL.revokeObjectURL(downloadUrl);

    setLoading(false);
  }

  return (
    <LoadingButton
      key={id}
      loading={loading}
      startIcon={<DownloadIcon />}
      loadingPosition="start"
      variant="outlined"
      onClick={downloadFile}
      style={{ margin: "0.25rem" }}
    >
      {originalName}
    </LoadingButton>
  );
};

export const MessageShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <FunctionField
          label="To"
          render={record =>
            `${record.toUser.email} <${record.toUser.firstName} ${record.toUser.lastName}>`
          }
        />
        <WithRecord
          label="From"
          render={record => {
            return (
              <TextField
                record={{
                  ...record,
                  combined_name: `${record.fromMedicalStaff.firstName} ${record.fromMedicalStaff.lastName}`,
                }}
                source="combined_name"
              />
            );
          }}
        />
        <TextField source="subject" />
        <TextField style={{ whiteSpace: "pre-wrap" }} source="body" />
        <InputLabel>Attachments</InputLabel>
        <Box display="flex" flexWrap="wrap">
          <WithRecord
            render={record =>
              record.attachments?.length
                ? record.attachments.map(a => (
                    <DownloadButton key={a.id} attachment={a} />
                  ))
                : "None"
            }
          />
        </Box>
      </SimpleShowLayout>
    </Show>
  );
};
