import { Datagrid, DateField, List, TextField } from "react-admin";
import { loginActivitiesFilters } from "./LoginActivitiesFilters";
import { Pagination } from "../../components/Pagination";
import { eventDateOptions } from "../../utils/dateTime";

export const LoginActivitiesList = props => (
  <List
    {...props}
    sort={{ field: "createdAt", order: "DESC" }}
    filters={loginActivitiesFilters}
    perPage={100}
    pagination={<Pagination />}
  >
    <Datagrid rowClick="show">
      <TextField label="IP" source="ip" />
      <TextField label="First Name" source="user.firstName" />
      <TextField label="Last Name" source="user.lastName" />
      <TextField label="Email" source="email" />
      <TextField source="portal" />
      <DateField source="createdAt" options={eventDateOptions} showTime />
    </Datagrid>
  </List>
);
