import { Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { SheehanDisabilityScaleForm } from "./types";
import { convertDateToHumanReadable } from "../../progress/utils";
import { genericStyles } from "../PdfBuilder";

export const TextContainer: React.FC<{ text: string; value: any }> = ({
  text,
  value,
}) => {
  return (
    <View
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: "10pt",
      }}
    >
      <Text>{`${text} ${value}`}</Text>
    </View>
  );
};

export const translateScore = (score: number | null | undefined) => {
  if (score === null || score === undefined) {
    return "Not answered";
  }
  if (score === 0) {
    return "Not at all";
  }
  if (score < 4) {
    return "Mildly";
  }
  if (score < 7) {
    return "Moderately";
  }
  if (score < 10) {
    return "Markedly";
  }
  return "Extremely";
};

export const SheehanPage: React.FC<{
  data: SheehanDisabilityScaleForm;
  clientName: string;
}> = ({ data, clientName }) => {
  return (
    <Page size="A4" style={genericStyles.page}>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>Sheehan Disability Scale</Text>
      </View>
      <View
        style={{ display: "flex", alignItems: "center", paddingBottom: "10pt" }}
      >
        <Text>{clientName}</Text>
        {data?.createdDate && (
          <Text>{`Completed on ${convertDateToHumanReadable(
            data.createdDate
          )}`}</Text>
        )}
      </View>
      {data.hasNotWorkedOrStudiedInPastWeek && (
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "10pt",
          }}
        >
          <Text>
            Has not worked in the past week for reasons unrelated to the
            disorder
          </Text>
        </View>
      )}
      {!data.hasNotWorkedOrStudiedInPastWeek && (
        <TextContainer
          text={"Symptoms have disrupted work/school:"}
          value={`${data.symptomsHaveDisruptedWorkOrSchool} (${translateScore(
            data.symptomsHaveDisruptedWorkOrSchool
          )})`}
        />
      )}
      <TextContainer
        text={"Symptoms have disrupted social life / leisure activities:"}
        value={`${
          data.symptomsHaveDisruptedSocialLifeOrLeisureActivities
        } (${translateScore(
          data.symptomsHaveDisruptedSocialLifeOrLeisureActivities
        )})`}
      />
      <TextContainer
        text={"Symptoms have disrupted family life / home responsibilities:"}
        value={`${
          data.symptomsHaveDisruptedFamilyLifeOrHomeResponsibilities
        } (${translateScore(
          data.symptomsHaveDisruptedFamilyLifeOrHomeResponsibilities
        )})`}
      />
      <TextContainer text={"Days lost:"} value={data.daysMissed} />
      <TextContainer
        text={"Days underproductive:"}
        value={data.daysProductivityReduced}
      />
    </Page>
  );
};
