import AddIcon from "@mui/icons-material/Add";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Button, useCreatePath, useRecordContext } from "react-admin";
import { Link, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./../Prescreenings/Accordions";
import { useHttpClient } from "../../utils";
import { convertDateToHumanReadable } from "../progress/utils";

const MessageTable = ({ messages }) => {
  const createPath = useCreatePath();
  const navigateTo = useNavigate();

  if (!messages) {
    return null;
  } else {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Subject</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Read</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Created</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>From</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.map((message: any) => {
              return (
                <TableRow
                  hover
                  onClick={() => {
                    const route = createPath({
                      resource: "admin/messages",
                      type: "show",
                      id: message.id,
                    });
                    navigateTo(route);
                  }}
                  tabIndex={-1}
                  key={message.id}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell>{message.subject}</TableCell>
                  <TableCell>
                    {message.openedAt
                      ? convertDateToHumanReadable(message.openedAt)
                      : "Unread"}
                  </TableCell>
                  <TableCell>
                    {convertDateToHumanReadable(message.createdAt)}
                  </TableCell>
                  <TableCell>
                    {message.fromMedicalStaff
                      ? `${message.fromMedicalStaff?.firstName} ${message.fromMedicalStaff?.lastName}`
                      : ""}
                  </TableCell>
                </TableRow>
              );
            })}
            {messages.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  No Messages
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export const UserMessages = ({ userId }) => {
  const { httpClient, baseUrl } = useHttpClient();

  const [messages, setMessages] = useState<[] | undefined>(undefined);

  useEffect(() => {
    const fetchMessages = async () => {
      const res = await httpClient(
        `${baseUrl}/api/admin/messages?userId=${userId}`,
        {
          method: "GET",
        }
      );

      setMessages(res.json);
    };
    fetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Accordion>
      <AccordionSummary aria-controls="Messages-content" id="Messages-header">
        <Typography>Messages</Typography>
        <CreateMessageButton />
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <MessageTable messages={messages} />
      </AccordionDetails>
    </Accordion>
  );
};

const CreateMessageButton = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const path = `/admin/messages/create?to_id=${record.id}`;

  return (
    <Button
      label="Create"
      startIcon={<AddIcon />}
      component={Link}
      variant="outlined"
      aria-label="add message for user"
      to={path}
      style={{ marginLeft: "auto" }}
    />
  );
};
