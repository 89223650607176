export const convertBornToHumanReadable = (
  value: "onTime" | "early" | "late" | undefined
) => {
  if (value === undefined || value === null) {
    return "Not answered";
  } else if (value === "onTime") {
    return "On time";
  } else if (value === "early") {
    return "Early (before 37 weeks)";
  }
  return "Late (after 40 weeks)";
};
