import {
  BooleanInput,
  Create,
  DateInput,
  email,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";

const validateEmail = [required(), email()];
const validateRequired = required();

export const UserCreate = props => {
  const pageTitle = "Create User";

  return (
    <Create {...props} title={pageTitle} redirect="show">
      <SimpleForm>
        <TextInput source="id" validate={validateRequired} fullWidth />
        <TextInput source="firstName" validate={validateRequired} fullWidth />
        <TextInput source="lastName" validate={validateRequired} fullWidth />
        <TextInput source="email" validate={validateEmail} fullWidth />
        <TextInput source="stripeCustomerId" fullWidth />
        <TextInput source="mobile" fullWidth />
        <TextInput source="address" fullWidth />
        <TextInput source="city" fullWidth />
        <TextInput source="province" fullWidth />
        <TextInput source="postalCode" fullWidth />
        <TextInput source="personalHealthNumber" fullWidth />
        <BooleanInput source="consentToPrivacyTerms" fullWidth />
        <BooleanInput source="consentToCommunication" fullWidth />
        <TextInput source="emergencyContactName" fullWidth />
        <TextInput source="emergencyContactPhone" fullWidth />
        <TextInput source="emergencyContactRelationship" fullWidth />
        <TextInput source="referralSource" fullWidth />
        <TextInput source="signupSurveyResult" fullWidth />
        <TextInput source="signupSurveyCondition" fullWidth />
        <DateInput source="birthday" fullWidth />
        <TextInput source="primaryCareProvider" fullWidth />
        <TextInput source="primaryCareProviderName" fullWidth />
        <TextInput source="primaryCareProviderPhone" fullWidth />
        <TextInput source="primaryCareProviderFax" fullWidth />
        <TextInput source="text" fullWidth />
      </SimpleForm>
    </Create>
  );
};
