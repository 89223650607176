import { Box, Typography } from "@material-ui/core";
import {
  Document as PDFDocument,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import React from "react";
import { WfirsForm } from "./types";
import { WfirsPage } from "./WfirsPage";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../Prescreenings/Accordions";

export const WfirsDocument: React.FC<{
  data: WfirsForm;
  clientName: string;
}> = ({ data, clientName }) => {
  const Document = () => (
    <PDFDocument>
      <WfirsPage data={data} clientName={clientName} />
    </PDFDocument>
  );

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="Weiss Functional Impairment Rating Scale - Self Report (WFIRS-S)-content"
        id="Weiss Functional Impairment Rating Scale - Self Report (WFIRS-S)-header"
      >
        <Typography>
          Weiss Functional Impairment Rating Scale - Self Report (WFIRS-S)
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <PDFDownloadLink
          document={<Document />}
          fileName={`${clientName} - Weiss Functional Impairment Rating Scale - Self Report (WFIRS-S).pdf`}
        >
          {({ loading }) => (loading ? "Loading document..." : "Download now")}
        </PDFDownloadLink>
        <Box sx={{ height: "80vh" }}>
          <PDFViewer showToolbar={false} height="100%" width="100%">
            <Document />
          </PDFViewer>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
