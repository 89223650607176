import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { Button } from "@mui/material";
import Chip from "@mui/material/Chip";
import { Datagrid, DateField, List, TextField, WithRecord } from "react-admin";
import { calendlyFilters } from "./calendlyFilters";
import { Pagination } from "../../components/Pagination";
import { eventDateOptions } from "../../utils/dateTime";

export const StatusField = ({ record }) => {
  let status;
  let color;
  const eventStartTime = new Date(record.eventStartTime);
  const eventEndTime = new Date(record.eventEndTime);
  const currentTime = new Date();
  if (record.no_show !== "null") {
    status = "no showed";
    color = "#ff533e";
  } else if (record.rescheduled) {
    status = "rescheduled";
    color = "grey";
  } else if (record.canceled) {
    status = "canceled";
    color = "#ffa14c";
  } else if (eventEndTime < currentTime) {
    status = "completed";
    color = "#5da758";
  } else if (eventStartTime > currentTime) {
    status = "upcoming";
    color = "#41a2ff";
  } else if (eventStartTime <= currentTime && eventEndTime >= currentTime) {
    status = "in progress";
    color = "#b182c4";
  } else {
    status = "unknown";
    color = "black";
  }
  return (
    <Chip
      label={status}
      size={"small"}
      variant="outlined"
      sx={{ textTransform: "capitalize", color: color, borderColor: color }}
    />
  );
};

export const CalendlyList = props => (
  <List
    {...props}
    sort={{ field: "eventStartTime", order: "DESC" }}
    filters={calendlyFilters}
    perPage={100}
    pagination={<Pagination />}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="email" />
      <TextField source="name" />
      <TextField label="Event" source="eventName" />
      <WithRecord
        label="Status"
        render={record => <StatusField record={record} />}
      />
      <WithRecord
        label="Host"
        render={record => {
          const host = record.host_metadata?.user
            ? record.host_metadata.user.name
            : record.hostName;
          return host;
        }}
      />
      <DateField
        label="Start time"
        source="eventStartTime"
        options={eventDateOptions}
        showTime
      />
      <WithRecord
        label="Duration (mins)"
        render={record => {
          const start = new Date(record.eventStartTime);
          const end = new Date(record.eventEndTime);
          const duration = (end.getTime() - start.getTime()) / 60000;
          return duration;
        }}
      />
      <WithRecord
        label="Booking summary"
        render={record => {
          const month = new Date(record.eventStartTime).toLocaleString(
            "default",
            { month: "short" }
          );
          const day = new Date(record.eventStartTime).getDate();
          const year = new Date(record.eventStartTime).getFullYear();
          const date = `${month} ${day}, ${year}`;
          const time = new Date(record.eventStartTime).toLocaleTimeString(
            "default",
            { hour: "numeric", minute: "2-digit" }
          );
          const host = record.host_metadata?.user
            ? record.host_metadata.user.name
            : record.hostName;
          const summary = `${record.eventName} with ${host} on ${date} at ${time}`;
          const handleCopy = ({ summary }) => {
            navigator.clipboard.writeText(summary);
          };
          return (
            <Button
              onClick={e => {
                e.stopPropagation();
                handleCopy({ summary });
              }}
              variant="outlined"
              startIcon={<ContentCopyRoundedIcon />}
              size="small"
            >
              Copy summary
            </Button>
          );
        }}
      />
    </Datagrid>
  </List>
);
