import {
  CADDRAGlobalChangeScore,
  CADDRAMedicationScores,
  CADDRASideEffectScore,
} from "./types";

export const mapGlobalChangeScore = (
  score: CADDRAGlobalChangeScore | undefined
) => {
  switch (score) {
    case -2:
      return "Marked Deterioration";
    case -1:
      return "Small Deterioration";
    case 0:
      return "No Change";
    case 1:
      return "Small Improvement";
    case 2:
      return "Marked Improvement";
    default:
      return "Unanswered";
  }
};

export const getDescriptor = (score: CADDRAMedicationScores | undefined) => {
  if (score === -3) {
    return "-3 Worse";
  }
  if (score === 0) {
    return "0 Unchanged";
  }
  if (score === 3) {
    return "3 Better";
  }
  return score?.toString();
};

export const getMedicationSideEffectDescriptor = (
  score: CADDRASideEffectScore
) => {
  if (score === 0) {
    return "Not At All";
  }
  if (score === 1) {
    return "Sometimes";
  }
  if (score === 2) {
    return "Often";
  }
  if (score === 3) {
    return "All The Time";
  }
};
