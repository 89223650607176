import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography } from "@material-ui/core";
import { AxiosRequestHeaders } from "axios";
import { useEffect, useState } from "react";
import { useCreatePath, useGetRecordId } from "react-admin";
import { Link } from "react-router-dom";
import { DisplayResults } from "./DisplayResults";
import { convertDateToHumanReadable, convertScoreToAnswer } from "./utils";
import Graph, { GraphPoint } from "../../components/Graph";
import { requestHelper } from "../../utils/requestHelper";
import { SimpleSheehanDocument } from "../AdhdInfo/sheehan/ProgressSheehanDocument";
import { SheehanDisabilityScaleForm } from "../AdhdInfo/sheehan/types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../Prescreenings/Accordions";

export const Progress = () => {
  const recordId = useGetRecordId();
  const { getAccessTokenSilently } = useAuth0();
  const createPath = useCreatePath();

  const [moodData, setMoodData] = useState<GraphPoint[]>([]);
  const [anxietyData, setAnxietyData] = useState<GraphPoint[]>([]);
  const [depressionData, setDepressionData] = useState<GraphPoint[]>([]);
  const [productivityData, setProductivityData] = useState<
    SheehanDisabilityScaleForm[]
  >([]);
  const [productivityGraphData, setProductivityGraphData] = useState<
    GraphPoint[]
  >([]);
  const [userData, setUserData] = useState<
    { birthday: string; name: string } | undefined
  >(undefined);
  const [selectedAnxietyForm, setSelectedAnxietyForm] = useState("");
  const [selectedDepressionForm, setSelectedDepressionForm] = useState("");
  const [selectedProductivityForm, setSelectedProductivityForm] = useState("");
  const [refreshKey, setRefreshKey] = useState(Date.now());
  const getLabel = (thoughts: number, severity: number) => {
    return `Thoughts that you would be better off dead or hurting yourself in some way: ${convertScoreToAnswer(
      thoughts
    )}${
      severity
        ? `
Severity of thoughts: ${severity === 1 ? "Vague, General" : "Specific Plans"}`
        : ""
    }`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      // Add content-type and Authorization
      const headers: AxiosRequestHeaders = {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const endpoints = [
        "mood-check-in",
        "anxiety_forms/user",
        "depression_forms/user",
        "sheehan_disability_scales",
        "users",
      ];
      const [
        moodResponse,
        anxietyResponse,
        depressionResponse,
        sheehanResponse,
        userResponse,
      ] = await Promise.all(
        endpoints.map(endpoint =>
          requestHelper<any>(endpoint, recordId, headers)
        )
      );

      setUserData({
        birthday: userResponse.birthday,
        name: `${userResponse.firstName} ${userResponse.lastName}`,
      });
      setMoodData(
        moodResponse.map(d => ({
          x: convertDateToHumanReadable(d.createdAt),
          y: d.rating / 10,
          label: d.comment,
          id: d.id,
        })) ?? []
      );
      setAnxietyData(
        anxietyResponse.results.map(d => ({
          x: convertDateToHumanReadable(d.createdDate),
          y: d.totalSum,
          id: d.id,
        })) ?? []
      );
      setDepressionData(
        depressionResponse.results.map(d => ({
          x: convertDateToHumanReadable(d.createdDate),
          y: d.totalSum,
          id: d.id,
          label: getLabel(
            d.thoughtsBetterOffDeadOrHurtingYourself,
            d.recentThoughtsOfHurtingSelf
          ),
        })) ?? []
      );
      setProductivityGraphData(
        sheehanResponse.results.map(productivityEntry => ({
          x: convertDateToHumanReadable(productivityEntry.createdDate),
          y: productivityEntry.totalSum,
          id: productivityEntry.id,
        })) ?? []
      );
      setProductivityData(sheehanResponse.results);
    };

    if (!recordId) {
      return;
    }
    fetchData();
  }, [refreshKey]);

  if (!recordId) {
    return null;
  }

  return (
    <>
      <Box sx={{ padding: "0.5rem 1rem" }}>
        <Box display={"flex"} flexDirection="row" alignItems={"baseline"}>
          <Typography variant="h5">Progress</Typography>
          <Box sx={{ marginLeft: "0.5rem" }}>
            <Link
              to={createPath({
                resource: "users",
                type: "show",
                id: recordId,
              })}
            >
              {userData ? `Back to ${userData?.name}` : "Back"}
            </Link>
          </Box>
        </Box>
        <Accordion>
          <AccordionSummary aria-controls="Mood-content" id="Mood-header">
            <Typography>Mood</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Graph
              data={moodData}
              horizontalLineStep={2}
              maxYValue={10}
              targetZoneStartingPosition={"top"}
              targetZonePercentage={30}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="Anxiety-content" id="Anxiety-header">
            <Typography>Anxiety</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {selectedAnxietyForm && (
              <DisplayResults
                id={selectedAnxietyForm}
                name={userData!.name}
                birthday={convertDateToHumanReadable(userData!.birthday, {
                  dateInUtc: true,
                })}
                deleteCallback={() => {
                  setSelectedAnxietyForm("");
                  setRefreshKey(Date.now());
                }}
                type="anxiety"
                hideCallback={() => setSelectedAnxietyForm("")}
              />
            )}
            <Typography>
              Click on a point to learn more about the result, download the
              form, or delete it.
            </Typography>
            <Graph
              data={anxietyData}
              horizontalLineStep={7}
              maxYValue={21}
              targetZonePercentage={43}
              targetZoneStartingPosition={"bottom"}
              onClick={index => setSelectedAnxietyForm(anxietyData[index].id)}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            aria-controls="Depression-content"
            id="Depression-header"
          >
            <Typography>Depression</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {selectedDepressionForm && (
              <DisplayResults
                id={selectedDepressionForm}
                name={userData!.name}
                birthday={convertDateToHumanReadable(userData!.birthday, {
                  dateInUtc: true,
                })}
                deleteCallback={() => {
                  setSelectedDepressionForm("");
                  setRefreshKey(Date.now());
                }}
                type="depression"
                hideCallback={() => setSelectedDepressionForm("")}
              />
            )}
            <Typography>
              Click on a point to learn more about the result, download the
              form, or delete it.
            </Typography>
            <Graph
              data={depressionData}
              horizontalLineStep={7}
              maxYValue={30}
              targetZonePercentage={33.3}
              targetZoneStartingPosition={"bottom"}
              onClick={index =>
                setSelectedDepressionForm(depressionData[index].id)
              }
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            aria-controls="Productivity-content"
            id="Productivity-header"
          >
            <Typography>Productivity (Sum of disruption questions) </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {selectedProductivityForm && (
              <SimpleSheehanDocument
                data={
                  productivityData.find(
                    data => data.id === selectedProductivityForm
                  )!
                }
                clientName={userData!.name}
                hideCallback={() => setSelectedProductivityForm("")}
              />
            )}
            <Typography>
              Click on a point to learn more about the result and download the
              form.
            </Typography>
            <Graph
              data={productivityGraphData}
              horizontalLineStep={10}
              maxYValue={30}
              targetZonePercentage={33.3}
              targetZoneStartingPosition={"bottom"}
              onClick={index =>
                setSelectedProductivityForm(productivityGraphData[index].id)
              }
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
