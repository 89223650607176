import { parse } from "query-string";
import { useEffect, useState } from "react";
import {
  Create,
  DateInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
} from "react-admin";
import { useLocation } from "react-router";
import { pharmacistNameChoices, prescriberChoices } from "./StaffNames";
import { SaveCancelToolBar } from "../../components/SaveCancelToolBar";
import {
  dateFormatter,
  dateParser,
  getDefaultDate,
} from "../../utils/dateInputFilters";

export const PrescriptionCreate = props => {
  const pageTitle = "Create Prescription";

  const location = useLocation();
  const dataProvider = useDataProvider();

  const { user_id: user_id_string } = parse(location.search);
  const redirectPath = user_id_string
    ? `/#/users/${user_id_string}/show`
    : undefined;

  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dataProvider.getOne("users", { id: user_id_string }).then(({ data }) => {
      setUserName(`${data.firstName} ${data.lastName}`);
      setLoading(false);
    });
  }, []);

  const transform = data => {
    const {
      name,
      strength,
      directions,
      quantityFilled,
      daysSupply,
      refillsRemaining,
      dateFilled,
      cost,
      insuranceDiscount,
      prescriberCalendlyId,
      pharmacistName,
      pharmacyName,
      pharmacyPhoneNumber,
      cancelled,
      trackingNumber,
    } = data;

    const prescriberName = prescriberChoices.find(
      prescriber => prescriber.id === prescriberCalendlyId
    )?.value;

    return {
      prescription: {
        user: { id: user_id_string },
        name,
        strength,
        directions,
        quantityFilled,
        daysSupply,
        prescriberCalendlyId,
        prescriberName,
        pharmacistName,
        pharmacyName,
        pharmacyPhoneNumber,
        cancelled,
      },
      refill: {
        refillsRemaining,
        cost,
        insuranceDiscount,
        dateFilled,
        trackingNumber,
      },
    };
  };

  if (loading) {
    return null;
  }

  return (
    <Create {...props} title={pageTitle} transform={transform} redirect="show">
      <SimpleForm toolbar={<SaveCancelToolBar path={redirectPath} />}>
        <TextInput
          source="user.id"
          defaultValue={userName}
          label="User"
          disabled
          fullWidth
        />
        <TextInput source="name" label="Drug Name" required />
        <TextInput source="strength" required />
        <TextInput source="directions" required fullWidth />
        <NumberInput source="quantityFilled" step={1} min={0} required />
        <NumberInput source="daysSupply" step={1} min={0} required />
        <NumberInput source="refillsRemaining" min={0} required step={1} />
        <DateInput
          source="dateFilled"
          format={dateFormatter}
          parse={dateParser}
          defaultValue={getDefaultDate()}
        />
        <NumberInput source="cost" label="Cost ($)" min={0} required />
        <NumberInput
          source="insuranceDiscount"
          label="Insurance Discount ($)"
          min={0}
        />
        <SelectInput
          source="prescriberCalendlyId"
          required
          label="Prescriber"
          choices={prescriberChoices}
        />
        <SelectInput
          source="pharmacistName"
          required
          choices={pharmacistNameChoices}
          defaultValue={pharmacistNameChoices[0].id}
        />
        <TextInput source="pharmacyName" required />
        <TextInput source="pharmacyPhoneNumber" required />
        <TextInput source="trackingNumber" />
      </SimpleForm>
    </Create>
  );
};
