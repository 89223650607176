import { convertNameToTitle } from "./DataContainer";

export const convertToHumanReadableAnswer = (
  value: string | boolean | undefined | null
) => {
  if (typeof value === "string") {
    return convertNameToTitle(value);
  }
  if (value === undefined || value === null) {
    return "Not answered";
  }
  return value ? "Yes" : "No";
};

export const substanceUseFields = [
  { name: "caffeineConsumption" },
  {
    name: "etoh",
    label: "ETOH",
  },
  {
    name: "smokesOrVapesDetails",
    label: "Nicotine",
  },
  {
    name: "usesCannabisDetails",
    label: "Cannabis",
  },
  { name: "usesRecreationalDrugsDetails", label: "Other Rec Drugs" },
];

export const ptsdDifficulties = [
  {
    name: "ptsdMemories",
    label: "PTSD Memories",
  },
  {
    name: "ptsdNightmares",
    label: "PTSD Nightmares",
  },
  {
    name: "relivingPtsdEvent",
    label: "Reliving PTSD Event",
  },
  {
    name: "ptsdOnGuard",
    label: "Being “super-alert”, watchful, or on guard",
  },
  {
    name: "ptsdJumpy",
    label: "Feeling jumpy or easily startled",
  },
];

export const socialHistoryFields = [
  { name: "occupation", label: "Employment" },
  { name: "highestLevelOfEducation", label: "Education" },
  { name: "livingSituation" },
];

export const medicalHistoryFields = [
  { name: "highBloodPressure" },
  { name: "diabetesMellitus" },
  { name: "hypoOrHyperthyroidism" },
  { name: "headInjuryOrConcussion" },
  { name: "stroke" },
  { name: "heartAttack" },
  { name: "arrhythmias" },
  { name: "chronicPain" },
  { name: "epilepsy" },
  { name: "cognitiveImpairment" },
  { name: "generalizedAnxietyDisorder" },
  { name: "depressiveDisorder" },
  { name: "borderlinePersonalityDisorder" },
  { name: "bipolarDisorder" },
  { name: "schizophrenia" },
  { name: "adhd", label: "ADHD" },
];
