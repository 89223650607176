import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { selfReportQuestions } from "./questions";
import { SelfReportForm } from "./types";
import { calculateScore } from "./utils";
import { AnswerLabels, QuestionAndAnswer, genericStyles } from "../PdfBuilder";

const styles = StyleSheet.create({
  score: { fontSize: "14px", fontStyle: "bold", paddingTop: "10pt" },
});

export const SelfReportPage: React.FC<{
  data: SelfReportForm;
  clientName: string;
}> = ({ data, clientName }) => {
  return (
    <Page size="A4" style={genericStyles.page}>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>ADULT ADHD SELF-REPORT SCALE SYMPTOM CHECKLIST</Text>
      </View>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>{clientName}</Text>
      </View>
      {selfReportQuestions.map(questionSet => (
        <View key={questionSet.title}>
          <View style={{ paddingTop: "10pt" }}>
            <Text style={genericStyles.sectionTitle}>{questionSet.title}</Text>
          </View>
          <AnswerLabels
            questions={["Never", "Rarely", "Sometimes", "Often", "Very Often"]}
          />
          {questionSet.questions.map(questionInfo => (
            <View key={questionInfo.field}>
              <QuestionAndAnswer
                key={questionInfo.field}
                question={questionInfo.question}
                answer={data[questionInfo.field]!}
                minGreySection={questionInfo.minHighlight}
                potentialScores={[0, 1, 2, 3, 4]}
              />
            </View>
          ))}
          <Text style={styles.score}>
            {`Total score in highlighted area: ${calculateScore(
              data,
              questionSet.questions
            )}/${questionSet.questions.length}`}
          </Text>
        </View>
      ))}
    </Page>
  );
};
