import { Sync } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useCallback, useState } from "react";
import {
  useListContext,
  useNotify,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import { useHttpClient } from "../../utils";

const BulkSyncUsersButton = () => {
  const refresh = useRefresh();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { httpClient, baseUrl } = useHttpClient();
  const { selectedIds } = useListContext();
  const notify = useNotify();
  const unselectAll = useUnselectAll("users");

  const handleResetGenerate = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all(
        selectedIds.map(id =>
          httpClient(`${baseUrl}/api/users/${id}/metrics`, {
            method: "POST",
          })
        )
      );

      notify("Users synced successfully", { type: "success" });
      unselectAll();
      refresh();
    } catch {
      notify("User sync failed. Contact admin.", { type: "error" });
    } finally {
      setIsLoading(false);
    }
  }, [selectedIds]);

  return (
    <LoadingButton
      startIcon={<Sync />}
      onClick={handleResetGenerate}
      loading={isLoading}
    >
      Refresh Metrics
    </LoadingButton>
  );
};

export const UserBulkActions = () => (
  <>
    <BulkSyncUsersButton />
  </>
);
