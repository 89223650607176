import { Box } from "@material-ui/core";
import React from "react";
import {
  BooleanField,
  DateField,
  EditButton,
  Identifier,
  NumberField,
  TextField,
  WithRecord,
  useCreatePath,
} from "react-admin";
import { Link } from "react-router-dom";
import SubscriptionStatus from "./SubscriptionStatus";
import { CalendlyField } from "../../components";
import { birthdayDateOptions, eventDateOptions } from "../../utils/dateTime";
import {
  convertEveryFirstLetterToCapital,
  formatPhoneNumber,
} from "../Prescreenings/utils";

export const pharmacistFields = (isTeamLead: boolean) => [
  <TextField key="firstName" source="firstName" emptyText="null" />,
  <TextField key="preferredName" source="preferredName" emptyText="null" />,
  <TextField key="lastName" source="lastName" emptyText="null" />,
  <DateField
    key="birthday"
    source="birthday"
    emptyText="null"
    options={birthdayDateOptions}
  />,
  <WithRecord
    key="email"
    label="Email"
    render={record => {
      return (
        <Box key="emailContainer" display={"flex"} flexDirection={"row"}>
          <TextField
            key="email"
            source="email"
            label="Email"
            emptyText="null"
          />
          {isTeamLead && <EditButton key="editButton" />}
        </Box>
      );
    }}
  />,
  <WithRecord
    key="mobile"
    label="Mobile"
    render={record => {
      return (
        <TextField
          record={{
            ...record,
            mobile: formatPhoneNumber(record.mobile),
          }}
          source="mobile"
          emptyText="null"
        />
      );
    }}
  />,
  <TextField key="address" source="address" emptyText="null" />,
  <WithRecord
    key="city"
    label="City"
    render={record => {
      return (
        <TextField
          record={{
            ...record,
            city: convertEveryFirstLetterToCapital(record.city),
          }}
          source="city"
          emptyText="null"
        />
      );
    }}
  />,
  <WithRecord
    key="province"
    label="Province"
    render={record => {
      return (
        <TextField
          record={{
            ...record,
            province: convertEveryFirstLetterToCapital(record.province),
          }}
          source="province"
          emptyText="null"
        />
      );
    }}
  />,
  <TextField key="postalCode" source="postalCode" emptyText="null" />,
  <WithRecord
    key="personalHealthNumberInfo"
    label="Personal Health Number"
    render={record => {
      return (
        <>
          {!record?.personalHealthNumberInfo && (
            <span style={{ fontSize: "14px" }}>Has not answered question</span>
          )}
          {record?.personalHealthNumberInfo &&
            !record?.personalHealthNumberInfo?.hasPersonalHealthNumber && (
              <span style={{ fontSize: "14px" }}>Does not have PHN</span>
            )}
          {record?.personalHealthNumberInfo?.hasPersonalHealthNumber && (
            <>
              <TextField
                record={{
                  ...record,
                  phnNumber: record.personalHealthNumberInfo?.number,
                }}
                source="phnNumber"
              />
              <br />
              <TextField
                record={{
                  ...record,
                  phnProvince: convertEveryFirstLetterToCapital(
                    record.personalHealthNumberInfo?.province
                  ),
                }}
                source="phnProvince"
                emptyText=""
              />
            </>
          )}
        </>
      );
    }}
  />,
  <TextField
    key="medicationDeliveryMethod"
    source="medicationDeliveryMethod"
    emptyText="null"
  />,
  <WithRecord
    key="preferredPharmacy"
    label="Preferred Pharmacy"
    render={record => {
      return (
        <>
          <TextField
            record={{
              ...record,
              preferredPharmacyName: record?.preferredPharmacy?.name,
            }}
            source="preferredPharmacy.name"
            emptyText="null"
          />
          <br />
          <TextField
            record={{
              ...record,
              preferredPharmacyAddress: record?.preferredPharmacy?.address,
            }}
            source="preferredPharmacy.address"
            emptyText=""
          />
          <br />
          <TextField
            record={{
              ...record,
              preferredPharmacyCity: convertEveryFirstLetterToCapital(
                record?.preferredPharmacy?.city
              ),
            }}
            source="preferredPharmacyCity"
            emptyText=""
          />
          <br />
          <TextField
            record={{
              ...record,
              preferredPharmacyProvince: convertEveryFirstLetterToCapital(
                record?.preferredPharmacy?.province
              ),
            }}
            source="preferredPharmacyProvince"
            emptyText=""
          />{" "}
          <TextField
            record={{
              ...record,
              preferredPharmacyPostalCode:
                record?.preferredPharmacy?.postalCode,
            }}
            source="preferredPharmacy.postalCode"
            emptyText=""
          />
        </>
      );
    }}
  />,
];

export const nonPharmacistFields = [
  <TextField key="id" source="id" label="ID" emptyText="null" />,
  <TextField key="gender" source="gender" emptyText="null" />,
  <TextField
    key="preferredPronouns"
    source="preferredPronouns"
    emptyText="null"
  />,
  <BooleanField
    key="selfIdentifiesAsIndigenous"
    source="selfIdentifiesAsIndigenous"
    emptyText="null"
  />,
  <DateField
    key="createdAt"
    source="createdAt"
    showTime
    emptyText="null"
    options={eventDateOptions}
  />,
  <DateField
    key="updatedAt"
    source="updatedAt"
    showTime
    emptyText="null"
    options={eventDateOptions}
  />,
  <WithRecord
    key="stripeCustomer"
    label="Stripe customer"
    render={record => {
      return (
        <Link
          to={`https://dashboard.stripe.com/customers/${record.stripeCustomerId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {record.stripeCustomerId}
        </Link>
      );
    }}
  />,
  <BooleanField
    key="consentToPrivacyTerms"
    source="consentToPrivacyTerms"
    emptyText="null"
  />,
  <BooleanField
    key="consentToCommunication"
    source="consentToCommunication"
    emptyText="null"
  />,
  <TextField
    key="emergencyContactName"
    source="emergencyContactName"
    emptyText="null"
  />,
  <WithRecord
    key="emergencyContactPhone"
    label="Emergency Contact Phone"
    render={record => {
      return (
        <TextField
          record={{
            ...record,
            emergencyContactPhone: formatPhoneNumber(
              record.emergencyContactPhone
            ),
          }}
          source="emergencyContactPhone"
          emptyText="null"
        />
      );
    }}
  />,
  <TextField
    key="emergencyContactRelationship"
    source="emergencyContactRelationship"
    emptyText="null"
  />,
  <TextField
    key="supportContactName"
    source="supportContactName"
    emptyText="null"
  />,
  <TextField
    key="supportContactRelationship"
    source="supportContactRelationship"
    emptyText="null"
  />,
  <TextField key="referralSource" source="referralSource" emptyText="null" />,
  <WithRecord
    key="Subscription Status"
    label="Subscription Status"
    render={record => <SubscriptionStatus id={record.id as string} />}
  />,
  <TextField
    key="signupSurveyResult"
    source="signupSurveyResult"
    emptyText="null"
  />,
  <TextField
    key="signupSurveyCondition"
    source="signupSurveyCondition"
    emptyText="null"
  />,
  <TextField
    key="primaryCareProvider"
    source="primaryCareProvider"
    emptyText="null"
  />,
  <TextField
    key="primaryCareProviderName"
    source="primaryCareProviderName"
    emptyText="null"
  />,
  <WithRecord
    key="primaryCareProviderPhone"
    label="Primary Care Provider Phone"
    render={record => {
      return (
        <TextField
          record={{
            ...record,
            primaryCareProviderPhone: formatPhoneNumber(
              record.primaryCareProviderPhone
            ),
          }}
          source="primaryCareProviderPhone"
          emptyText="null"
        />
      );
    }}
  />,
  <WithRecord
    key="primaryCareProviderFax"
    label="Primary Care Provider Fax"
    render={record => {
      return (
        <TextField
          record={{
            ...record,
            primaryCareProviderFax: formatPhoneNumber(
              record.primaryCareProviderFax
            ),
          }}
          source="primaryCareProviderFax"
          emptyText="null"
        />
      );
    }}
  />,
  <TextField key="text" source="text" emptyText="null" />,
  <NumberField key="Logins" label="Logins" source="metrics.logins" />,
  <DateField
    key="metrics.lastLogin"
    source="metrics.lastLogin"
    showTime
    options={eventDateOptions}
  />,
  <TextField key="metrics.plan" source="metrics.plan" />,
  <NumberField
    key="Total Visits"
    label="Total Visits"
    source="metrics.totalVisits"
  />,
  <NumberField
    key="Cancellations"
    label="Cancellations"
    source="metrics.totalCancellations"
  />,
  <WithRecord
    key="Next Visit"
    label="Next Visit"
    render={record => (
      <CalendlyField appointment={record?.metrics?.nextVisit} />
    )}
  />,
  <WithRecord
    key="Last Visit"
    label="Last Visit"
    render={record => (
      <CalendlyField appointment={record?.metrics?.lastVisit} />
    )}
  />,
  <WithRecord
    key="Last Assessment"
    label="Last Assessment"
    render={record => (
      <CalendlyField appointment={record?.metrics?.lastAssessmentVisit} />
    )}
  />,
  <WithRecord
    key="Last Prescriber Visit"
    label="Last Prescriber Visit"
    render={record => (
      <CalendlyField appointment={record?.metrics?.lastPrescriberVisit} />
    )}
  />,
  <WithRecord
    key="Last Care Coach Visit"
    label="Last Care Coach Visit"
    render={record => (
      <CalendlyField appointment={record?.metrics?.lastCareCoachVisit} />
    )}
  />,
];

export const PrescriptionNameField: React.FunctionComponent<{
  name?: Identifier;
  id?: Identifier;
}> = ({ name, id }) => {
  const createPath = useCreatePath();

  if (!name) {
    return null;
  }

  return (
    <Link
      to={createPath({
        resource: "prescriptions",
        type: "show",
        id,
      })}
    >
      {name}
    </Link>
  );
};
