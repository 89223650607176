import { FunctionComponent } from "react";
import {
  Datagrid,
  DateField,
  Identifier,
  Link,
  List,
  NumberField,
  TextField,
  useCreatePath,
  WithRecord,
} from "react-admin";
import { Pagination } from "../../components/Pagination";
import { eventDateOptions } from "../../utils/dateTime";

const IDField: FunctionComponent<{
  id?: Identifier;
  label?: string;
}> = ({ id, label }) => {
  const createPath = useCreatePath();

  if (!id) {
    return null;
  }

  return (
    <Link
      to={createPath({
        resource: "users",
        type: "show",
        id,
      })}
    >
      {label}
    </Link>
  );
};

export const FeedbackList = props => (
  <List
    {...props}
    sort={{ field: "createdAt", order: "DESC" }}
    perPage={100}
    pagination={<Pagination />}
  >
    <Datagrid bulkActionButtons={false}>
      <WithRecord
        label="User"
        render={record => (
          <IDField
            id={record.user.id}
            label={`${record.user.firstName} ${record.user.lastName}`}
          />
        )}
      />
      <TextField sortable={false} label="Email" source="user.email" />
      <NumberField sortable={false} label="Rating" source="rating" />
      <TextField sortable={false} label="Comment" source="comment" />
      <DateField
        sortable={false}
        label="Created On"
        source="createdAt"
        options={eventDateOptions}
      />
    </Datagrid>
  </List>
);
