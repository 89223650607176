import {
  Logger as DatadogLogger,
  StatusType,
  datadogLogs,
} from "@datadog/browser-logs";
import axios from "axios";
const { logger: datadogLogger } = datadogLogs;

/**
 * Redefine the logger type
 * - prevent use of log,
 * - combine the context and error into a single object, and
 * - allow for any error type because datadog handles it gracefully
 */
type LogContext = { [key: string]: any; error?: any } & {
  stack?: never; // this prevents an error from being passed in as the whole log context
};
type Logger = Omit<
  DatadogLogger,
  "log" | "debug" | "info" | "warn" | "error"
> & {
  info: (message: string, context?: LogContext) => void;
  warn: (message: string, context?: LogContext) => void;
  error: (message: string, context?: LogContext) => void;
};

/**
 * Redefine logger methods so that we can
 * - change the shape of the context, and
 * - report on malformed errors
 */
export const logger = datadogLogger as Logger;
logger.info = _mapToCogLog(StatusType.info);
logger.warn = _mapToCogLog(StatusType.warn);
logger.error = _mapToCogLog(StatusType.error);

/**
 * Initialize datadog logging
 */
export function initializeDatadogLogging() {
  const env = _getDatadogEnvironment(window.location.hostname);

  datadogLogs.init({
    clientToken: "pub659373fa9495610e7e3d2d4dbaba7dd5", // OK to keep here because it's exposed on the client side
    site: "us3.datadoghq.com",
    service: "admin-app",
    env,
    forwardErrorsToLogs: true, // forward errors to datadog
    forwardConsoleLogs: ["log", "info", "warn", "error"], // this is a catch-all, all logs should use logger defined above
    sessionSampleRate: 100, // log on 100% of sessions
    telemetrySampleRate: 0, // don't submit telemetry data to datadog
  });

  // If in a development environment, only log to console
  if (env === "development") {
    logger.setHandler("console");
    logger.info("logging to datadog is disabled");
  }

  // Add a request interceptor to reduce log noise
  axios.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      // Swallow request errors because XHR errors are already logged & contain more information
      return undefined;
    }
  );
}

export function _mapToCogLog(level: any) {
  return function cogLog(message: string, context?: LogContext) {
    const { error, ...rest } = context || {};

    if (error && !(error instanceof Error)) {
      logger.warn(`error logged is not an instance of Error: ${error}`, {
        malformedError: error,
      });
    }
    return datadogLogger.log(message, rest, level, error);
  };
}

export function _getDatadogEnvironment(hostname: string) {
  const lowerHostname = hostname.toLowerCase();

  if (lowerHostname === "admin.getcognito.ca") {
    return "production.d2c";
  } else if (lowerHostname === "island-health.admin.getcognito.ca") {
    return "production.ih";
  } else if (lowerHostname.includes("admin-staging.getcognito.ca")) {
    return "staging.d2c";
  } else if (lowerHostname === "island-health-staging-admin.getcognito.ca") {
    return "staging.ih";
  } else if (
    lowerHostname === "localhost" ||
    lowerHostname.includes("ngrok") ||
    lowerHostname.startsWith("127.0")
  ) {
    return "development";
  }
}
